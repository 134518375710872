/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.30435 3.86025V1.08434C4.30435 0.477108 4.73478 0 5.28261 0C5.83043 0 6.26087 0.477108 6.26087 1.08434V3.86025C6.26087 4.46748 5.83043 4.94459 5.28261 4.94459C4.73478 4.94459 4.30435 4.46748 4.30435 3.86025ZM12.7174 4.94459C13.2652 4.94459 13.6957 4.46748 13.6957 3.86025V1.08434C13.6957 0.477108 13.2652 0 12.7174 0C12.1696 0 11.7391 0.477108 11.7391 1.08434V3.86025C11.7391 4.46748 12.1696 4.94459 12.7174 4.94459ZM18 2.47227V17.1325C18 17.6096 17.6478 18 17.2174 18H0.782609C0.352174 18 0 17.6096 0 17.1325V2.47227C0 1.99516 0.352174 1.51807 0.782609 1.51807H2.60218C3.03262 1.51807 3.38479 1.90843 3.38479 2.38554C3.38479 2.86265 3.03262 3.25301 2.60218 3.25301H1.54566V6.93976H16.4152V3.25301H15.3783C14.9478 3.25301 14.5956 2.86265 14.5956 2.38554C14.5956 1.90843 14.9478 1.51807 15.3783 1.51807H17.1978C17.6478 1.51807 18 1.99516 18 2.47227ZM16.4348 16.2651V8.45783H1.56522V16.2651H16.4348ZM7.76738 3.25301H10.2326C10.6631 3.25301 11.0152 2.86265 11.0152 2.38554C11.0152 1.90843 10.6631 1.51807 10.2326 1.51807H7.76738C7.33695 1.51807 6.98477 1.90843 6.98477 2.38554C6.98477 2.86265 7.33695 3.25301 7.76738 3.25301Z"
      fill="#A0A0A0"
    />
  </svg>
);

export default SVG;

export const instructions = [
  {
    text: "1. Save the delivery ID code",
  },
  {
    text: "2. Search & Select a nearby store location, Drop your package to the location.",
  },
  {
    text: "3. Provide them with the delivery ID code",
  },
  {
    text: "4. Make Payment",
  },
];

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="46"
    viewBox="0 0 46 46"
    width="46"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M45.5 23.0005C45.5 10.5729 35.4264 0.5 23.0004 0.5C10.5737 0.5 0.5 10.5729 0.5 23.0005C0.5 35.4263 10.5737 45.5 23.0004 45.5C35.4264 45.5 45.5 35.4263 45.5 23.0005ZM12.3421 15.9725C12.3421 14.7048 12.7514 13.4203 13.5701 12.1191C14.3886 10.818 15.5833 9.74042 17.1535 8.88623C18.7238 8.03232 20.5558 7.60513 22.6495 7.60513C24.5953 7.60513 26.3134 7.96235 27.8032 8.67605C29.293 9.39012 30.4435 10.361 31.2558 11.5886C32.0676 12.8166 32.4737 14.1509 32.4737 15.5922C32.4737 16.7266 32.2422 17.721 31.7791 18.5748C31.316 19.429 30.7656 20.1663 30.1283 20.7867C29.4907 21.4073 28.3466 22.4516 26.6958 23.9196C26.2395 24.3334 25.8735 24.6971 25.5987 25.0106C25.3234 25.3243 25.1187 25.6112 24.9846 25.8713C24.8503 26.1316 24.7461 26.3918 24.6726 26.652C24.5986 26.9121 24.4879 27.3695 24.3404 28.0232C24.0854 29.4113 23.2867 30.1051 21.9448 30.1051C21.2468 30.1051 20.6595 29.8784 20.1833 29.4245C19.7068 28.9709 19.4686 28.2969 19.4686 27.4027C19.4686 26.2817 19.6429 25.3108 19.9921 24.4902C20.341 23.6693 20.8039 22.9487 21.3811 22.3283C21.958 21.7077 22.7366 20.9705 23.7164 20.1163C24.5752 19.369 25.1961 18.8051 25.5786 18.4248C25.9611 18.0444 26.2832 17.621 26.5449 17.1536C26.8066 16.6866 26.9375 16.1796 26.9375 15.6323C26.9375 14.5647 26.5381 13.664 25.7397 12.9299C24.941 12.1961 23.9108 11.8289 22.6495 11.8289C21.1729 11.8289 20.0858 12.1992 19.3881 12.9399C18.6901 13.6805 18.0997 14.7715 17.6165 16.2128C17.1601 17.721 16.2944 18.4748 15.0195 18.4748C14.2676 18.4748 13.6335 18.2114 13.117 17.6841C12.6004 17.157 12.3421 16.5865 12.3421 15.9725ZM20.3425 37.6125C20.9391 38.1339 21.6374 38.3948 22.4375 38.3948C23.2112 38.3948 23.893 38.1307 24.4832 37.6027C25.0733 37.0748 25.3684 36.3486 25.3684 35.4244C25.3684 34.5926 25.0862 33.8931 24.5226 33.3253C23.9585 32.7578 23.2636 32.4738 22.4374 32.4738C21.598 32.4738 20.8898 32.7578 20.3129 33.3253C19.7356 33.8931 19.4474 34.5926 19.4474 35.4244C19.4474 36.3619 19.7456 37.0913 20.3425 37.6125Z"
      fill="#B6B6B6"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

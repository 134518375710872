/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#F5F5F9"
      height="47"
      rx="9.5"
      stroke="#6B7280"
      width="47"
      x="0.5"
      y="0.5"
    />
    <path
      d="M34 21C35.0605 20.9987 36.0771 20.5768 36.827 19.827C37.5768 19.0771 37.9987 18.0605 38 17V14H35C33.9761 14.0015 32.9923 14.3976 32.253 15.106C31.735 14.166 30.9744 13.3821 30.0505 12.8358C29.1267 12.2896 28.0733 12.0009 27 12H24V15C24.0019 16.5907 24.6346 18.1158 25.7594 19.2406C26.8842 20.3654 28.4093 20.9981 30 21H31V34H19V29H20C21.0605 28.9987 22.0771 28.5768 22.827 27.827C23.5768 27.0771 23.9987 26.0605 24 25V22H21C19.9761 22.0015 18.9923 22.3976 18.253 23.106C17.735 22.166 16.9744 21.3821 16.0505 20.8358C15.1267 20.2896 14.0733 20.0009 13 20H10V23C10.0019 24.5907 10.6346 26.1158 11.7594 27.2406C12.8842 28.3654 14.4093 28.9981 16 29H17V34H10V36H38V34H33V21H34ZM33 18C33.0005 17.4697 33.2114 16.9613 33.5864 16.5864C33.9613 16.2114 34.4697 16.0005 35 16H36V17C35.9995 17.5303 35.7886 18.0387 35.4136 18.4136C35.0387 18.7886 34.5303 18.9995 34 19H33V18ZM19 26C19.0005 25.4697 19.2114 24.9613 19.5864 24.5864C19.9613 24.2114 20.4697 24.0005 21 24H22V25C21.9995 25.5303 21.7886 26.0387 21.4136 26.4136C21.0387 26.7886 20.5303 26.9995 20 27H19V26ZM17 27H16C14.9395 26.9987 13.9229 26.5768 13.173 25.827C12.4232 25.0771 12.0013 24.0605 12 23V22H13C14.0605 22.0013 15.0771 22.4232 15.827 23.173C16.5768 23.9229 16.9987 24.9395 17 26V27ZM31 19H30C28.9395 18.9987 27.9229 18.5768 27.173 17.827C26.4232 17.0771 26.0013 16.0605 26 15V14H27C28.0605 14.0013 29.0771 14.4232 29.827 15.173C30.5768 15.9229 30.9987 16.9395 31 18V19Z"
      fill="#F9A025"
    />
  </svg>
);

export default SVG;

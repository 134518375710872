/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#000"
      height="10"
      rx="0.833333"
      transform="rotate(-90 5.83334 5.83331)"
      width="1.66667"
      x="5.83334"
      y="5.83331"
    />
    <path
      d="M8.08927 6.91076C8.4147 7.2362 8.4147 7.76384 8.08927 8.08928C7.76383 8.41471 7.23619 8.41471 6.91075 8.08928L4.41075 5.58928C4.08532 5.26384 4.08532 4.7362 4.41075 4.41076L6.91075 1.91076C7.23619 1.58533 7.76383 1.58533 8.08927 1.91076C8.4147 2.2362 8.4147 2.76384 8.08927 3.08928L6.17852 5.00002L8.08927 6.91076Z"
      fill="#000"
    />
    <rect
      fill="#000"
      height="10"
      rx="0.833334"
      transform="matrix(0 -1 -1 0 14.1667 15.8333)"
      width="1.66667"
    />
    <path
      d="M11.9107 16.9108C11.5853 17.2362 11.5853 17.7638 11.9107 18.0893C12.2362 18.4147 12.7638 18.4147 13.0892 18.0893L15.5892 15.5893C15.9147 15.2638 15.9147 14.7362 15.5892 14.4108L13.0892 11.9108C12.7638 11.5853 12.2362 11.5853 11.9107 11.9108C11.5853 12.2362 11.5853 12.7638 11.9107 13.0893L13.8215 15L11.9107 16.9108Z"
      fill="#000"
    />
  </svg>
);

export default SVG;

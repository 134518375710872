/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM27.5999 24.6475L25.1355 22.1757C24.6446 21.6854 23.8317 21.7003 23.3238 22.2099L22.0822 23.4548C22.0038 23.4114 21.9226 23.3662 21.8372 23.3181C21.0532 22.8824 19.9801 22.2853 18.8508 21.152C17.7183 20.0163 17.1223 18.9385 16.6865 18.1517C16.6406 18.0683 16.5965 17.988 16.553 17.9116L17.3863 17.0772L17.7959 16.6658C18.3047 16.1555 18.3187 15.3405 17.829 14.8488L15.3646 12.3767C14.8749 11.8857 14.0616 11.9006 13.5529 12.4109L12.8583 13.1115L12.8773 13.1304C12.6444 13.4284 12.4498 13.7721 12.305 14.1428C12.1715 14.4956 12.0883 14.8323 12.0503 15.1697C11.7249 17.8752 12.9577 20.3478 16.3033 23.703C20.928 28.3406 24.6548 27.9903 24.8156 27.9732C25.1658 27.9312 25.5013 27.8473 25.8424 27.7145C26.2088 27.5709 26.5513 27.376 26.8483 27.143L26.8634 27.1565L27.5671 26.4655C28.0748 25.9553 28.0894 25.14 27.5999 24.6475Z"
      fill="#B6B6B6"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

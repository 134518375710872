/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 100 40"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M70.8042 9C71.4668 9.0113 72.1205 9.15711 72.727 9.4289C73.3172 9.69317 73.8538 10.0671 74.3095 10.5315C75.2464 11.4931 75.7706 12.794 75.767 14.1487C75.7733 14.8382 75.6397 15.5216 75.3747 16.1561C74.854 17.3974 73.8827 18.386 72.6636 18.9155C72.0417 19.1874 71.3713 19.3255 70.6946 19.3209H64.2724V26.3712H62.6841V9.78337C62.6829 9.67846 62.7035 9.57447 62.7446 9.47826C62.7857 9.38205 62.8463 9.29581 62.9226 9.22522C63.0704 9.0805 63.2675 8.99977 63.4725 9H70.8042ZM70.6908 17.7209C71.1534 17.7233 71.6116 17.6287 72.0367 17.4428C72.8777 17.0796 73.5472 16.3977 73.9038 15.5411C74.0876 15.101 74.1805 14.627 74.1768 14.1487C74.1789 13.6775 74.0859 13.2109 73.9038 12.7778C73.5472 11.9212 72.8777 11.2394 72.0367 10.8762C71.6113 10.6912 71.1533 10.5965 70.6908 10.5981H64.2685V17.7209H70.6908Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M81.5988 13.7786C83.5716 13.7786 85.1047 14.3387 86.1982 15.4589C87.2916 16.5791 87.8383 18.1374 87.8383 20.1337V22.9166C87.8243 23.2598 87.7628 23.5994 87.6557 23.9252C87.4947 24.3897 87.2065 24.7974 86.825 25.1002C86.2674 25.5481 85.6293 25.8808 84.9464 26.0794C84.1273 26.3497 83.0114 26.4848 81.5988 26.4848C80.1862 26.4848 79.0729 26.351 78.2589 26.0834C77.576 25.8847 76.9379 25.5521 76.3803 25.1041C75.9988 24.8014 75.7106 24.3936 75.5496 23.9291C75.4415 23.6036 75.38 23.2639 75.3669 22.9205C75.3669 21.9778 75.5112 21.2356 75.7996 20.6938C76.0595 20.182 76.4605 19.7586 76.9533 19.4756C77.4876 19.1899 78.0715 19.013 78.6723 18.9547C79.3791 18.8755 80.0897 18.8369 80.8008 18.8391H86.1309C85.9168 17.7254 85.431 16.8768 84.6734 16.2932C83.9158 15.7096 82.8903 15.4158 81.5969 15.4119C80.2906 15.4119 79.2357 15.7285 78.4319 16.3617L77.4532 15.0711C78.5325 14.2094 79.9144 13.7786 81.5988 13.7786ZM86.2443 20.4588H81.5988C80.581 20.4588 79.7651 20.4855 79.1511 20.5391C78.6636 20.5615 78.1844 20.6757 77.7378 20.8759C77.5877 20.9484 77.454 21.0518 77.3451 21.1795C77.2362 21.3073 77.1545 21.4566 77.1052 21.6181C76.9938 22.0413 76.9439 22.4787 76.9571 22.9166C76.9561 23.0887 76.9923 23.259 77.0631 23.4154C77.1339 23.5717 77.2375 23.7103 77.3667 23.8214C77.6813 24.0921 78.0453 24.297 78.4377 24.4246C78.9216 24.589 79.421 24.7019 79.9279 24.7614C80.4836 24.8303 81.0428 24.865 81.6026 24.8652C82.1618 24.865 82.7204 24.8304 83.2755 24.7614C83.783 24.7021 84.283 24.5892 84.7676 24.4246C85.16 24.297 85.5239 24.0921 85.8386 23.8214C85.9678 23.7103 86.0714 23.5717 86.1422 23.4154C86.213 23.259 86.2491 23.0887 86.2482 22.9166L86.2443 20.4588Z"
        fill="url(#paint1_radial)"
      />
      <path
        d="M99.9998 14.0332L92.4373 31.4769H90.7068L93.1661 25.8171L88.0437 14.0332H89.7742L94.0044 23.7979L98.2673 14.0332H99.9998Z"
        fill="url(#paint2_radial)"
      />
      <path
        d="M12.3503 9.19775H9.74292L1.89782 17.6053V9.19775H0V26.5004H1.89782V20.398L3.62643 18.5551L9.8679 26.4789H12.308L4.93203 17.145L12.3503 9.19775Z"
        fill="#335C88"
      />
      <path
        d="M17.5302 13.8607C15.565 13.8607 14.0133 14.4306 12.9154 15.5528C11.8175 16.675 11.2656 18.2515 11.2656 20.2374C11.2656 22.2232 11.8194 23.8193 12.9154 24.9376C14.0114 26.0558 15.5612 26.6179 17.5302 26.6179C19.4991 26.6179 21.0451 26.0539 22.1449 24.9376C23.2448 23.8213 23.7947 22.2428 23.7947 20.2374C23.7947 18.2319 23.239 16.6769 22.1449 15.5528C21.0508 14.4287 19.4934 13.8607 17.5302 13.8607ZM17.5302 24.681C16.1015 24.681 15.0055 24.303 14.2729 23.5549C13.5403 22.8068 13.1673 21.6925 13.1673 20.2374C13.1673 18.7822 13.5384 17.6679 14.2729 16.9198C15.0074 16.1717 16.1015 15.7937 17.5302 15.7937C18.9588 15.7937 20.0529 16.1717 20.7874 16.9198C21.5219 17.6679 21.893 18.7842 21.893 20.2374C21.893 21.6905 21.52 22.8088 20.7874 23.5549C20.0548 24.3011 18.9569 24.681 17.5302 24.681Z"
        fill="#335C88"
      />
      <path
        d="M29.9189 13.8843C28.171 13.8843 26.7923 14.4561 25.8232 15.5842C24.8541 16.7122 24.3677 18.279 24.3677 20.2609V26.5005H26.2655V20.2609C26.2655 18.8156 26.5828 17.6993 27.2038 16.9433C27.8249 16.1874 28.7421 15.8172 29.9189 15.8172C31.0956 15.8172 32.0166 16.1952 32.6319 16.9433C33.2472 17.6915 33.5703 18.8156 33.5703 20.2609V26.5005H35.4681V20.2609C35.4681 18.279 34.9759 16.7064 34.0029 15.5842C33.03 14.462 31.6513 13.8843 29.9189 13.8843Z"
        fill="#335C88"
      />
      <path
        d="M42.3158 13.8607C40.3507 13.8607 38.8009 14.4306 37.701 15.5528C36.6012 16.675 36.0513 18.2515 36.0513 20.2374C36.0513 22.2232 36.607 23.8193 37.701 24.9376C38.7951 26.0558 40.3468 26.6179 42.3158 26.6179C44.1252 26.6179 45.5846 26.1342 46.6575 25.1745C46.6575 26.5924 46.273 27.6872 45.5519 28.4275C44.8309 29.1678 43.7291 29.5536 42.3158 29.5536H37.9068V31.4865H42.3158C44.2656 31.4865 45.8115 30.9206 46.9152 29.8043C48.0189 28.688 48.5765 27.1173 48.5765 25.1315V20.2354C48.5765 18.2515 48.0208 16.675 46.9267 15.5508C45.8326 14.4267 44.279 13.8607 42.3158 13.8607ZM42.3158 15.7937C43.7425 15.7937 44.8385 16.1717 45.5731 16.9198C46.3076 17.6679 46.6787 18.7842 46.6787 20.2315L46.6575 20.8347C46.5575 22.0959 46.1403 23.0614 45.4154 23.7057C44.6905 24.35 43.6445 24.6849 42.3158 24.6849C40.8872 24.6849 39.7912 24.307 39.0586 23.5588C38.326 22.8107 37.9529 21.6964 37.9529 20.2413C37.9529 18.7862 38.324 17.6718 39.0586 16.9237C39.7931 16.1756 40.8891 15.7937 42.3158 15.7937Z"
        fill="#335C88"
      />
      <path
        d="M59.7998 15.5528C58.7038 14.4306 57.154 13.8607 55.185 13.8607C53.5083 13.8607 52.1085 14.2994 51.0337 15.167L50.901 15.2727L52.0662 16.7905L52.2008 16.6848C52.9507 16.0972 53.9564 15.7937 55.185 15.7937C56.4137 15.7937 57.3751 16.0718 58.0827 16.6182C58.7626 17.16 59.2308 17.9306 59.4037 18.792H54.4159C53.7151 18.7901 53.0148 18.8281 52.3181 18.9056C51.707 18.967 51.1135 19.1486 50.5703 19.4403C50.0581 19.7313 49.6404 20.1683 49.3685 20.6976C49.0743 21.2499 48.9263 22.0078 48.9263 22.9537C48.9418 23.3066 49.0065 23.6554 49.1186 23.9897C49.2857 24.4766 49.5851 24.9051 49.9819 25.2255C50.5451 25.6735 51.1883 26.006 51.8759 26.2047C52.6873 26.473 53.7987 26.6081 55.1947 26.6081C56.5906 26.6081 57.6943 26.473 58.5154 26.2047C59.2022 26.0057 59.8447 25.6731 60.4074 25.2255C60.8042 24.9051 61.1036 24.4766 61.2708 23.9897C61.3833 23.6555 61.448 23.3066 61.463 22.9537V20.2374C61.4534 18.2535 60.8997 16.6769 59.7998 15.5528ZM56.8021 24.5811C55.7306 24.7143 54.6472 24.7143 53.5756 24.5811C53.0926 24.5242 52.6164 24.4172 52.1547 24.2619C51.7936 24.1443 51.4585 23.956 51.1683 23.7077C51.0597 23.6159 50.9727 23.5005 50.9135 23.3701C50.8542 23.2396 50.8244 23.0974 50.826 22.9537C50.8141 22.5437 50.8601 22.1342 50.9625 21.7375C51.0046 21.6017 51.074 21.4763 51.1664 21.3694C51.2588 21.2625 51.3721 21.1764 51.499 21.1167C51.9152 20.9327 52.3609 20.8278 52.8142 20.8073C53.4084 20.7544 54.2063 20.7289 55.1889 20.7289H59.5518V22.9537C59.555 23.0963 59.5273 23.2379 59.4704 23.3683C59.4136 23.4986 59.329 23.6145 59.223 23.7077C58.9329 23.9564 58.5978 24.1447 58.2366 24.2619C57.7716 24.4177 57.2923 24.5246 56.806 24.5811H56.8021Z"
        fill="#335C88"
      />
      <path
        d="M48.576 14.5542H46.6782V19.3053H48.576V14.5542Z"
        fill="#335C88"
      />
    </g>
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(69.2217 17.6856) scale(7.60283 7.74361)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(5367.85 736.13) scale(404.697 412.318)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(5934.32 1239.1) scale(459.113 669.838)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
      <clipPath id="clip0">
        <rect
          fill="white"
          height="22.4827"
          transform="translate(0 9)"
          width="100"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 17 15"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.76454 2.71281H15.4979V2.71093C15.9597 2.71071 16.3958 2.92248 16.6806 3.28513C16.9653 3.64778 17.0669 4.12096 16.9561 4.56815L15.9314 8.70756C15.725 9.54421 14.9728 10.1323 14.1091 10.1323H6.49718C5.62878 10.1328 4.87348 9.5388 4.67114 8.69633L3.01025 1.7842C2.96958 1.61641 2.81921 1.4981 2.64617 1.49775H0.750685C0.336093 1.49775 0 1.16247 0 0.748877C0 0.335284 0.336093 2.5897e-07 0.750685 2.5897e-07H2.64429C3.51269 -0.000452764 4.26799 0.593503 4.47033 1.43597L6.13122 8.3481C6.17189 8.51589 6.32227 8.6342 6.49531 8.63455H14.1091C14.2816 8.63442 14.4318 8.51702 14.4732 8.34998L15.4979 4.21056H9.76454C9.34995 4.21056 9.01385 3.87528 9.01385 3.46168C9.01385 3.04809 9.34995 2.71281 9.76454 2.71281ZM15.8526 12.8563C15.8526 11.6724 14.8905 10.7127 13.7038 10.7127C12.517 10.7127 11.5549 11.6724 11.5549 12.8563C11.5549 14.0403 12.517 15 13.7038 15C14.8897 14.9979 15.8505 14.0394 15.8526 12.8563ZM13.0544 12.8563C13.0544 12.4996 13.3443 12.2104 13.7019 12.2104C14.0595 12.2104 14.3494 12.4996 14.3494 12.8563C14.3494 13.2131 14.0595 13.5022 13.7019 13.5022C13.3455 13.5002 13.0573 13.2119 13.0563 12.8563H13.0544ZM6.41836 15C7.60513 15 8.5672 14.0403 8.5672 12.8563C8.5672 11.6724 7.60513 10.7127 6.41836 10.7127C5.23159 10.7127 4.26952 11.6724 4.26952 12.8563C4.27159 14.0394 5.23245 14.9979 6.41836 15ZM7.06583 12.8545C7.06583 12.4977 6.77595 12.2086 6.41836 12.2086V12.2104C6.06193 12.2115 5.77295 12.4989 5.77089 12.8545C5.77089 13.2112 6.06077 13.5004 6.41836 13.5004C6.77595 13.5004 7.06583 13.2112 7.06583 12.8545Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

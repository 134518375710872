/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#F5F5F9"
      height="47"
      rx="9.5"
      stroke="#6B7280"
      width="47"
      x="0.5"
      y="0.5"
    />
    <path
      d="M32.4373 13.6715L30.5399 15.5688C28.6745 14.1224 26.3821 13.3353 24.0216 13.331C21.6611 13.3267 19.3657 14.1052 17.495 15.5449C15.6243 16.9845 14.2838 19.0038 13.6835 21.2867C13.0831 23.5696 13.2568 25.9872 14.1773 28.1608L17.3999 24.9382C17.2195 23.6666 17.41 22.37 17.9486 21.204C18.4873 20.0381 19.3511 19.0525 20.4363 18.3657C21.5215 17.6788 22.782 17.3199 24.0662 17.332C25.3505 17.3442 26.6039 17.7269 27.6759 18.4342L25.7239 20.3862C24.9773 20.029 24.1383 19.9121 23.3225 20.0518C22.5067 20.1915 21.7543 20.5808 21.1691 21.166C20.5839 21.7512 20.1946 22.5036 20.0549 23.3194C19.9152 24.1352 20.0321 24.9742 20.3893 25.7208L11.7893 34.3208C11.7439 29.6542 11.0013 27.9048 10.7386 25.3848C10.5301 23.4102 10.7662 21.4139 11.4295 19.5424C12.0928 17.6708 13.1665 15.9715 14.5719 14.5688C19.4599 9.68084 27.1999 9.38217 32.4386 13.6715H32.4373ZM36.2106 13.6742C36.2559 18.3408 36.9986 20.0888 37.2613 22.6088C37.4698 24.5835 37.2337 26.5797 36.5704 28.4513C35.9071 30.3228 34.8334 32.0222 33.4279 33.4248C28.5399 38.3128 20.7999 38.6115 15.5613 34.3222L17.4599 32.4248C19.3253 33.8713 21.6178 34.6584 23.9783 34.6627C26.3388 34.667 28.6341 33.8884 30.5049 32.4488C32.3756 31.0092 33.716 28.9898 34.3164 26.7069C34.9168 24.424 34.7431 22.0065 33.8226 19.8328L30.5999 23.0555C30.7804 24.3271 30.5899 25.6237 30.0512 26.7896C29.5126 27.9556 28.6488 28.9412 27.5636 29.628C26.4784 30.3149 25.2179 30.6738 23.9337 30.6616C22.6494 30.6495 21.396 30.2668 20.3239 29.5595L22.2759 27.6075C23.0225 27.9647 23.8616 28.0816 24.6774 27.9419C25.4932 27.8022 26.2455 27.4129 26.8308 26.8277C27.416 26.2424 27.8053 25.4901 27.945 24.6743C28.0847 23.8585 27.9678 23.0194 27.6106 22.2728L36.2106 13.6728V13.6742Z"
      fill="#F9A025"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 16 15"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M2.6665 2.83333C2.48969 2.83333 2.32012 2.90357 2.1951 3.0286C2.07008 3.15362 1.99984 3.32319 1.99984 3.5V12.8333C1.99984 13.0101 2.07007 13.1797 2.1951 13.3047C2.32012 13.4298 2.48969 13.5 2.6665 13.5H11.9998C12.1766 13.5 12.3462 13.4298 12.4712 13.3047C12.5963 13.1797 12.6665 13.0101 12.6665 12.8333V9.27333C12.6665 8.90514 12.965 8.60667 13.3332 8.60667C13.7014 8.60667 13.9998 8.90514 13.9998 9.27333V12.8333C13.9998 13.3638 13.7891 13.8725 13.4141 14.2475C13.039 14.6226 12.5303 14.8333 11.9998 14.8333H2.6665C2.13607 14.8333 1.62736 14.6226 1.25229 14.2475C0.877218 13.8725 0.666504 13.3638 0.666504 12.8333V3.5C0.666504 2.96957 0.877217 2.46086 1.25229 2.08579C1.62736 1.71071 2.13607 1.5 2.6665 1.5H6.2265C6.59469 1.5 6.89317 1.79848 6.89317 2.16667C6.89317 2.53486 6.59469 2.83333 6.2265 2.83333H2.6665Z"
      fill="#333333"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.5284 0.361888C11.7888 0.101539 12.2109 0.101539 12.4712 0.361888L15.1379 3.02855C15.3983 3.2889 15.3983 3.71101 15.1379 3.97136L8.47124 10.638C8.34622 10.7631 8.17665 10.8333 7.99984 10.8333H5.33317C4.96498 10.8333 4.6665 10.5348 4.6665 10.1666V7.49996C4.6665 7.32315 4.73674 7.15358 4.86177 7.02855L11.5284 0.361888ZM5.99984 7.7761V9.49996H7.7237L13.7237 3.49996L11.9998 1.7761L5.99984 7.7761Z"
      fill="#333333"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 24 25"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.46428 17.6204C5.07376 18.0109 5.07376 18.6441 5.46428 19.0346C5.8548 19.4252 6.48797 19.4252 6.87849 19.0346L11.9998 13.9133L18.1922 20.1057C18.5827 20.4962 19.2159 20.4962 19.6064 20.1057C19.9969 19.7152 19.9969 19.082 19.6064 18.6915L13.414 12.4991L19.6064 6.30672C19.9969 5.91619 19.9969 5.28303 19.6064 4.8925C19.2159 4.50198 18.5827 4.50198 18.1922 4.8925L11.9998 11.0849L6.87849 5.96357C6.48797 5.57305 5.8548 5.57305 5.46428 5.96357C5.07376 6.35409 5.07376 6.98726 5.46428 7.37778L10.5856 12.4991L5.46428 17.6204Z"
      fill="#ED017F"
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M18 6.5H15C15 6.5 14 6.32843 14 5.5C14 4.67157 15 4.5 15 4.5H20V9.5C20 9.5 20 10.5 19 10.5C18 10.5 18 9.5 18 9.5V6.5ZM9 18.5H6V15.5C6 15.5 6 14.5 5 14.5C4 14.5 4 15.5 4 15.5V20.5H9C9 20.5 10 20.3284 10 19.5C10 18.6716 9 18.5 9 18.5ZM18 15.5V18.5H15C15 18.5 14 18.5 14 19.5C14 20.5 15 20.5 15 20.5H20V15.5C20 15.5 19.8284 14.5 19 14.5C18.1716 14.5 18 15.5 18 15.5ZM6 9.5V6.5H9C9 6.5 10 6.5 10 5.5C10 4.5 9 4.5 9 4.5H4V9.5C4 9.5 4.17157 10.5 5 10.5C5.82843 10.5 6 9.5 6 9.5Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

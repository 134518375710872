/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.52513 2.19114C5.1815 1.53476 6.07174 1.16602 7 1.16602H16.3333C16.6428 1.16602 16.9395 1.28893 17.1583 1.50772L24.1583 8.50772C24.3771 8.72652 24.5 9.02326 24.5 9.33268V23.3327C24.5 24.2609 24.1313 25.1512 23.4749 25.8076C22.8185 26.4639 21.9283 26.8327 21 26.8327H7C6.07174 26.8327 5.1815 26.4639 4.52513 25.8076C3.86875 25.1512 3.5 24.2609 3.5 23.3327V4.66602C3.5 3.73776 3.86875 2.84752 4.52513 2.19114ZM7 3.49935C6.69058 3.49935 6.39383 3.62227 6.17504 3.84106C5.95625 4.05985 5.83333 4.3566 5.83333 4.66602V23.3327C5.83333 23.6421 5.95625 23.9388 6.17504 24.1576C6.39383 24.3764 6.69058 24.4993 7 24.4993H21C21.3094 24.4993 21.6062 24.3764 21.825 24.1576C22.0437 23.9388 22.1667 23.6421 22.1667 23.3327V9.81593L15.8501 3.49935H7Z"
      fill="#999999"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.3333 1.16602C16.9777 1.16602 17.5 1.68835 17.5 2.33268V8.16602H23.3333C23.9777 8.16602 24.5 8.68835 24.5 9.33268C24.5 9.97701 23.9777 10.4993 23.3333 10.4993H16.3333C15.689 10.4993 15.1667 9.97701 15.1667 9.33268V2.33268C15.1667 1.68835 15.689 1.16602 16.3333 1.16602Z"
      fill="#999999"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.16667 15.166C8.16667 14.5217 8.689 13.9993 9.33333 13.9993H18.6667C19.311 13.9993 19.8333 14.5217 19.8333 15.166C19.8333 15.8103 19.311 16.3327 18.6667 16.3327H9.33333C8.689 16.3327 8.16667 15.8103 8.16667 15.166Z"
      fill="#999999"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.16667 19.8327C8.16667 19.1883 8.689 18.666 9.33333 18.666H18.6667C19.311 18.666 19.8333 19.1883 19.8333 19.8327C19.8333 20.477 19.311 20.9993 18.6667 20.9993H9.33333C8.689 20.9993 8.16667 20.477 8.16667 19.8327Z"
      fill="#999999"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.16667 10.4993C8.16667 9.85502 8.689 9.33268 9.33333 9.33268H11.6667C12.311 9.33268 12.8333 9.85502 12.8333 10.4993C12.8333 11.1437 12.311 11.666 11.6667 11.666H9.33333C8.689 11.666 8.16667 11.1437 8.16667 10.4993Z"
      fill="#999999"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="51"
    viewBox="0 0 58 51"
    width="58"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="28.6667" cy="43.6152" fill="#ECECEC" rx="28.6667" ry="7" />
    <g clipPath="url(#clip0)">
      <path
        d="M48.7483 12.3428C48.4986 11.985 48.0968 11.7638 47.6604 11.7452L20.6054 10.579C19.8292 10.545 19.1805 11.1443 19.1473 11.917C19.1143 12.6893 19.7126 13.3419 20.4852 13.3751L45.7085 14.4624L40.7491 29.9362H18.679L14.6917 8.2257C14.604 7.74988 14.2774 7.35291 13.8268 7.17615L7.2963 4.61058C6.57656 4.3288 5.76438 4.68232 5.48172 5.40119C5.19954 6.12045 5.55297 6.93311 6.27232 7.21578L12.079 9.49692L16.1366 31.5885C16.2589 32.2526 16.8377 32.735 17.5133 32.735H18.1864L16.6494 37.0042C16.5207 37.3619 16.5737 37.759 16.7927 38.0697C17.0113 38.3805 17.3669 38.5654 17.7465 38.5654H18.8245C18.1565 39.3088 17.7465 40.2877 17.7465 41.3642C17.7465 43.6792 19.6301 45.5624 21.9447 45.5624C24.2592 45.5624 26.1429 43.6792 26.1429 41.3642C26.1429 40.2878 25.7328 39.3088 25.0649 38.5654H34.2181C33.5498 39.3088 33.1398 40.2877 33.1398 41.3642C33.1398 43.6792 35.023 45.5624 37.3381 45.5624C39.6532 45.5624 41.5363 43.6792 41.5363 41.3642C41.5363 40.2878 41.1263 39.3088 40.4585 38.5654H41.7696C42.4137 38.5654 42.9357 38.0433 42.9357 37.3993C42.9357 36.7551 42.4137 36.2331 41.7696 36.2331H19.4057L20.6651 32.7346H41.7695C42.378 32.7346 42.9165 32.3414 43.1019 31.7624L48.9327 13.57C49.0666 13.1548 48.9979 12.7008 48.7483 12.3428ZM21.9448 43.2305C20.9157 43.2305 20.0788 42.3937 20.0788 41.3647C20.0788 40.3356 20.9157 39.4988 21.9448 39.4988C22.9738 39.4988 23.8106 40.3356 23.8106 41.3647C23.8106 42.3937 22.9738 43.2305 21.9448 43.2305ZM37.3381 43.2305C36.309 43.2305 35.4722 42.3937 35.4722 41.3647C35.4722 40.3356 36.309 39.4988 37.3381 39.4988C38.3671 39.4988 39.2039 40.3356 39.2039 41.3647C39.2039 42.3937 38.3671 43.2305 37.3381 43.2305Z"
        fill="#BABABA"
      />
    </g>
    <path d="M33.3846 6.46154V0" stroke="#BABABA" strokeWidth="1.07692" />
    <path
      d="M38.7692 7.53786L41.9999 2.96094"
      stroke="#BABABA"
      strokeWidth="1.07692"
    />
    <path
      d="M27.4615 6.9997L23.6923 3.23047"
      stroke="#BABABA"
      strokeWidth="1.07692"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          fill="white"
          height="43.6154"
          transform="translate(5.38464 3.23047)"
          width="43.6154"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

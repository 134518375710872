/* eslint-disable max-len */
import React from "react";

/**
 * Whatsapp Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any) => (
  <svg height="38" viewBox="0 0 38 38" width="38" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        d="M0 38l2.62-9.5C.918 25.552 0 22.276 0 18.869 0 8.517 8.517 0 18.934 0 29.352 0 38 8.517 38 18.869S29.352 38 18.934 38c-3.275 0-6.42-1.048-9.237-2.62L0 38z"
        fill="#FFF"
      />
      <path
        d="M10.22 31.972l.59.328c2.424 1.507 5.242 2.424 8.19 2.424 8.583 0 15.724-7.141 15.724-15.855S27.583 3.276 18.934 3.276c-8.648 0-15.658 6.945-15.658 15.593 0 3.014.852 5.962 2.424 8.452l.393.59-1.507 5.503 5.635-1.442z"
        fill="#3EC850"
      />
      <path
        d="M13.89 10.09l-1.245-.066c-.393 0-.786.131-1.048.393-.59.524-1.573 1.507-1.835 2.818-.458 1.965.262 4.324 1.966 6.682 1.703 2.359 4.979 6.159 10.744 7.797 1.835.524 3.276.196 4.456-.524a3.866 3.866 0 0 0 1.703-2.49l.197-.917a.659.659 0 0 0-.328-.72l-4.128-1.9a.625.625 0 0 0-.786.196l-1.638 2.096a.51.51 0 0 1-.524.131c-1.114-.393-4.848-1.965-6.88-5.896-.065-.197-.065-.393.066-.524l1.573-1.77c.13-.196.196-.458.13-.655l-1.9-4.258c-.065-.197-.261-.393-.523-.393"
        fill="#FEFEFE"
      />
    </g>
  </svg>
);

export default SVG;
/* eslint-enable max-len */

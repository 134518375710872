/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 218 48"
    width="218"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M153.964 1.05833e-06C155.405 0.0241203 156.826 0.335422 158.145 0.915681C159.428 1.4799 160.595 2.27818 161.586 3.26969C163.623 5.32267 164.763 8.1 164.755 10.9923C164.769 12.4644 164.479 13.9234 163.902 15.2781C162.77 17.9283 160.658 20.0388 158.007 21.1693C156.655 21.7499 155.197 22.0446 153.725 22.0348H139.76V37.0871H136.307V1.67247C136.304 1.44849 136.349 1.22647 136.438 1.02106C136.527 0.81566 136.659 0.631536 136.825 0.480837C137.146 0.171862 137.575 -0.000492788 138.021 1.05833e-06H153.964ZM153.717 18.6188C154.723 18.6241 155.719 18.422 156.644 18.0251C158.473 17.2497 159.928 15.7939 160.704 13.9652C161.103 13.0254 161.306 12.0135 161.298 10.9923C161.302 9.98638 161.1 8.99021 160.704 8.06551C159.928 6.23675 158.473 4.78095 156.644 4.00558C155.719 3.61061 154.723 3.40857 153.717 3.41185H139.752V18.6188H153.717Z"
        fill="white"
      />
      <path
        d="M177.437 10.2021C181.727 10.2021 185.061 11.398 187.438 13.7896C189.816 16.1812 191.005 19.5081 191.005 23.7701V29.7116C190.974 30.4444 190.841 31.1693 190.608 31.8649C190.258 32.8565 189.631 33.7271 188.801 34.3736C187.589 35.3299 186.201 36.04 184.716 36.4642C182.935 37.0412 180.509 37.3297 177.437 37.3297C174.365 37.3297 171.944 37.044 170.174 36.4725C168.689 36.0484 167.302 35.3382 166.089 34.3819C165.26 33.7355 164.633 32.8649 164.283 31.8732C164.048 31.1782 163.914 30.453 163.886 29.7199C163.886 27.7074 164.199 26.1227 164.826 24.9659C165.392 23.8733 166.264 22.9694 167.335 22.3652C168.497 21.7553 169.767 21.3775 171.073 21.253C172.61 21.0839 174.155 21.0015 175.702 21.0063H187.292C186.826 18.6286 185.77 16.8168 184.123 15.5708C182.475 14.3248 180.245 13.6976 177.433 13.6893C174.592 13.6893 172.298 14.3652 170.551 15.7171L168.422 12.9617C170.769 11.122 173.774 10.2021 177.437 10.2021ZM187.539 24.4642H177.437C175.224 24.4642 173.449 24.5213 172.114 24.6356C171.054 24.6835 170.012 24.9273 169.041 25.3548C168.715 25.5095 168.424 25.7302 168.187 26.003C167.95 26.2757 167.773 26.5946 167.666 26.9394C167.423 27.8428 167.315 28.7767 167.344 29.7116C167.341 30.0791 167.42 30.4427 167.574 30.7765C167.728 31.1103 167.953 31.4062 168.234 31.6433C168.918 32.2212 169.71 32.6588 170.563 32.9311C171.615 33.2822 172.701 33.5232 173.803 33.6502C175.012 33.7973 176.228 33.8713 177.445 33.8718C178.661 33.8714 179.876 33.7974 181.083 33.6502C182.186 33.5236 183.274 33.2826 184.328 32.9311C185.181 32.6588 185.972 32.2212 186.656 31.6433C186.937 31.4062 187.163 31.1103 187.317 30.7765C187.47 30.4427 187.549 30.0791 187.547 29.7116L187.539 24.4642Z"
        fill="white"
      />
      <path
        d="M217.451 10.7451L201.006 47.9869H197.243L202.591 35.9033L191.452 10.7451H195.215L204.414 31.5925L213.684 10.7451H217.451Z"
        fill="white"
      />
      <path
        d="M26.8557 0.422852H21.1861L4.12683 18.3727V0.422852H0V37.3636H4.12683V24.335L7.88571 20.4006L21.4578 37.3176H26.7638L10.7247 17.3901L26.8557 0.422852Z"
        fill="white"
      />
      <path
        d="M38.1199 10.3779C33.8467 10.3779 30.4725 11.5947 28.085 13.9905C25.6976 16.3863 24.4976 19.7521 24.4976 23.9919C24.4976 28.2316 25.7017 31.6393 28.085 34.0267C30.4683 36.4142 33.8383 37.6142 38.1199 37.6142C42.4014 37.6142 45.7631 36.41 48.1547 34.0267C50.5463 31.6434 51.7422 28.2734 51.7422 23.9919C51.7422 19.7103 50.5338 16.3905 48.1547 13.9905C45.7756 11.5905 42.3888 10.3779 38.1199 10.3779ZM38.1199 33.479C35.0132 33.479 32.63 32.672 31.0369 31.0748C29.4439 29.4776 28.6328 27.0985 28.6328 23.9919C28.6328 20.8852 29.4397 18.5062 31.0369 16.9089C32.6341 15.3117 35.0132 14.5048 38.1199 14.5048C41.2265 14.5048 43.6056 15.3117 45.2028 16.9089C46.8 18.5062 47.607 20.8894 47.607 23.9919C47.607 27.0943 46.7958 29.4818 45.2028 31.0748C43.6098 32.6678 41.2223 33.479 38.1199 33.479Z"
        fill="white"
      />
      <path
        d="M65.0592 10.4277C61.2585 10.4277 58.2606 11.6486 56.1533 14.057C54.046 16.4654 52.9882 19.8103 52.9882 24.0417V37.3629H57.115V24.0417C57.115 20.956 57.8049 18.5727 59.1554 16.9587C60.5059 15.3448 62.5004 14.5546 65.0592 14.5546C67.6181 14.5546 69.6209 15.3615 70.9589 16.9587C72.2969 18.556 72.9993 20.956 72.9993 24.0417V37.3629H77.1261V24.0417C77.1261 19.8103 76.0558 16.4528 73.9401 14.057C71.8244 11.6612 68.8265 10.4277 65.0592 10.4277Z"
        fill="white"
      />
      <path
        d="M92.0153 10.3779C87.7422 10.3779 84.3721 11.5947 81.9805 13.9905C79.5889 16.3863 78.393 19.7521 78.393 23.9919C78.393 28.2316 79.6014 31.6393 81.9805 34.0267C84.3596 36.4142 87.7338 37.6142 92.0153 37.6142C95.9498 37.6142 99.1233 36.5814 101.456 34.5326C101.456 37.5598 100.62 39.8971 99.0523 41.4776C97.4843 43.0581 95.0885 43.8818 92.0153 43.8818H82.4279V48.0086H92.0153C96.2551 48.0086 99.6167 46.8002 102.017 44.417C104.417 42.0337 105.629 38.6804 105.629 34.4407V23.9877C105.629 19.7521 104.421 16.3863 102.042 13.9863C99.6627 11.5863 96.2843 10.3779 92.0153 10.3779ZM92.0153 14.5048C95.1178 14.5048 97.501 15.3117 99.0983 16.9089C100.695 18.5062 101.502 20.8894 101.502 23.9793L101.456 25.2671C101.239 27.9598 100.332 30.0211 98.7554 31.3967C97.1791 32.7724 94.9045 33.4873 92.0153 33.4873C88.9087 33.4873 86.5254 32.6804 84.9324 31.0832C83.3394 29.4859 82.5282 27.1069 82.5282 24.0002C82.5282 20.8936 83.3352 18.5145 84.9324 16.9173C86.5296 15.3201 88.9129 14.5048 92.0153 14.5048Z"
        fill="white"
      />
      <path
        d="M130.035 13.9905C127.652 11.5947 124.282 10.3779 120 10.3779C116.354 10.3779 113.31 11.3145 110.973 13.1668L110.684 13.3926L113.218 16.633L113.511 16.4072C115.141 15.1528 117.328 14.5048 120 14.5048C122.672 14.5048 124.762 15.0985 126.301 16.265C127.779 17.4218 128.798 19.0671 129.174 20.9062H118.328C116.804 20.9021 115.281 20.9831 113.766 21.1487C112.437 21.2798 111.146 21.6674 109.965 22.2901C108.851 22.9115 107.943 23.8444 107.352 24.9744C106.712 26.1535 106.39 27.7717 106.39 29.7912C106.424 30.5446 106.565 31.2893 106.808 32.003C107.172 33.0424 107.823 33.9573 108.686 34.6413C109.911 35.5979 111.309 36.3078 112.804 36.7319C114.569 37.3048 116.985 37.5933 120.021 37.5933C123.056 37.5933 125.456 37.3048 127.242 36.7319C128.735 36.307 130.132 35.5971 131.356 34.6413C132.219 33.9573 132.87 33.0424 133.233 32.003C133.478 31.2896 133.619 30.5447 133.652 29.7912V23.9919C133.631 19.7563 132.426 16.3905 130.035 13.9905ZM123.516 33.2657C121.186 33.55 118.83 33.55 116.5 33.2657C115.45 33.1442 114.415 32.9158 113.41 32.5842C112.625 32.3331 111.897 31.9312 111.266 31.4009C111.029 31.2049 110.84 30.9587 110.711 30.6802C110.583 30.4017 110.518 30.098 110.521 29.7912C110.495 28.9159 110.595 28.0415 110.818 27.1947C110.91 26.9047 111.061 26.6369 111.261 26.4087C111.462 26.1805 111.709 25.9967 111.985 25.8692C112.89 25.4764 113.859 25.2525 114.845 25.2086C116.137 25.0957 117.872 25.0413 120.008 25.0413H129.495V29.7912C129.503 30.0956 129.442 30.3979 129.319 30.6763C129.195 30.9546 129.011 31.2021 128.781 31.4009C128.15 31.932 127.421 32.334 126.636 32.5842C125.625 32.9168 124.582 33.1451 123.525 33.2657H123.516Z"
        fill="white"
      />
      <path d="M105.629 11.8584H101.502V22.002H105.629V11.8584Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="48" width="217.451" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 27 32"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.99897 10.0128C0.894911 10.0128 0 10.796 0 11.7645V19.2571C0 20.2246 0.89502 21.0099 1.99897 21.0099C3.10192 21.0099 3.99793 20.2247 3.99793 19.2571V11.7645C3.99804 10.797 3.10203 10.0128 1.99897 10.0128ZM17.119 2.68545L18.8659 0.940716C19.0819 0.725416 19.0819 0.377876 18.8659 0.161475C18.65 -0.0538251 18.302 -0.0538251 18.0859 0.161475L15.936 2.31096C15.167 2.12261 14.356 2.01854 13.5121 2.01854C12.6781 2.01854 11.8741 2.12173 11.114 2.30502L8.968 0.161585C8.75204 -0.0537151 8.40295 -0.0537151 8.18799 0.161585C7.97203 0.376886 7.97203 0.725416 8.18799 0.940826L9.92596 2.67654C7.02604 3.79023 5.01104 6.20496 5.01104 9.01124C5.01104 9.04535 22.011 9.01322 22.011 9.01124C22.012 6.21201 20.008 3.80321 17.119 2.68545ZM11.011 7.01325C10.4581 7.01325 10.011 6.56659 10.011 6.01365C10.011 5.46181 10.4579 5.01405 11.011 5.01405C11.5641 5.01405 12.011 5.4617 12.011 6.01365C12.012 6.56549 11.562 7.01325 11.011 7.01325ZM16.0121 7.01325C15.4591 7.01325 15.012 6.56659 15.012 6.01365C15.012 5.46181 15.459 5.01405 16.0121 5.01405C16.565 5.01405 17.0121 5.4617 17.0121 6.01365C17.0121 6.5656 16.564 7.01325 16.0121 7.01325ZM25.002 10.0128C23.898 10.0128 23.0021 10.796 23.0021 11.7645V19.2571C23.0021 20.2246 23.8981 21.0099 25.002 21.0099C26.106 21.0099 27 20.2237 27 19.2571V11.7645C27 10.797 26.104 10.0128 25.002 10.0128ZM5.01104 22.0005C5.01104 23.653 6.34906 24.9911 8.00103 24.9971V30.2472C8.00103 31.2157 8.89605 32 10 32C11.103 32 11.998 31.2158 11.998 30.2472V24.9971H15.002V30.2472C15.002 31.2157 15.897 32 17.001 32C18.104 32 18.999 31.2158 18.999 30.2472V24.9971H19.0119C20.669 24.9971 22.0119 23.655 22.0119 22.0005V10.0099H5.01203V22.0005H5.01104Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

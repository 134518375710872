/* eslint-disable max-len */
import React from "react";

/**
 * Cash Exchange Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any) => (
  <svg height="20" width="20" {...props}>
    <path
      d="M19.85.11a.39.39 0 0 0-.28-.11H9.98a.39.39 0 0 0-.39.39v1.12l-3.7.99a.39.39 0 0 0-.27.47l1.91 7.15-1.65 1.66c-.21-.31-.57-.51-.97-.51H1.17c-.65 0-1.17.52-1.17 1.17v6.35c0 .65.52 1.17 1.17 1.17h3.74c.55 0 1.01-.38 1.14-.89l.46.4c.36.32.82.5 1.3.5h11.76c.22 0 .4-.18.4-.4V.4c0-.1-.05-.2-.12-.28zM5.3 18.8c0 .22-.17.4-.39.4H1.17a.39.39 0 0 1-.39-.4v-6.35c0-.22.17-.4.39-.4h1.48v4.5a.39.39 0 0 0 .78 0v-4.5h1.48c.22 0 .4.18.4.4v6.35zM6.47 3.15L9.6 2.3v1.45l-.26.07a.39.39 0 0 0-.28.47c.15.56-.18 1.14-.74 1.29a.39.39 0 0 0-.27.48l1.52 5.63h-.79l-.4-1.58a.39.39 0 0 0-.07-.14L6.47 3.15zm3.12 2.4v3.28L8.88 6.2c.3-.15.54-.38.71-.66zm5.6 13.63H7.8c-.28 0-.56-.1-.78-.3l-.94-.81v-5.4l1.7-1.7.31 1.21c.05.17.2.3.38.3h7.67a.55.55 0 0 1 0 1.09H12.2a.39.39 0 0 0 0 .78h5.15a.55.55 0 0 1 0 1.09H12.2a.39.39 0 0 0 0 .78h4.31a.55.55 0 0 1 0 1.09h-4.31a.39.39 0 0 0 0 .78h2.98a.55.55 0 0 1 0 1.1zm4 0h-2.8a1.32 1.32 0 0 0 0-1.09h.13a1.33 1.33 0 0 0 1.19-1.92 1.33 1.33 0 0 0 .7-2.07V9.16a.39.39 0 0 0-.77 0v4.44l-.28-.03h-.02a1.33 1.33 0 0 0-1.2-1.87h-.63a1.87 1.87 0 1 0-1.47 0h-2.13V4.28c.7-.15 1.25-.7 1.4-1.4h2.93c.15.7.7 1.25 1.4 1.4V5.5a.39.39 0 0 0 .78 0V3.94a.39.39 0 0 0-.4-.4c-.57 0-1.04-.47-1.04-1.04a.39.39 0 0 0-.39-.4h-3.63a.39.39 0 0 0-.39.4c0 .57-.47 1.05-1.05 1.05a.39.39 0 0 0-.39.39v7.76h-.76V.78h8.81v18.4zm-4.41-8.11a1.09 1.09 0 1 1 0-2.18 1.09 1.09 0 0 1 0 2.18z"
      fill="#ed017f"
      fillRule="nonzero"
    />
  </svg>
);

export default SVG;
/* eslint-enable max-len */

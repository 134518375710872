/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 32 25"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.6363 6.04441H23.2727V0H2.90911C1.30179 0 0 1.35242 0 3.02224V19.6445H2.90911C2.90911 22.1454 4.86545 24.1778 7.27274 24.1778C9.68003 24.1778 11.6364 22.1454 11.6364 19.6445H20.3636C20.3636 22.1454 22.32 24.1778 24.7273 24.1778C27.1346 24.1778 29.0909 22.1454 29.0909 19.6445H32V12.0889L27.6363 6.04441ZM7.27274 21.911C6.06545 21.911 5.09092 20.8986 5.09092 19.6444C5.09092 18.3901 6.06545 17.3777 7.27274 17.3777C8.48003 17.3777 9.45455 18.3901 9.45455 19.6444C9.45455 20.8986 8.47996 21.911 7.27274 21.911ZM24.7273 21.911C23.52 21.911 22.5454 20.8986 22.5454 19.6444C22.5454 18.3901 23.52 17.3777 24.7273 17.3777C25.9346 17.3777 26.9091 18.3901 26.9091 19.6444C26.9091 20.8986 25.9345 21.911 24.7273 21.911ZM23.2727 12.0889V8.31114H26.909L29.7672 12.0889H23.2727Z"
      fill="#5A5F66"
    />
  </svg>
);

export default SVG;

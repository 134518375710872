/* eslint-disable max-len */
import React from "react";

/**
 * Clock Icon
 * @param {*} props The Component's props
 * @returns {*} React.Component
 */
const SVG = (props: any) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_16989_70035)">
      <path
        d="M8.00781 0.753906C3.87231 0.753906 0.507812 4.11841 0.507812 8.25391C0.507812 12.3894 3.87231 15.7539 8.00781 15.7539C12.1433 15.7539 15.5078 12.3894 15.5078 8.25391C15.5078 4.11841 12.1433 0.753906 8.00781 0.753906ZM8.00781 14.158C4.75231 14.158 2.10353 11.5096 2.10353 8.25391C2.10353 4.99825 4.75231 2.34978 8.00781 2.34978C11.2633 2.34978 13.9121 4.99825 13.9121 8.25391C13.9121 11.5096 11.2633 14.158 8.00781 14.158Z"
        fill="#F9A024"
      />
      <path
        d="M11.9161 8.02267H8.54562V3.97008C8.54562 3.62904 8.26912 3.35254 7.92808 3.35254C7.58705 3.35254 7.31055 3.62904 7.31055 3.97008V8.64021C7.31055 8.98124 7.58705 9.25775 7.92808 9.25775H11.9161C12.2572 9.25775 12.5337 8.98124 12.5337 8.64021C12.5337 8.29917 12.2572 8.02267 11.9161 8.02267Z"
        fill="#F9A024"
      />
    </g>
    <defs>
      <clipPath id="clip0_16989_70035">
        <rect
          fill="white"
          height="15"
          transform="translate(0.507812 0.753906)"
          width="15"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
/* eslint-enable max-len */

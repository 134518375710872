/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 10 14"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.734373 4.04614C1.04211 4.04614 1.04967 3.60254 0.734373 3.60254C0.417526 3.60254 0.427897 4.04614 0.734373 4.04614Z"
      fill="#555555"
    />
    <path
      d="M8.52734 0H1.61328C0.999424 0 0.5 0.451579 0.5 1.00663V2.99339C0.5 3.11043 0.604941 3.20532 0.734375 3.20532C0.863809 3.20532 0.96875 3.11043 0.96875 2.99339V1.00663C0.96875 0.685275 1.25788 0.423843 1.61328 0.423843H8.52734C8.88274 0.423843 9.17188 0.685275 9.17188 1.00663V12.5564C9.17188 12.8777 8.88274 13.1391 8.52734 13.1391H1.61328C1.25788 13.1391 0.96875 12.8777 0.96875 12.5564V11.3378H8.41016C8.53959 11.3378 8.64453 11.2429 8.64453 11.1259C8.64453 11.0089 8.53959 10.914 8.41016 10.914H0.96875V4.71857C0.96875 4.60154 0.863809 4.50665 0.734375 4.50665C0.604941 4.50665 0.5 4.60154 0.5 4.71857V12.5564C0.5 13.1114 0.999424 13.563 1.61328 13.563H8.52734C9.1412 13.563 9.64062 13.1114 9.64062 12.5564V1.00663C9.64062 0.451579 9.1412 0 8.52734 0Z"
      fill="#555555"
    />
    <path
      d="M4.2793 12.2388C4.2793 12.6332 4.63414 12.9541 5.07031 12.9541C5.50648 12.9541 5.86133 12.6332 5.86133 12.2388C5.86133 11.8443 5.50648 11.5234 5.07031 11.5234C4.63414 11.5234 4.2793 11.8443 4.2793 12.2388ZM5.39258 12.2388C5.39258 12.3995 5.248 12.5302 5.07031 12.5302C4.89263 12.5302 4.74805 12.3995 4.74805 12.2388C4.74805 12.0781 4.89263 11.9473 5.07031 11.9473C5.248 11.9473 5.39258 12.0781 5.39258 12.2388Z"
      fill="#555555"
    />
    <path
      d="M5.74414 0.901367H4.39648C4.26705 0.901367 4.16211 0.996306 4.16211 1.1134C4.16211 1.2305 4.26705 1.32544 4.39648 1.32544H5.74414C5.87357 1.32544 5.97852 1.2305 5.97852 1.1134C5.97852 0.996306 5.87357 0.901367 5.74414 0.901367Z"
      fill="#555555"
    />
    <path
      d="M3.14677 4.83063L2.67802 5.25465C2.58649 5.33747 2.58649 5.47168 2.67802 5.55449C2.7696 5.63728 2.91793 5.63726 3.00948 5.55449L3.47823 5.13047C3.56976 5.04765 3.56976 4.91345 3.47823 4.83063C3.38668 4.74786 3.23832 4.74786 3.14677 4.83063Z"
      fill="#555555"
    />
    <path
      d="M5.88603 4.23395L5.74687 4.35985C5.65534 4.44267 5.65534 4.57689 5.74687 4.65971C5.83845 4.74251 5.98678 4.74248 6.07833 4.65971L6.21749 4.53382C6.30901 4.45099 6.30901 4.31678 6.21749 4.23395C6.12594 4.15118 5.97758 4.15118 5.88603 4.23395Z"
      fill="#555555"
    />
    <path
      d="M3.11747 7.03742C3.20905 7.12019 3.35738 7.12016 3.44894 7.03742L5.44112 5.23583C5.53265 5.15304 5.53265 5.01887 5.44112 4.93608C5.34957 4.85334 5.20121 4.85334 5.10966 4.93608L3.11747 6.73767C3.02595 6.82046 3.02595 6.95463 3.11747 7.03742Z"
      fill="#555555"
    />
    <path
      d="M6.70634 5.58549L5.72489 6.47294C5.63337 6.55572 5.63337 6.68987 5.72489 6.77266C5.81647 6.85542 5.96481 6.85539 6.05636 6.77266L7.0378 5.88521C7.12933 5.80242 7.12933 5.66827 7.0378 5.58549C6.94625 5.50275 6.79789 5.50275 6.70634 5.58549Z"
      fill="#555555"
    />
    <path
      d="M5.6663 8.4941C5.75788 8.57685 5.90621 8.57682 5.99776 8.4941L7.5212 7.11663C7.61272 7.03385 7.61272 6.89971 7.5212 6.81693C7.42965 6.7342 7.28129 6.7342 7.18974 6.81693L5.6663 8.19439C5.57478 8.27717 5.57478 8.41132 5.6663 8.4941Z"
      fill="#555555"
    />
  </svg>
);

export default SVG;

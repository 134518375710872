/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    className="icon"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9818 18.5691V12.1419H9.30176V5.42188H14.6778L13.0314 10.8939H14.6778L10.9818 18.5691Z"
      fill="white"
    />
    <path
      d="M11.3466 18.567C11.3466 16.6854 11.3466 14.8086 11.3466 12.927C11.3466 12.663 11.3466 12.399 11.3466 12.1398C11.3466 11.9526 11.1882 11.799 11.0058 11.799C10.4346 11.799 9.86821 11.799 9.29701 11.799C9.41221 11.9142 9.52741 12.0294 9.63781 12.1398C9.63781 10.1766 9.63781 8.21342 9.63781 6.25022C9.63781 5.97182 9.63781 5.69342 9.63781 5.41502C9.52261 5.53022 9.40741 5.64542 9.29701 5.75582C10.8714 5.75582 12.4458 5.75582 14.0202 5.75582C14.241 5.75582 14.4618 5.75582 14.6826 5.75582C14.5722 5.61182 14.4618 5.46782 14.3514 5.32382C13.8714 6.91742 13.3914 8.51102 12.9114 10.1046C12.8442 10.3302 12.7722 10.5606 12.705 10.7862C12.6426 11.0022 12.8154 11.2182 13.0362 11.2182C13.5834 11.2182 14.1354 11.2182 14.6826 11.2182C14.5818 11.0454 14.4858 10.8726 14.385 10.7046C13.9722 11.5686 13.5594 12.4326 13.1466 13.2966C12.489 14.6742 11.8266 16.0566 11.169 17.4342C11.0154 17.7558 10.8618 18.0726 10.7082 18.3942C10.521 18.7926 11.1114 19.1382 11.2986 18.7398C11.7114 17.8758 12.1242 17.0118 12.537 16.1478C13.1946 14.7702 13.857 13.3878 14.5146 12.0102C14.6682 11.6886 14.8218 11.3718 14.9754 11.0502C15.0858 10.8198 14.9562 10.5366 14.6778 10.5366C14.1306 10.5366 13.5786 10.5366 13.0314 10.5366C13.1418 10.6806 13.2522 10.8246 13.3626 10.9686C13.8426 9.37502 14.3226 7.78142 14.8026 6.18782C14.8698 5.96222 14.9418 5.73182 15.009 5.50622C15.0714 5.29022 14.8986 5.07422 14.6778 5.07422C13.1034 5.07422 11.529 5.07422 9.95461 5.07422C9.73381 5.07422 9.51301 5.07422 9.29222 5.07422C9.10502 5.07422 8.95142 5.23262 8.95142 5.41502C8.95142 7.37822 8.95142 9.34142 8.95142 11.3046C8.95142 11.583 8.95142 11.8614 8.95142 12.1398C8.95142 12.327 9.10982 12.4806 9.29222 12.4806C9.86341 12.4806 10.4298 12.4806 11.001 12.4806C10.8858 12.3654 10.7706 12.2502 10.6602 12.1398C10.6602 14.0214 10.6602 15.8982 10.6602 17.7798C10.6602 18.0438 10.6602 18.3078 10.6602 18.567C10.665 19.0086 11.3466 19.0086 11.3466 18.567Z"
      fill="white"
    />
    <path
      d="M21.9114 11.9918C21.9067 14.0606 21.2635 16.0718 20.0634 17.7614C18.9211 19.3742 17.2603 20.6414 15.3979 21.3134C13.4251 22.0286 11.2555 22.1102 9.23945 21.5294C7.29545 20.9726 5.56745 19.8158 4.28585 18.2606C1.65065 15.059 1.34825 10.3886 3.48425 6.851C4.52585 5.1326 6.08105 3.7646 7.90985 2.9294C9.78185 2.075 11.9515 1.8446 13.9675 2.2574C15.9451 2.6654 17.7594 3.6686 19.1562 5.123C20.5578 6.5822 21.4746 8.4542 21.7962 10.451C21.8778 10.9646 21.9114 11.4782 21.9114 11.9918C21.9114 12.611 22.8715 12.611 22.8715 11.9918C22.8666 9.755 22.1754 7.5326 20.8747 5.7134C19.5931 3.9086 17.7978 2.531 15.7195 1.763C13.5931 0.975799 11.2027 0.898999 9.01865 1.5134C6.92105 2.1086 5.00105 3.3518 3.61385 5.0414C2.18345 6.779 1.31465 8.9102 1.13705 11.1518C0.959452 13.3934 1.50185 15.6878 2.66345 17.6126C3.78665 19.4702 5.48105 20.9966 7.46345 21.8942C9.56105 22.8446 11.9082 23.1134 14.1691 22.6622C16.3194 22.235 18.3258 21.1166 19.8426 19.5374C21.3643 17.9486 22.401 15.899 22.737 13.7198C22.8235 13.1486 22.8762 12.5726 22.8762 11.9966C22.8762 11.3726 21.9163 11.3726 21.9114 11.9918Z"
      fill="white"
    />
  </svg>
);

export default SVG;

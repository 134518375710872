/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7249 3.61995C20.5849 3.35995 20.3049 3.19995 20.0249 3.19995H4.00492C3.70492 3.19995 3.44492 3.35995 3.30492 3.61995C3.16492 3.87995 3.22492 4.19994 3.38492 4.43994L9.62491 13.6V19.66C9.62491 20.28 10.0449 20.7999 10.6849 20.7999H13.3649C13.9849 20.7999 14.4249 20.3 14.4249 19.66V13.6L20.6649 4.43994C20.8049 4.19994 20.8649 3.87995 20.7249 3.61995ZM12.9849 12.9199C12.9049 13.0599 12.8049 13.2 12.8049 13.36V19.2H11.2049V13.36C11.2049 13.2 11.1249 13.0399 11.0249 12.9199L9.12491 10.1399C9.52491 10.0199 10.0049 9.89995 10.5249 9.91995C11.1449 9.91995 11.5649 10.04 12.0249 10.16C12.5049 10.28 12.9849 10.3999 13.6649 10.4199C14.0649 10.4199 14.4249 10.4 14.7449 10.36L12.9849 12.9199ZM15.9049 8.63994C15.8849 8.63994 15.0449 9.01995 13.6649 8.99995C13.1649 8.99995 12.7849 8.89996 12.3649 8.77996C11.8849 8.65996 11.3249 8.51995 10.5249 8.49995C9.62491 8.49995 8.82492 8.71995 8.28492 8.91995L5.48492 4.77996H18.5049L15.9049 8.63994ZM14.2649 7.31995C14.1249 7.17995 14.0249 6.95996 14.0249 6.75996C14.0249 6.53996 14.1049 6.33995 14.2649 6.19995C14.4049 6.05995 14.6249 5.95996 14.8249 5.95996C15.0449 5.95996 15.2449 6.03995 15.3849 6.19995C15.5249 6.35995 15.6249 6.55996 15.6249 6.75996C15.6249 6.97996 15.5449 7.17995 15.3849 7.31995C15.2249 7.45995 15.0249 7.55996 14.8249 7.55996C14.6249 7.55996 14.4049 7.45995 14.2649 7.31995Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

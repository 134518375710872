/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66668 14.6667H1.33334C1.33334 12.8257 2.82573 12 4.66668 12H11.3333C13.1743 12 14.6667 12.8257 14.6667 14.6667H13.3333C13.3333 13.5621 12.4379 13.3333 11.3333 13.3333H4.66668C3.56211 13.3333 2.66668 13.5621 2.66668 14.6667Z"
      fill="#ED017F"
      opacity="0.3"
    />
    <rect
      fill="#ED017F"
      height="4"
      rx="1"
      width="14.6667"
      x="0.666656"
      y="9.3335"
    />
    <path
      clipRule="evenodd"
      d="M2 6C2 4.89543 2.89543 4 4 4H12C13.1046 4 14 4.89543 14 6V7.66667C14 8.21895 13.5523 8.66667 13 8.66667H12.6098C12.6466 8.56241 12.6667 8.45021 12.6667 8.33333C12.6667 7.78105 12.219 7.33333 11.6667 7.33333H9.66667C9.11438 7.33333 8.66667 7.78105 8.66667 8.33333C8.66667 8.45021 8.68672 8.56241 8.72357 8.66667H7.27643C7.31328 8.56241 7.33333 8.45021 7.33333 8.33333C7.33333 7.78105 6.88562 7.33333 6.33333 7.33333H4.33333C3.78105 7.33333 3.33333 7.78105 3.33333 8.33333C3.33333 8.45021 3.35338 8.56241 3.39024 8.66667H3C2.44772 8.66667 2 8.21895 2 7.66667V6Z"
      fill="#ED017F"
      fillRule="evenodd"
      opacity="0.3"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.9131 2.49507C15.702 2.16823 16.5474 2 17.4013 2C18.2551 2 19.1006 2.16823 19.8894 2.49507C20.6781 2.82186 21.3947 3.30081 21.9982 3.90455C22.602 4.5081 23.0813 5.22501 23.408 6.01368C23.7349 6.80249 23.9031 7.64797 23.9031 8.50183C23.9031 9.35568 23.7349 10.2012 23.408 10.99C23.0812 11.7787 22.6022 12.4953 21.9984 13.0989C21.9983 13.099 21.9985 13.0989 21.9984 13.0989L13.1584 21.9389C12.7679 22.3295 12.1347 22.3295 11.7442 21.9389L2.90418 13.0989C1.68496 11.8797 1 10.2261 1 8.50183C1 6.77758 1.68496 5.12395 2.90418 3.90472C4.12341 2.68549 5.77704 2.00053 7.50129 2.00053C9.22554 2.00053 10.8792 2.68549 12.0984 3.90472L12.4513 4.25761L12.804 3.90488C12.8041 3.90483 12.804 3.90494 12.804 3.90488C13.4076 3.30106 14.1244 2.82189 14.9131 2.49507ZM17.4013 4C16.8102 4 16.2248 4.11647 15.6787 4.34274C15.1326 4.56902 14.6365 4.90068 14.2186 5.31877L13.1584 6.37893C12.7679 6.76946 12.1347 6.76946 11.7442 6.37893L10.6842 5.31893C9.84003 4.47478 8.69511 4.00053 7.50129 4.00053C6.30747 4.00053 5.16255 4.47478 4.3184 5.31893C3.47424 6.16309 3 7.30801 3 8.50183C3 9.69564 3.47424 10.8406 4.3184 11.6847L12.4513 19.8176L20.5842 11.6847C21.0023 11.2668 21.3341 10.7705 21.5604 10.2244C21.7866 9.67828 21.9031 9.09295 21.9031 8.50183C21.9031 7.9107 21.7866 7.32537 21.5604 6.77926C21.3341 6.23316 21.0024 5.73699 20.5843 5.3191C20.1665 4.90101 19.67 4.56902 19.1239 4.34274C18.5778 4.11647 17.9924 4 17.4013 4Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

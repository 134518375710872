/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 164 36"
    width="164"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.473 7.9375e-07C116.553 0.0180902 117.619 0.251566 118.609 0.68676C119.571 1.10992 120.446 1.70863 121.19 2.45227C122.718 3.992 123.572 6.075 123.567 8.24425C123.577 9.34829 123.359 10.4426 122.927 11.4585C122.078 13.4462 120.493 15.0291 118.505 15.877C117.491 16.3124 116.398 16.5334 115.294 16.5261H104.82V27.8153H102.23V1.25436C102.228 1.08637 102.262 0.919852 102.329 0.765798C102.396 0.611745 102.494 0.473652 102.619 0.360628C102.86 0.128897 103.181 -0.000369591 103.516 7.9375e-07H115.473ZM115.288 13.9641C116.042 13.9681 116.79 13.8165 117.483 13.5188C118.854 12.9373 119.946 11.8454 120.528 10.4739C120.828 9.76906 120.979 9.01013 120.973 8.24425C120.976 7.48978 120.825 6.74266 120.528 6.04913C119.946 4.67756 118.854 3.58571 117.483 3.00418C116.789 2.70795 116.042 2.55643 115.288 2.55889H104.814V13.9641H115.288Z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M133.078 7.65161C136.295 7.65161 138.796 8.54848 140.579 10.3422C142.362 12.1359 143.254 14.6311 143.254 17.8276V22.2837C143.231 22.8333 143.131 23.377 142.956 23.8987C142.693 24.6424 142.223 25.2953 141.601 25.7802C140.692 26.4974 139.651 27.03 138.537 27.3481C137.201 27.7809 135.382 27.9973 133.078 27.9973C130.774 27.9973 128.958 27.783 127.631 27.3544C126.517 27.0363 125.476 26.5037 124.567 25.7865C123.945 25.3016 123.475 24.6487 123.212 23.9049C123.036 23.3837 122.936 22.8398 122.914 22.2899C122.914 20.7805 123.149 19.592 123.62 18.7244C124.044 17.9049 124.698 17.227 125.501 16.7739C126.373 16.3165 127.325 16.0332 128.305 15.9398C129.458 15.8129 130.617 15.7512 131.776 15.7547H140.469C140.12 13.9715 139.328 12.6126 138.092 11.6781C136.856 10.7436 135.184 10.2732 133.075 10.2669C130.944 10.2669 129.224 10.7739 127.913 11.7878L126.317 9.7213C128.077 8.34151 130.331 7.65161 133.078 7.65161ZM140.654 18.3481H133.078C131.418 18.3481 130.087 18.391 129.086 18.4767C128.291 18.5126 127.509 18.6955 126.781 19.0161C126.536 19.1321 126.318 19.2977 126.14 19.5022C125.963 19.7068 125.83 19.9459 125.749 20.2046C125.568 20.8821 125.486 21.5826 125.508 22.2837C125.506 22.5593 125.565 22.832 125.681 23.0824C125.796 23.3327 125.965 23.5546 126.176 23.7324C126.689 24.1659 127.282 24.4941 127.922 24.6983C128.712 24.9616 129.526 25.1424 130.353 25.2377C131.259 25.348 132.171 25.4035 133.084 25.4039C133.996 25.4036 134.907 25.3481 135.812 25.2377C136.64 25.1427 137.455 24.9619 138.246 24.6983C138.886 24.4941 139.479 24.1659 139.992 23.7324C140.203 23.5546 140.372 23.3327 140.488 23.0824C140.603 22.832 140.662 22.5593 140.66 22.2837L140.654 18.3481Z"
      fill="url(#paint1_radial)"
    />
    <path
      d="M163.088 8.05933L150.755 35.9907H147.932L151.943 26.928L143.589 8.05933H146.412L153.311 23.6949L160.263 8.05933H163.088Z"
      fill="url(#paint2_radial)"
    />
    <path
      d="M20.1418 0.31665H15.8895L3.09512 13.779V0.31665H0V28.0222H3.09512V18.2508L5.91429 15.2999L16.0934 27.9877H20.0728L8.04356 13.0421L20.1418 0.31665Z"
      fill="#335C88"
    />
    <path
      d="M28.5899 7.7832C25.385 7.7832 22.8544 8.69575 21.0638 10.4926C19.2732 12.2895 18.3732 14.8139 18.3732 17.9937C18.3732 21.1734 19.2763 23.7292 21.0638 25.5198C22.8512 27.3104 25.3787 28.2104 28.5899 28.2104C31.801 28.2104 34.3223 27.3072 36.116 25.5198C37.9098 23.7323 38.8066 21.2048 38.8066 17.9937C38.8066 14.7825 37.9003 12.2926 36.116 10.4926C34.3317 8.69261 31.7916 7.7832 28.5899 7.7832ZM28.5899 25.109C26.2599 25.109 24.4725 24.5038 23.2777 23.3059C22.0829 22.1079 21.4746 20.3236 21.4746 17.9937C21.4746 15.6637 22.0798 13.8794 23.2777 12.6815C24.4756 11.4836 26.2599 10.8783 28.5899 10.8783C30.9199 10.8783 32.7042 11.4836 33.9021 12.6815C35.1 13.8794 35.7052 15.6668 35.7052 17.9937C35.7052 20.3205 35.0969 22.1111 33.9021 23.3059C32.7073 24.5006 30.9167 25.109 28.5899 25.109Z"
      fill="#335C88"
    />
    <path
      d="M48.7944 7.8208C45.9439 7.8208 43.6954 8.73648 42.115 10.5428C40.5345 12.349 39.7411 14.8577 39.7411 18.0313V28.0222H42.8362V18.0313C42.8362 15.717 43.3536 13.9295 44.3665 12.7191C45.3794 11.5086 46.8752 10.9159 48.7944 10.9159C50.7136 10.9159 52.2157 11.5211 53.2191 12.7191C54.2226 13.917 54.7495 15.717 54.7495 18.0313V28.0222H57.8446V18.0313C57.8446 14.8577 57.0418 12.3396 55.455 10.5428C53.8683 8.74589 51.6198 7.8208 48.7944 7.8208Z"
      fill="#335C88"
    />
    <path
      d="M69.0115 7.7832C65.8066 7.7832 63.2791 8.69575 61.4854 10.4926C59.6917 12.2895 58.7948 14.8139 58.7948 17.9937C58.7948 21.1735 59.7011 23.7292 61.4854 25.5198C63.2697 27.3104 65.8004 28.2104 69.0115 28.2104C71.9624 28.2104 74.3425 27.4358 76.0924 25.8992C76.0924 28.1696 75.4652 29.9226 74.2892 31.1079C73.1133 32.2933 71.3164 32.9111 69.0115 32.9111H61.8209V36.0062H69.0115C72.1913 36.0062 74.7126 35.0999 76.5126 33.3125C78.3126 31.525 79.222 29.01 79.222 25.8302V17.9905C79.222 14.8139 78.3157 12.2895 76.5314 10.4895C74.7471 8.68948 72.2133 7.7832 69.0115 7.7832ZM69.0115 10.8783C71.3384 10.8783 73.1258 11.4836 74.3237 12.6815C75.5216 13.8794 76.1269 15.6668 76.1269 17.9843L76.0924 18.9501C75.9293 20.9696 75.2488 22.5156 74.0666 23.5473C72.8843 24.579 71.1784 25.1153 69.0115 25.1153C66.6816 25.1153 64.8941 24.51 63.6993 23.3121C62.5046 22.1142 61.8962 20.3299 61.8962 17.9999C61.8962 15.67 62.5014 13.8856 63.6993 12.6877C64.8972 11.4898 66.6847 10.8783 69.0115 10.8783Z"
      fill="#335C88"
    />
    <path
      d="M97.5262 10.4926C95.7387 8.69575 93.2112 7.7832 90 7.7832C87.2655 7.7832 84.9826 8.48564 83.2297 9.87484L83.0133 10.0442L84.9136 12.4745L85.1331 12.3052C86.3561 11.3644 87.9962 10.8783 90 10.8783C92.0039 10.8783 93.5718 11.3236 94.7258 12.1985C95.8346 13.0661 96.5983 14.3 96.8802 15.6794H88.7457C87.6028 15.6763 86.4606 15.7371 85.3244 15.8613C84.3278 15.9596 83.3598 16.2503 82.4739 16.7173C81.6386 17.1834 80.9574 17.8831 80.514 18.7306C80.0342 19.6149 79.7927 20.8285 79.7927 22.3431C79.818 22.9082 79.9236 23.4667 80.1063 24.002C80.3789 24.7816 80.8671 25.4678 81.5143 25.9808C82.4329 26.6982 83.4819 27.2306 84.6032 27.5487C85.9265 27.9783 87.7391 28.1947 90.0157 28.1947C92.2924 28.1947 94.0924 27.9783 95.4314 27.5487C96.5515 27.23 97.5993 26.6976 98.5171 25.9808C99.1643 25.4678 99.6526 24.7816 99.9251 24.002C100.109 23.4669 100.214 22.9083 100.239 22.3431V17.9937C100.223 14.817 99.3199 12.2926 97.5262 10.4926ZM92.6373 24.9491C90.8898 25.1623 89.1228 25.1623 87.3753 24.9491C86.5875 24.8579 85.811 24.6866 85.0579 24.4379C84.469 24.2496 83.9225 23.9481 83.4492 23.5504C83.2721 23.4034 83.1302 23.2188 83.0336 23.0099C82.937 22.801 82.8883 22.5732 82.891 22.3431C82.8716 21.6867 82.9466 21.0309 83.1136 20.3957C83.1822 20.1782 83.2954 19.9775 83.4461 19.8063C83.5967 19.6351 83.7815 19.4973 83.9885 19.4017C84.6673 19.107 85.3943 18.9391 86.1335 18.9062C87.1025 18.8215 88.4039 18.7808 90.0063 18.7808H97.1216V22.3431C97.127 22.5715 97.0817 22.7982 96.989 23.0069C96.8962 23.2157 96.7584 23.4013 96.5854 23.5504C96.1124 23.9487 95.5659 24.2502 94.9767 24.4379C94.2184 24.6873 93.4367 24.8586 92.6436 24.9491H92.6373Z"
      fill="#335C88"
    />
    <path d="M79.2219 8.89331H76.1268V16.501H79.2219V8.89331Z" fill="#335C88" />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(112.892 13.9077) scale(12.3993)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(8754.35 1164.3) scale(660.014 660.216)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(9678.2 1969.68) scale(748.761 1072.56)"
        gradientUnits="userSpaceOnUse"
        id="paint2_radial"
        r="1"
      >
        <stop stopColor="#F79020" />
        <stop offset="1" stopColor="#F26822" />
      </radialGradient>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" fill="#ACACAC" fillOpacity="0.2" r="16" />
    <path
      d="M19.3333 19.333V20.6663C19.3333 20.8432 19.4035 21.0127 19.5286 21.1377C19.6536 21.2628 19.8231 21.333 20 21.333C20.1768 21.333 20.3463 21.2628 20.4714 21.1377C20.5964 21.0127 20.6666 20.8432 20.6666 20.6663V10.6663H11.3333V17.9997H9.99996V9.99967C9.99996 9.82286 10.0702 9.65329 10.1952 9.52827C10.3202 9.40325 10.4898 9.33301 10.6666 9.33301H21.3333C21.5101 9.33301 21.6797 9.40325 21.8047 9.52827C21.9297 9.65329 22 9.82286 22 9.99967V20.6663C22 21.1968 21.7892 21.7055 21.4142 22.0806C21.0391 22.4556 20.5304 22.6663 20 22.6663H10.6666C10.1362 22.6663 9.62749 22.4556 9.25241 22.0806C8.87734 21.7055 8.66663 21.1968 8.66663 20.6663V19.333H19.3333Z"
      fill="#ACACAC"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_20257_73918)">
      <path
        d="M23.7693 2.97252C24.1345 1.87668 24.0661 1.03203 23.5635 0.438198C22.9699 -0.064167 22.1251 -0.13242 21.029 0.232238C19.933 0.597556 18.9512 1.21394 18.0835 2.0815L15.3438 4.82142L3.95683 2.09883C3.77414 2.04168 3.60291 2.08732 3.44319 2.2357L1.25123 4.42755C1.12582 4.55326 1.0743 4.70734 1.09721 4.88997C1.13145 5.07284 1.22856 5.20965 1.38833 5.30105L10.0872 10.0786L5.65207 14.5137L2.33009 13.6064C2.30729 13.5949 2.26177 13.5891 2.19322 13.5891C2.03332 13.5891 1.90215 13.6404 1.79935 13.7433L0.155574 15.4041C0.0414383 15.5408 -0.00984175 15.6836 0.0015538 15.8319C0.024225 16.0145 0.098416 16.1459 0.224127 16.2259L4.5392 19.4621L7.77548 23.7771C7.87828 23.9029 8.01533 23.9767 8.18656 23.9999H8.22081C8.38071 23.9999 8.51187 23.9487 8.61467 23.846L10.2584 22.2024C10.4068 22.0424 10.4525 21.8654 10.3955 21.6714L9.48787 18.3494L13.923 13.914L18.7007 22.6133C18.7691 22.7618 18.8889 22.8531 19.0603 22.8874C19.0943 22.8988 19.1343 22.9044 19.1802 22.9044C19.3168 22.9044 19.4255 22.8701 19.5053 22.8017L21.6976 21.1579C21.8913 20.9982 21.9598 20.81 21.903 20.5931L19.1633 8.67504L21.9202 5.91821C22.7876 5.05029 23.404 4.06835 23.7693 2.97252Z"
        fill="#4E4E4E"
      />
    </g>
    <defs>
      <clipPath id="clip0_20257_73918">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C5.38317 0 0 5.38317 0 12C0 18.6168 5.38317 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38317 18.6168 0 12 0ZM5.95319 6.06676C5.86228 5.88867 5.93137 5.67045 6.11319 5.57955C6.21866 5.52504 8.76412 4.2413 10.0623 5.6559C10.2005 5.80504 10.1896 6.03409 10.0405 6.17223C9.97137 6.23402 9.8841 6.26687 9.79683 6.26687C9.69502 6.26687 9.60048 6.22674 9.52774 6.14684C8.60412 5.13956 6.46593 6.21591 6.44411 6.22674C6.26591 6.31765 6.0441 6.24503 5.95319 6.06676ZM9.63683 9.0522C9.63683 9.9359 8.92048 10.6523 8.03684 10.6523C7.15319 10.6523 6.43683 9.9359 6.43683 9.0522C6.43683 8.16868 7.15319 7.45224 8.03684 7.45224C8.92048 7.45224 9.63683 8.16868 9.63683 9.0522ZM11.7605 19.4922C8.76773 19.4922 6.33501 17.7686 6.33501 15.6523C6.33501 15.4522 6.49865 15.2887 6.69864 15.2887H16.8186C17.0223 15.2887 17.1823 15.4522 17.1823 15.6523C17.1823 17.7686 14.7496 19.4922 11.7605 19.4922ZM15.9641 10.6523C15.0805 10.6523 14.3641 9.9359 14.3641 9.0522C14.3641 8.16868 15.0805 7.45224 15.9641 7.45224C16.8477 7.45224 17.5641 8.16868 17.5641 9.0522C17.5641 9.9359 16.8477 10.6523 15.9641 10.6523ZM18.0477 6.06676C17.9532 6.24503 17.735 6.31765 17.5568 6.22674C17.535 6.21591 15.3968 5.13956 14.4732 6.14684C14.4005 6.22674 14.3023 6.26687 14.2041 6.26687C14.1168 6.26687 14.0296 6.23402 13.9605 6.17223C13.8114 6.03409 13.8005 5.80504 13.935 5.6559C15.2332 4.2413 17.7823 5.52504 17.8877 5.57955C18.0659 5.67045 18.1387 5.88867 18.0477 6.06676Z"
      fill="#DFDFDF"
    />
  </svg>
);

export default SVG;

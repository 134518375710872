/* eslint-disable max-len */
import React from "react";
/**
 * Close Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.0002C10.0213 22.0014 8.08681 21.4155 6.44124 20.3168C4.79567 19.2181 3.51304 17.6559 2.75568 15.8279C1.99832 13.9999 1.80028 11.9883 2.18661 10.0477C2.57295 8.10714 3.5263 6.32479 4.92601 4.92624C6.80215 3.0501 9.34675 1.99609 12 1.99609C14.6533 1.99609 17.1979 3.0501 19.074 4.92624C20.9502 6.80238 22.0042 9.34698 22.0042 12.0002C22.0042 14.6535 20.9502 17.1981 19.074 19.0742C18.1469 20.0055 17.0444 20.7436 15.8301 21.2459C14.6159 21.7481 13.314 22.0045 12 22.0002V22.0002ZM4.00001 12.1722C4.02274 14.2864 4.88146 16.3056 6.38849 17.7885C7.89552 19.2714 9.9283 20.0975 12.0426 20.0861C14.1568 20.0748 16.1806 19.2269 17.6716 17.7279C19.1626 16.2288 19.9995 14.2005 19.9995 12.0862C19.9995 9.97196 19.1626 7.94365 17.6716 6.44461C16.1806 4.94557 14.1568 4.09772 12.0426 4.08636C9.9283 4.07499 7.89552 4.90104 6.38849 6.38396C4.88146 7.86688 4.02274 9.88608 4.00001 12.0002V12.1722ZM9.40901 16.0002L7.99901 14.5902L10.59 12.0002L8.00001 9.41024L9.41001 8.00024L12 10.5902L14.59 8.00024L16 9.41024L13.41 12.0002L16 14.5902L14.592 16.0002L12 13.4102L9.41001 16.0002H9.40901Z"
      fill="#EC1111"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.4276 1.07922C13.085 0.806847 13.7895 0.666656 14.5011 0.666656C15.2126 0.666656 15.9172 0.806847 16.5745 1.07922C17.2318 1.35154 17.8289 1.75066 18.3319 2.25378C18.835 2.75674 19.2344 3.35416 19.5067 4.01139C19.7791 4.66873 19.9193 5.3733 19.9193 6.08484C19.9193 6.79639 19.7791 7.50096 19.5067 8.1583C19.2344 8.81559 18.8352 9.41278 18.332 9.91577C18.332 9.91581 18.3321 9.91572 18.332 9.91577L10.9653 17.2824C10.6399 17.6079 10.1123 17.6079 9.78683 17.2824L2.42016 9.91577C1.40414 8.89974 0.833344 7.52172 0.833344 6.08484C0.833344 4.64797 1.40414 3.26995 2.42016 2.25392C3.43619 1.2379 4.81421 0.667102 6.25109 0.667102C7.68796 0.667102 9.06598 1.2379 10.082 2.25392L10.3761 2.548L10.67 2.25406C10.6701 2.25401 10.67 2.25411 10.67 2.25406C11.173 1.75087 11.7703 1.35157 12.4276 1.07922ZM14.5011 2.33332C14.0085 2.33332 13.5207 2.43038 13.0656 2.61894C12.6105 2.80751 12.1971 3.08389 11.8488 3.4323L10.9653 4.31577C10.6399 4.6412 10.1123 4.6412 9.78683 4.31577L8.9035 3.43243C8.20003 2.72897 7.24593 2.33377 6.25109 2.33377C5.25624 2.33377 4.30214 2.72897 3.59867 3.43243C2.89521 4.1359 2.50001 5.09 2.50001 6.08484C2.50001 7.07969 2.89521 8.03379 3.59867 8.73726L10.3761 15.5147L17.1535 8.73726C17.5019 8.38901 17.7784 7.9754 17.967 7.52031C18.1556 7.06523 18.2526 6.57745 18.2526 6.08484C18.2526 5.59224 18.1556 5.10446 17.967 4.64937C17.7784 4.19429 17.502 3.78081 17.1536 3.43257C16.8054 3.08417 16.3916 2.80751 15.9366 2.61894C15.4815 2.43038 14.9937 2.33332 14.5011 2.33332Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3594 1.09375H11.7031V0H10.6094V1.09375H3.39062V0H2.29688V1.09375H1.64062C0.735984 1.09375 0 1.82973 0 2.73438V12.3594C0 13.264 0.735984 14 1.64062 14H12.3594C13.264 14 14 13.264 14 12.3594V2.73438C14 1.82973 13.264 1.09375 12.3594 1.09375ZM12.9062 12.3594C12.9062 12.6609 12.6609 12.9062 12.3594 12.9062H1.64062C1.33908 12.9062 1.09375 12.6609 1.09375 12.3594V5.14062H12.9062V12.3594ZM12.9062 4.04688H1.09375V2.73438C1.09375 2.43283 1.33908 2.1875 1.64062 2.1875H2.29688V3.28125H3.39062V2.1875H10.6094V3.28125H11.7031V2.1875H12.3594C12.6609 2.1875 12.9062 2.43283 12.9062 2.73438V4.04688Z"
      fill="#666565"
    />
    <path
      d="M3.17188 6.28906H2.07812V7.38281H3.17188V6.28906Z"
      fill="#666565"
    />
    <path
      d="M5.35938 6.28906H4.26562V7.38281H5.35938V6.28906Z"
      fill="#666565"
    />
    <path
      d="M7.54688 6.28906H6.45312V7.38281H7.54688V6.28906Z"
      fill="#666565"
    />
    <path
      d="M9.73438 6.28906H8.64062V7.38281H9.73438V6.28906Z"
      fill="#666565"
    />
    <path
      d="M11.9219 6.28906H10.8281V7.38281H11.9219V6.28906Z"
      fill="#666565"
    />
    <path
      d="M3.17188 8.47656H2.07812V9.57031H3.17188V8.47656Z"
      fill="#666565"
    />
    <path
      d="M5.35938 8.47656H4.26562V9.57031H5.35938V8.47656Z"
      fill="#666565"
    />
    <path
      d="M7.54688 8.47656H6.45312V9.57031H7.54688V8.47656Z"
      fill="#666565"
    />
    <path
      d="M9.73438 8.47656H8.64062V9.57031H9.73438V8.47656Z"
      fill="#666565"
    />
    <path
      d="M3.17188 10.6641H2.07812V11.7578H3.17188V10.6641Z"
      fill="#666565"
    />
    <path
      d="M5.35938 10.6641H4.26562V11.7578H5.35938V10.6641Z"
      fill="#666565"
    />
    <path
      d="M7.54688 10.6641H6.45312V11.7578H7.54688V10.6641Z"
      fill="#666565"
    />
    <path
      d="M9.73438 10.6641H8.64062V11.7578H9.73438V10.6641Z"
      fill="#666565"
    />
    <path
      d="M11.9219 8.47656H10.8281V9.57031H11.9219V8.47656Z"
      fill="#666565"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 20 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6254 21C16.5485 21.0001 16.4722 20.9808 16.4011 20.9431C16.33 20.9053 16.2654 20.85 16.2111 20.7801L3.31683 4.28013C3.21156 4.13833 3.15373 3.94952 3.15569 3.75395C3.15764 3.55838 3.21923 3.37152 3.32731 3.23322C3.43538 3.09492 3.58141 3.01612 3.73424 3.01361C3.88707 3.01111 4.03463 3.0851 4.14544 3.21982L17.0397 19.7198C17.1216 19.8247 17.1774 19.9583 17.2 20.1038C17.2226 20.2492 17.211 20.3999 17.1667 20.5369C17.1223 20.6739 17.0472 20.791 16.9509 20.8735C16.8546 20.9559 16.7413 20.9999 16.6254 21Z"
      fill="#ED017F"
    />
    <path
      d="M9.88516 14.8055L7.98801 12.3778C7.97714 12.364 7.96316 12.3549 7.94805 12.3518C7.93293 12.3487 7.91744 12.3517 7.90374 12.3605C7.89003 12.3692 7.87882 12.3832 7.87166 12.4005C7.8645 12.4179 7.86176 12.4376 7.86383 12.457C7.94042 13.0868 8.17153 13.6692 8.52375 14.1199C8.87597 14.5706 9.33106 14.8664 9.82325 14.9644C9.83842 14.967 9.85386 14.9635 9.86739 14.9543C9.88092 14.9452 9.89188 14.9308 9.89871 14.9133C9.90554 14.8958 9.9079 14.8759 9.90547 14.8566C9.90304 14.8373 9.89594 14.8194 9.88516 14.8055Z"
      fill="#ED017F"
    />
    <path
      d="M10.4713 9.19455L12.3713 11.625C12.3822 11.639 12.3962 11.6483 12.4114 11.6515C12.4266 11.6547 12.4422 11.6517 12.456 11.6429C12.4698 11.6342 12.481 11.6201 12.4882 11.6026C12.4954 11.5852 12.4981 11.5653 12.4959 11.5458C12.4195 10.9151 12.1882 10.3319 11.8356 9.88063C11.4829 9.42935 11.0271 9.1334 10.5343 9.03564C10.519 9.03263 10.5034 9.03586 10.4896 9.04489C10.4759 9.05391 10.4647 9.06825 10.4578 9.08588C10.4508 9.1035 10.4483 9.1235 10.4507 9.14301C10.4532 9.16253 10.4603 9.18057 10.4713 9.19455Z"
      fill="#ED017F"
    />
    <path
      d="M18.7867 12.8137C18.908 12.5702 18.9721 12.2872 18.9715 11.9984C18.9708 11.7096 18.9054 11.427 18.7831 11.1844C17.8138 9.26624 16.5562 7.63687 15.1466 6.47203C13.585 5.18203 11.8634 4.5 10.1666 4.5C9.27208 4.50157 8.38366 4.6882 7.53571 5.05266C7.51197 5.06276 7.49066 5.08046 7.47379 5.10409C7.45691 5.12771 7.44505 5.15647 7.43932 5.18763C7.43359 5.21878 7.43419 5.25129 7.44106 5.28207C7.44793 5.31284 7.46084 5.34085 7.47856 5.36344L9.20903 7.57781C9.22701 7.60086 9.24937 7.61751 9.27391 7.62614C9.29844 7.63477 9.32431 7.63508 9.34897 7.62703C9.93553 7.44411 10.549 7.45752 11.1304 7.66595C11.7117 7.87438 12.2413 8.27082 12.6682 8.81709C13.0951 9.36337 13.4049 10.0411 13.5678 10.785C13.7307 11.5289 13.7412 12.3139 13.5982 13.0645C13.592 13.096 13.5923 13.129 13.599 13.1603C13.6058 13.1916 13.6187 13.2202 13.6367 13.2431L16.1258 16.4306C16.1517 16.4638 16.1864 16.4834 16.223 16.4855C16.2596 16.4875 16.2955 16.472 16.3236 16.4419C17.2817 15.3968 18.112 14.1739 18.7867 12.8137Z"
      fill="#ED017F"
    />
    <path
      d="M10.1783 16.5C9.64593 16.5 9.12052 16.3454 8.64167 16.0478C8.16282 15.7502 7.74302 15.3173 7.41392 14.7819C7.08483 14.2464 6.85503 13.6224 6.74185 12.9567C6.62867 12.2911 6.63507 11.6012 6.76056 10.9392C6.76678 10.9077 6.76651 10.8747 6.75977 10.8434C6.75303 10.8121 6.74005 10.7836 6.7221 10.7606L4.27365 7.6261C4.24768 7.59284 4.21291 7.57327 4.1762 7.57128C4.1395 7.56928 4.10355 7.585 4.07547 7.61532C3.18203 8.59079 2.35379 9.77766 1.59662 11.1647C1.46408 11.4081 1.39108 11.6965 1.38691 11.9931C1.38273 12.2897 1.44757 12.5813 1.57317 12.8306C2.54061 14.768 3.78535 16.3997 5.17332 17.5486C6.73712 18.8438 8.41704 19.5 10.1666 19.5C11.0701 19.4969 11.9678 19.3143 12.8275 18.9586C12.8514 18.9488 12.873 18.9313 12.8901 18.9078C12.9073 18.8842 12.9194 18.8554 12.9253 18.8242C12.9312 18.7929 12.9307 18.7602 12.9239 18.7293C12.9171 18.6983 12.9042 18.6701 12.8865 18.6474L11.1476 16.4227C11.1296 16.3997 11.1073 16.3831 11.0829 16.3745C11.0584 16.3658 11.0326 16.3655 11.008 16.3734C10.7363 16.4577 10.4578 16.5002 10.1783 16.5Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

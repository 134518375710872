/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M40 20.0005C40 8.95371 31.0457 0 20.0003 0C8.95441 0 0 8.95371 0 20.0005C0 31.0456 8.95441 40 20.0003 40C31.0457 40 40 31.0456 40 20.0005ZM10.5263 13.7535C10.5263 12.6266 10.8901 11.4848 11.6179 10.3282C12.3454 9.17166 13.4074 8.21382 14.8031 7.45454C16.199 6.69551 17.8274 6.31579 19.6884 6.31579C21.4181 6.31579 22.9453 6.63332 24.2695 7.26772C25.5937 7.90245 26.6165 8.76546 27.3385 9.85668C28.0601 10.9482 28.4211 12.1343 28.4211 13.4154C28.4211 14.4238 28.2153 15.3077 27.8036 16.0666C27.392 16.8259 26.9027 17.4813 26.3362 18.0327C25.7695 18.5844 24.7525 19.5127 23.2852 20.8175C22.8795 21.1853 22.5543 21.5087 22.3099 21.7873C22.0653 22.0662 21.8833 22.3212 21.7641 22.5524C21.6447 22.7837 21.5521 23.015 21.4867 23.2464C21.421 23.4776 21.3226 23.8841 21.1915 24.4652C20.9648 25.699 20.2548 26.3158 19.062 26.3158C18.4416 26.3158 17.9196 26.1142 17.4962 25.7108C17.0727 25.3076 16.861 24.7085 16.861 23.9137C16.861 22.9172 17.0159 22.0542 17.3263 21.3247C17.6364 20.5951 18.0479 19.9545 18.561 19.403C19.0738 18.8514 19.7659 18.1961 20.6368 17.4368C21.4001 16.7726 21.9521 16.2713 22.2921 15.9332C22.6321 15.5951 22.9184 15.2187 23.1511 14.8033C23.3836 14.3882 23.5 13.9375 23.5 13.451C23.5 12.5021 23.1449 11.7014 22.4353 11.0489C21.7253 10.3967 20.8096 10.0702 19.6884 10.0702C18.3759 10.0702 17.4096 10.3994 16.7894 11.0578C16.169 11.7162 15.6442 12.6859 15.2147 13.967C14.809 15.3077 14.0395 15.9777 12.9062 15.9777C12.2379 15.9777 11.6742 15.7436 11.2151 15.2749C10.7559 14.8063 10.5263 14.2992 10.5263 13.7535ZM17.6377 32.9888C18.1681 33.4523 18.7888 33.6842 19.5 33.6842C20.1877 33.6842 20.7938 33.4494 21.3184 32.9801C21.843 32.5108 22.1053 31.8654 22.1053 31.0438C22.1053 30.3045 21.8544 29.6826 21.3534 29.1779C20.852 28.6735 20.2343 28.4211 19.4999 28.4211C18.7538 28.4211 18.1243 28.6735 17.6115 29.1779C17.0984 29.6826 16.8421 30.3045 16.8421 31.0438C16.8421 31.8771 17.1072 32.5255 17.6377 32.9888Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

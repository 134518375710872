/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 31 24"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83452 7.58018C8.51452 7.28018 8.51453 6.76018 8.81453 6.44018C8.97453 6.28018 9.17453 6.20017 9.39453 6.20017C9.59453 6.20017 9.79454 6.28017 9.95454 6.42017L15.1545 11.4202C15.3145 11.5802 15.3945 11.7802 15.3945 12.0002C15.3945 12.2202 15.3145 12.4202 15.1545 12.5802L9.95454 17.5802C9.63454 17.8802 9.13453 17.8802 8.81453 17.5602C8.51453 17.2402 8.51452 16.7402 8.83452 16.4202L13.4345 12.0002L8.83452 7.58018Z"
      fill="#ED017F"
    />
    <path
      d="M15.8345 7.58018C15.5145 7.28018 15.5145 6.76018 15.8145 6.44018C15.9745 6.28018 16.1745 6.20017 16.3945 6.20017C16.5945 6.20017 16.7945 6.28017 16.9545 6.42017L22.1545 11.4202C22.3145 11.5802 22.3945 11.7802 22.3945 12.0002C22.3945 12.2202 22.3145 12.4202 22.1545 12.5802L16.9545 17.5802C16.6345 17.8802 16.1345 17.8802 15.8145 17.5602C15.5145 17.2402 15.5145 16.7402 15.8345 16.4202L20.4345 12.0002L15.8345 7.58018Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

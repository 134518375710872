/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.565 10.7599C17.405 10.9199 17.205 10.9999 17.005 10.9999C16.805 10.9999 16.605 10.9199 16.445 10.7599L12.805 7.11988V18.7999C12.805 19.2399 12.445 19.5999 12.005 19.5999C11.565 19.5999 11.205 19.2399 11.205 18.7999V7.11988L7.56499 10.7399C7.24499 11.0599 6.74499 11.0599 6.42499 10.7399C6.10499 10.4199 6.10499 9.91988 6.42499 9.59988L11.425 4.61988C11.745 4.29988 12.245 4.29988 12.545 4.61988L17.545 9.59988C17.885 9.93988 17.885 10.4399 17.565 10.7599Z"
      fill="#ED017F"
    />
    <path
      d="M29.5651 14.3799L24.5651 19.3599C24.4051 19.5199 24.2051 19.5999 24.0051 19.5999C23.8051 19.5999 23.6051 19.5199 23.4451 19.3599L18.4451 14.3799C18.1251 14.0599 18.1251 13.5599 18.4451 13.2399C18.7651 12.9199 19.2651 12.9199 19.5851 13.2399L23.2251 16.8599V5.1999C23.2251 4.7599 23.5851 4.3999 24.0251 4.3999C24.4651 4.3999 24.8251 4.7599 24.8251 5.1999V16.8799L28.4651 13.2599C28.7851 12.9399 29.2851 12.9399 29.6051 13.2599C29.8851 13.5599 29.8851 14.0599 29.5651 14.3799Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.2501 21C20.1516 21.0001 20.054 20.9808 19.963 20.9431C19.872 20.9053 19.7894 20.85 19.7199 20.7801L3.21994 4.28013C3.08522 4.13833 3.01123 3.94952 3.01373 3.75395C3.01624 3.55838 3.09504 3.37152 3.23334 3.23322C3.37164 3.09492 3.5585 3.01612 3.75407 3.01361C3.94964 3.01111 4.13845 3.0851 4.28025 3.21982L20.7803 19.7198C20.8851 19.8247 20.9564 19.9583 20.9854 20.1038C21.0143 20.2492 20.9994 20.3999 20.9427 20.5369C20.8859 20.6739 20.7899 20.791 20.6666 20.8735C20.5433 20.9559 20.3984 20.9999 20.2501 21Z"
      fill="#ED017F"
    />
    <path
      d="M11.625 14.8055L9.19736 12.3778C9.18345 12.364 9.16557 12.3549 9.14623 12.3518C9.12688 12.3487 9.10705 12.3517 9.08952 12.3605C9.07199 12.3692 9.05763 12.3832 9.04847 12.4005C9.03931 12.4179 9.03581 12.4376 9.03846 12.457C9.13646 13.0868 9.43219 13.6692 9.88291 14.1199C10.3336 14.5706 10.916 14.8664 11.5458 14.9644C11.5652 14.967 11.585 14.9635 11.6023 14.9543C11.6196 14.9452 11.6336 14.9308 11.6424 14.9133C11.6511 14.8958 11.6541 14.8759 11.651 14.8566C11.6479 14.8373 11.6388 14.8194 11.625 14.8055Z"
      fill="#ED017F"
    />
    <path
      d="M12.375 9.19455L14.8064 11.625C14.8202 11.639 14.8382 11.6483 14.8576 11.6515C14.8771 11.6547 14.897 11.6517 14.9147 11.6429C14.9323 11.6342 14.9467 11.6201 14.9559 11.6026C14.9651 11.5852 14.9685 11.5653 14.9657 11.5458C14.868 10.9151 14.572 10.3319 14.1208 9.88063C13.6695 9.42935 13.0863 9.1334 12.4556 9.03564C12.436 9.03263 12.4161 9.03586 12.3985 9.04489C12.3809 9.05391 12.3666 9.06825 12.3577 9.08588C12.3487 9.1035 12.3456 9.1235 12.3487 9.14301C12.3518 9.16253 12.361 9.18057 12.375 9.19455Z"
      fill="#ED017F"
    />
    <path
      d="M23.0157 12.8137C23.1709 12.5702 23.253 12.2872 23.2521 11.9984C23.2513 11.7096 23.1676 11.427 23.0111 11.1844C21.7707 9.26624 20.1615 7.63687 18.3578 6.47203C16.3595 5.18203 14.1564 4.5 11.9851 4.5C10.8405 4.50157 9.70363 4.6882 8.61856 5.05266C8.58819 5.06276 8.56091 5.08046 8.53932 5.10409C8.51773 5.12771 8.50255 5.15647 8.49522 5.18763C8.48789 5.21878 8.48865 5.25129 8.49744 5.28207C8.50623 5.31284 8.52276 5.34085 8.54543 5.36344L10.7598 7.57781C10.7828 7.60086 10.8114 7.61751 10.8428 7.62614C10.8742 7.63477 10.9073 7.63508 10.9389 7.62703C11.6895 7.44411 12.4745 7.45752 13.2184 7.66595C13.9623 7.87438 14.64 8.27082 15.1863 8.81709C15.7326 9.36337 16.129 10.0411 16.3375 10.785C16.5459 11.5289 16.5593 12.3139 16.3764 13.0645C16.3684 13.096 16.3688 13.129 16.3774 13.1603C16.386 13.1916 16.4026 13.2202 16.4256 13.2431L19.6107 16.4306C19.6439 16.4638 19.6883 16.4834 19.7351 16.4855C19.782 16.4875 19.8279 16.472 19.8639 16.4419C21.0899 15.3968 22.1524 14.1739 23.0157 12.8137Z"
      fill="#ED017F"
    />
    <path
      d="M12 16.5C11.3188 16.5 10.6465 16.3454 10.0337 16.0478C9.42094 15.7502 8.88375 15.3173 8.46263 14.7819C8.04151 14.2464 7.74745 13.6224 7.60262 12.9567C7.45779 12.2911 7.46598 11.6012 7.62656 10.9392C7.63452 10.9077 7.63417 10.8747 7.62555 10.8434C7.61692 10.8121 7.60031 10.7836 7.57734 10.7606L4.44422 7.6261C4.41099 7.59284 4.36649 7.57327 4.31952 7.57128C4.27255 7.56928 4.22655 7.585 4.19062 7.61532C3.04734 8.59079 1.9875 9.77766 1.01859 11.1647C0.84899 11.4081 0.755584 11.6965 0.750243 11.9931C0.744901 12.2897 0.827865 12.5813 0.988591 12.8306C2.22656 14.768 3.81937 16.3997 5.59547 17.5486C7.59656 18.8438 9.74625 19.5 11.985 19.5C13.1412 19.4969 14.2899 19.3143 15.39 18.9586C15.4206 18.9488 15.4482 18.9313 15.4702 18.9078C15.4921 18.8842 15.5076 18.8554 15.5152 18.8242C15.5227 18.7929 15.5222 18.7602 15.5134 18.7293C15.5047 18.6983 15.4882 18.6701 15.4655 18.6474L13.2403 16.4227C13.2174 16.3997 13.1888 16.3831 13.1575 16.3745C13.1262 16.3658 13.0932 16.3655 13.0617 16.3734C12.7141 16.4577 12.3577 16.5002 12 16.5Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="33"
    viewBox="0 0 30 33"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.7591 15.1426C15.8889 15.1426 11.9277 19.1038 11.9277 23.9739C11.9277 28.8441 15.8889 32.8053 20.7591 32.8053C25.6293 32.8053 29.5905 28.8441 29.5905 23.9739C29.5905 19.1038 25.6293 15.1426 20.7591 15.1426ZM25.681 21.6238L19.5545 27.2404C19.4436 27.3439 19.2958 27.3956 19.1554 27.3956C18.9928 27.3956 18.8376 27.3291 18.7194 27.2035L15.4825 23.6931C15.2607 23.4566 15.2755 23.0797 15.5194 22.858C15.7559 22.6363 16.1328 22.6511 16.3545 22.895L19.1924 25.9693L24.8829 20.7518C25.1268 20.5301 25.4963 20.5449 25.718 20.7887C25.9397 21.0326 25.9175 21.4095 25.681 21.6238Z"
      fill="#999999"
    />
    <path
      d="M11.0632 21.4318H4.64109C4.31592 21.4318 4.04987 21.1657 4.04987 20.8405C4.04987 20.5154 4.31592 20.2493 4.64109 20.2493H11.4549C11.8318 19.2886 12.3344 18.4757 12.9551 17.6627H4.64109C4.31592 17.6627 4.04987 17.3967 4.04987 17.0715C4.04987 16.7463 4.31592 16.4803 4.64109 16.4803H14.0785C15.8521 14.9283 18.2022 13.938 20.7666 13.938C21.0105 13.938 21.2101 13.9602 21.5057 13.9824V7.90758H17.0715C15.357 7.90758 13.9676 6.50343 13.9676 4.79628V0H2.63093C1.16766 0 0 1.19722 0 2.66789V25.223C0 26.6936 1.16766 27.8613 2.63093 27.8613H11.5362C11.0263 26.6789 10.7454 25.3486 10.7454 23.9592C10.7381 23.0872 10.8563 22.2447 11.0632 21.4318ZM4.64109 12.7852H12.1274C12.4526 12.7852 12.7186 13.0512 12.7186 13.3764C12.7186 13.7016 12.4526 13.9676 12.1274 13.9676H4.64109C4.31592 13.9676 4.04987 13.7016 4.04987 13.3764C4.04987 13.0512 4.31592 12.7852 4.64109 12.7852Z"
      fill="#999999"
    />
    <path
      d="M17.0719 6.72545H20.6931L15.1504 0.894531V4.79659C15.1504 5.8534 16.0077 6.72545 17.0719 6.72545Z"
      fill="#999999"
    />
  </svg>
);

export default SVG;

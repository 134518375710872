/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4053 12.5999C20.2053 12.5999 20.0053 12.5199 19.8453 12.3799L12.0053 4.71992L4.16529 12.3799C3.84529 12.6799 3.34529 12.6799 3.02529 12.3599C2.72529 12.0399 2.7253 11.5399 3.0453 11.2199L11.4453 3.01992C11.7653 2.71992 12.2453 2.71992 12.5653 3.01992L20.9653 11.2199C21.2853 11.5199 21.2853 12.0399 20.9853 12.3599C20.8253 12.5199 20.6053 12.5999 20.4053 12.5999ZM18.6453 20.3999V12.5799C18.6453 12.1399 18.2853 11.7799 17.8453 11.7799C17.4053 11.7799 17.0453 12.1399 17.0453 12.5799V19.5999H14.5453V15.3199C14.5453 14.7399 14.0653 14.2599 13.4853 14.2599H10.5053C9.9253 14.2599 9.4453 14.7399 9.4453 15.3199V19.5999H6.9453V12.5799C6.9453 12.1399 6.5853 11.7799 6.1453 11.7799C5.7053 11.7799 5.3453 12.1399 5.3453 12.5799V20.3999C5.3453 20.8399 5.7053 21.1999 6.1453 21.1999H10.2453C10.6853 21.1999 11.0453 20.8399 11.0453 20.3999V15.8599H12.9453V20.3999C12.9453 20.8399 13.3053 21.1999 13.7453 21.1999H17.8453C18.3053 21.1999 18.6453 20.8399 18.6453 20.3999Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.33325 1.99998C1.33325 1.63179 1.63173 1.33331 1.99992 1.33331H6.66659C7.03478 1.33331 7.33325 1.63179 7.33325 1.99998V6.66665C7.33325 7.03484 7.03478 7.33331 6.66659 7.33331H1.99992C1.63173 7.33331 1.33325 7.03484 1.33325 6.66665V1.99998ZM2.66659 2.66665V5.99998H5.99992V2.66665H2.66659Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.66675 1.99998C8.66675 1.63179 8.96522 1.33331 9.33341 1.33331H14.0001C14.3683 1.33331 14.6667 1.63179 14.6667 1.99998V6.66665C14.6667 7.03484 14.3683 7.33331 14.0001 7.33331H9.33341C8.96522 7.33331 8.66675 7.03484 8.66675 6.66665V1.99998ZM10.0001 2.66665V5.99998H13.3334V2.66665H10.0001Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.66675 9.33335C8.66675 8.96516 8.96522 8.66669 9.33341 8.66669H14.0001C14.3683 8.66669 14.6667 8.96516 14.6667 9.33335V14C14.6667 14.3682 14.3683 14.6667 14.0001 14.6667H9.33341C8.96522 14.6667 8.66675 14.3682 8.66675 14V9.33335ZM10.0001 10V13.3334H13.3334V10H10.0001Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.33325 9.33335C1.33325 8.96516 1.63173 8.66669 1.99992 8.66669H6.66659C7.03478 8.66669 7.33325 8.96516 7.33325 9.33335V14C7.33325 14.3682 7.03478 14.6667 6.66659 14.6667H1.99992C1.63173 14.6667 1.33325 14.3682 1.33325 14V9.33335ZM2.66659 10V13.3334H5.99992V10H2.66659Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="8"
    viewBox="0 0 10 8"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.10699 7.5856C9.33177 7.5856 9.52654 7.51395 9.69212 7.37248L6.85897 4.63745C6.791 4.68444 6.72511 4.73013 6.66288 4.77358C6.45086 4.92437 6.27878 5.04204 6.14664 5.12636C6.01452 5.21087 5.83874 5.29701 5.61932 5.38496C5.39974 5.47301 5.19519 5.51689 5.00542 5.51689H4.99987H4.99431C4.80453 5.51689 4.59998 5.47303 4.38042 5.38496C4.16087 5.29701 3.98508 5.21087 3.85309 5.12636C3.72098 5.04204 3.54899 4.92439 3.33686 4.77358C3.27775 4.73175 3.21219 4.68584 3.14131 4.63672L0.307617 7.37248C0.473177 7.51395 0.668076 7.5856 0.892841 7.5856H9.10699Z"
      fill="white"
    />
    <path
      d="M0.563673 2.9141C0.351671 2.77764 0.163672 2.62135 0 2.44531V6.60708L2.49712 4.19616C1.99755 3.85943 1.35389 3.43257 0.563673 2.9141Z"
      fill="white"
    />
    <path
      d="M9.44197 2.91407C8.68189 3.41073 8.03589 3.8383 7.50388 4.19701L10 6.60692V2.44531C9.83996 2.6178 9.65397 2.77397 9.44197 2.91407Z"
      fill="white"
    />
    <path
      d="M9.10737 0H0.89324C0.606673 0 0.386356 0.0934225 0.232016 0.280075C0.077549 0.466815 0.000442505 0.700354 0.000442505 0.980429C0.000442505 1.20666 0.102767 1.45178 0.307325 1.71587C0.511883 1.97986 0.72955 2.18721 0.960215 2.33804C1.08667 2.42429 1.46801 2.68023 2.10423 3.10577C2.44768 3.33554 2.74635 3.53581 3.00302 3.70863C3.22179 3.8558 3.41046 3.98324 3.56624 4.089C3.58412 4.10111 3.61225 4.12053 3.64957 4.1463C3.68979 4.17419 3.74068 4.20957 3.80346 4.25334C3.92436 4.33775 4.02479 4.40599 4.10479 4.45812C4.18467 4.51026 4.28146 4.56851 4.395 4.63318C4.50846 4.69777 4.61546 4.74634 4.7159 4.77865C4.81635 4.81093 4.90935 4.82713 4.9949 4.82713H5.00045H5.00601C5.09154 4.82713 5.18455 4.81093 5.28502 4.77865C5.38544 4.74634 5.49236 4.69788 5.6059 4.63318C5.71934 4.56851 5.816 4.51014 5.89614 4.45812C5.97613 4.40599 6.07656 4.33777 6.19748 4.25334C6.26014 4.20957 6.31103 4.17417 6.35124 4.14639C6.38859 4.12052 6.41669 4.10122 6.43468 4.089C6.55603 4.00748 6.74514 3.88056 6.99939 3.71012C7.46203 3.39979 8.14337 2.94304 9.04628 2.33804C9.31783 2.15493 9.5447 1.93395 9.72704 1.67544C9.90905 1.41692 10.0003 1.14575 10.0003 0.862012C10.0003 0.62495 9.91183 0.422102 9.73526 0.253153C9.5585 0.0844134 9.34919 0 9.10737 0Z"
      fill="white"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25619 18.93L4.97889 20.469C2.47693 18.3925 1 15.3162 1 12.0008C1 8.69006 2.47282 5.6176 4.96885 3.54102L6.24797 5.0785C4.20393 6.77904 3 9.29058 3 12.0008C3 14.7148 4.2073 17.2295 6.25619 18.93ZM17.7436 18.93L19.0209 20.469C21.5229 18.3925 22.9998 15.3162 22.9998 12.0008C22.9998 8.69006 21.527 5.6176 19.031 3.54102L17.7519 5.0785C19.7959 6.77904 20.9998 9.29058 20.9998 12.0008C20.9998 14.7148 19.7925 17.2295 17.7436 18.93ZM15.1785 15.8607L16.4509 17.4038C18.0529 16.0828 19 14.1182 19 12.0008C19 9.89043 18.0591 7.93158 16.4661 6.61047L15.1894 8.14994C16.329 9.09503 17 10.492 17 12.0008C17 13.5146 16.3245 14.9157 15.1785 15.8607ZM7.54894 17.4038L8.82134 15.8607C7.67534 14.9157 6.99984 13.5146 6.99984 12.0008C6.99984 10.492 7.67088 9.09503 8.81045 8.14994L7.53372 6.61047C5.94074 7.93158 4.99984 9.89043 4.99984 12.0008C4.99984 14.1182 5.94698 16.0828 7.54894 17.4038Z"
      fill="#404040"
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M13 14.292C13.883 13.9062 14.5 13.0252 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.0252 10.117 13.9062 11 14.292V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V14.292Z"
      fill="#404040"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

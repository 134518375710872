/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9.07512 3.73349V5.86682H9.60846V3.73349H10.1418V5.86682H10.6751V3.73349H11.4751C11.6223 3.73349 11.7418 3.61403 11.7418 3.46683V2.40016C11.7418 2.28943 11.6735 2.1903 11.57 2.15083L5.97006 0.0175C5.90893 -0.00583332 5.84146 -0.00583332 5.78027 0.0175L0.180345 2.15083C0.0768114 2.1903 0.00854492 2.28943 0.00854492 2.40016V3.46683C0.00854492 3.61403 0.128011 3.73349 0.275211 3.73349H1.07521V8.00015H0.808543C0.661277 8.00015 0.541877 8.11948 0.541877 8.26675V9.06675H0.275211C0.128011 9.06675 0.00854492 9.18615 0.00854492 9.33341V10.4001C0.00854492 10.5472 0.128011 10.6667 0.275211 10.6667H8.00846V10.1334H0.541877V9.60008H8.00846V9.06675H1.07521V8.53341H8.00846V3.73349H9.07512ZM0.541877 2.58383L5.8752 0.552099L11.2085 2.58383V3.20016H0.541877V2.58383ZM6.40846 3.73349V8.00015H5.34187V3.73349H6.40846ZM3.74187 3.73349V8.00015H2.67521V3.73349H3.74187ZM1.60854 8.00015V3.73349H2.14187V8.00015H1.60854ZM4.2752 8.00015V3.73349H4.80853V8.00015H4.2752ZM7.47513 8.00015H6.9418V3.73349H7.47513V8.00015Z"
        fill="#ED017F"
      />
      <path
        d="M5.87519 2.6664C6.31692 2.6664 6.67512 2.3082 6.67512 1.8664C6.67512 1.42461 6.31692 1.06641 5.87519 1.06641C5.43339 1.06641 5.0752 1.42461 5.0752 1.8664C5.0752 2.3082 5.43339 2.6664 5.87519 2.6664ZM5.87519 1.59974C6.02246 1.59974 6.14185 1.7192 6.14185 1.8664C6.14185 2.01367 6.02246 2.13307 5.87519 2.13307C5.72786 2.13307 5.60852 2.01367 5.60852 1.8664C5.60852 1.7192 5.72786 1.59974 5.87519 1.59974Z"
        fill="#ED017F"
      />
      <path
        d="M14.4085 10.0232V6.66706C14.4085 6.51972 14.2891 6.40039 14.1419 6.40039H8.80854C8.66127 6.40039 8.54187 6.51972 8.54187 6.66706V15.7336C8.54187 15.8808 8.66127 16.0003 8.80854 16.0003H13.0752V15.7982C13.0733 15.1441 13.3332 14.5166 13.7971 14.0554C13.9011 13.9513 13.9011 13.7825 13.7971 13.6784L11.9304 11.8118C11.747 11.6202 11.7502 11.317 11.9377 11.1294C12.1253 10.942 12.4284 10.9386 12.62 11.1222L14.4867 12.9888L14.8637 12.6117L14.4085 12.1565V10.7774L14.9285 11.2973C15.2795 11.6466 15.4764 12.1218 15.4751 12.617V16.0003H16.0085V12.617C16.0103 11.9802 15.7571 11.3693 15.3055 10.9203L14.4085 10.0232ZM12.8085 10.0827C12.036 9.43945 10.9144 9.43945 10.1419 10.0827V8.48805C10.3694 8.40692 10.5486 8.22799 10.6296 8.00039H12.3207C12.4018 8.22799 12.5808 8.40692 12.8085 8.48805V10.0827ZM10.1419 12.3178C10.5136 12.6342 10.9872 12.8056 11.4752 12.8003C11.6796 12.8004 11.8829 12.7716 12.0793 12.7146L12.8085 13.444V13.9124C12.5808 13.9936 12.4018 14.1727 12.3207 14.4003H10.6296C10.5486 14.1727 10.3694 13.9936 10.1419 13.9124V12.3178ZM11.5533 10.7446C11.1551 11.1436 11.1551 11.7894 11.5533 12.1883L11.621 12.2562C11.5728 12.2605 11.5246 12.2664 11.4752 12.2664C10.74 12.2664 10.1419 11.788 10.1419 11.1998C10.1419 10.6114 10.74 10.1331 11.4752 10.1331C11.823 10.1282 12.1618 10.2443 12.4336 10.4614C12.1116 10.4094 11.7844 10.515 11.5533 10.7451V10.7446ZM13.3419 11.0899V8.26699C13.3419 8.11972 13.2225 8.00039 13.0752 8.00039C12.9278 8.00039 12.8085 7.88092 12.8085 7.73372C12.8085 7.58639 12.6891 7.46705 12.5418 7.46705H10.4086C10.2613 7.46705 10.1419 7.58639 10.1419 7.73372C10.1419 7.88092 10.0226 8.00039 9.87522 8.00039C9.72795 8.00039 9.60855 8.11972 9.60855 8.26699V14.1336C9.60855 14.2808 9.72795 14.4003 9.87522 14.4003C10.0226 14.4003 10.1419 14.5196 10.1419 14.667C10.1419 14.8141 10.2613 14.9336 10.4086 14.9336H12.5418C12.5934 14.9322 12.6435 14.9157 12.6856 14.8858C12.625 15.0748 12.5836 15.2695 12.5621 15.467H9.07521V6.93372H13.8752V11.6232L13.3419 11.0899Z"
        fill="#ED017F"
      />
      <path
        d="M11.2084 8.5332H11.7417V9.06658H11.2084V8.5332Z"
        fill="#ED017F"
      />
      <path
        d="M11.2084 13.333H11.7417V13.8663H11.2084V13.333Z"
        fill="#ED017F"
      />
      <path
        d="M4.00854 12.2666H4.54186V12.7999H4.00854V12.2666Z"
        fill="#ED017F"
      />
      <path
        d="M4.00854 11.2002H4.54186V11.7336H4.00854V11.2002Z"
        fill="#ED017F"
      />
      <path
        d="M4.00854 13.333H4.54186V13.8663H4.00854V13.333Z"
        fill="#ED017F"
      />
      <path
        d="M4.00854 14.4004H4.54186V14.9337H4.00854V14.4004Z"
        fill="#ED017F"
      />
      <path
        d="M5.0752 14.4004H5.60851V14.9337H5.0752V14.4004Z"
        fill="#ED017F"
      />
      <path
        d="M6.14185 14.4004H6.6751V14.9337H6.14185V14.4004Z"
        fill="#ED017F"
      />
      <path
        d="M7.20837 14.4004H7.74169V14.9337H7.20837V14.4004Z"
        fill="#ED017F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

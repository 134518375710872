/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C9.62663 0 7.30655 0.703787 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519935 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0ZM8 8C8.26371 8 8.5215 8.0782 8.74076 8.2247C8.96003 8.37121 9.13093 8.57945 9.23184 8.82309C9.33276 9.06672 9.35916 9.33481 9.30772 9.59345C9.25627 9.85209 9.12928 10.0897 8.94281 10.2761C8.75634 10.4626 8.51877 10.5896 8.26013 10.641C8.00148 10.6925 7.73339 10.6661 7.48976 10.5652C7.24612 10.4643 7.03788 10.2934 6.89138 10.0741C6.74487 9.85483 6.66667 9.59704 6.66667 9.33333C6.66667 8.97971 6.80715 8.64057 7.05719 8.39052C7.30724 8.14047 7.64638 8 8 8ZM16 17.3333H8C7.64638 17.3333 7.30724 17.1929 7.05719 16.9428C6.80715 16.6928 6.66667 16.3536 6.66667 16C6.66667 15.6464 6.80715 15.3072 7.05719 15.0572C7.30724 14.8071 7.64638 14.6667 8 14.6667H16C16.3536 14.6667 16.6928 14.8071 16.9428 15.0572C17.1929 15.3072 17.3333 15.6464 17.3333 16C17.3333 16.3536 17.1929 16.6928 16.9428 16.9428C16.6928 17.1929 16.3536 17.3333 16 17.3333ZM16 10.6667C15.7363 10.6667 15.4785 10.5885 15.2592 10.442C15.04 10.2954 14.8691 10.0872 14.7682 9.84357C14.6672 9.59994 14.6408 9.33185 14.6923 9.07321C14.7437 8.81457 14.8707 8.57699 15.0572 8.39052C15.2437 8.20405 15.4812 8.07706 15.7399 8.02562C15.9985 7.97417 16.2666 8.00058 16.5102 8.10149C16.7539 8.20241 16.9621 8.37331 17.1086 8.59257C17.2551 8.81184 17.3333 9.06962 17.3333 9.33333C17.3333 9.68695 17.1929 10.0261 16.9428 10.2761C16.6928 10.5262 16.3536 10.6667 16 10.6667Z"
      fill="#DFDFDF"
    />
  </svg>
);

export default SVG;

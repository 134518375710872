/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.3335 3.90983C2.96531 3.90983 2.66683 4.20831 2.66683 4.5765V13.9098C2.66683 14.278 2.96531 14.5765 3.3335 14.5765H12.6668C13.035 14.5765 13.3335 14.278 13.3335 13.9098V4.5765C13.3335 4.20831 13.035 3.90983 12.6668 3.90983H3.3335ZM1.3335 4.5765C1.3335 3.47193 2.22893 2.5765 3.3335 2.5765H12.6668C13.7714 2.5765 14.6668 3.47193 14.6668 4.5765V13.9098C14.6668 15.0144 13.7714 15.9098 12.6668 15.9098H3.3335C2.22893 15.9098 1.3335 15.0144 1.3335 13.9098V4.5765Z"
      fill="#F1F1F1"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.6668 1.24316C11.035 1.24316 11.3335 1.54164 11.3335 1.90983V4.5765C11.3335 4.94469 11.035 5.24316 10.6668 5.24316C10.2986 5.24316 10.0002 4.94469 10.0002 4.5765V1.90983C10.0002 1.54164 10.2986 1.24316 10.6668 1.24316Z"
      fill="#F1F1F1"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.3335 1.24316C5.70169 1.24316 6.00016 1.54164 6.00016 1.90983V4.5765C6.00016 4.94469 5.70169 5.24316 5.3335 5.24316C4.96531 5.24316 4.66683 4.94469 4.66683 4.5765V1.90983C4.66683 1.54164 4.96531 1.24316 5.3335 1.24316Z"
      fill="#F1F1F1"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.3335 7.24316C1.3335 6.87497 1.63197 6.5765 2.00016 6.5765H14.0002C14.3684 6.5765 14.6668 6.87497 14.6668 7.24316C14.6668 7.61135 14.3684 7.90983 14.0002 7.90983H2.00016C1.63197 7.90983 1.3335 7.61135 1.3335 7.24316Z"
      fill="#F1F1F1"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

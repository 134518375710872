/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#F5F5F9"
      height="47"
      rx="9.5"
      stroke="#6B7280"
      width="47"
      x="0.5"
      y="0.5"
    />
    <path
      d="M15.52 14V16.5467H11V19.04C11 23.2133 12.24 25.54 15.22 26.9867C16.3279 27.5823 17.3504 28.3246 18.26 29.1933C18.9911 29.9732 19.8827 30.585 20.8733 30.9867L22 31.3333V34.3533H20.28C18.2 34.3533 17.5 34.8267 17.5 36.2333V37.18H30.5V36.3333C30.5 34.9533 29.6467 34.3333 27.6733 34.3333H26V31.3333L27.1467 30.9933C28.1387 30.5913 29.0307 29.9769 29.76 29.1933C30.7089 28.2889 31.7711 27.5113 32.92 26.88C33.8121 26.4507 34.6087 25.8465 35.2627 25.1032C35.9166 24.3599 36.4145 23.4928 36.7267 22.5533C36.9389 21.3901 37.0371 20.209 37.02 19.0267V16.5533H32.48V14H15.52ZM24 18.2467L25.3667 21.0733H28.66L26.08 23.4533L26.82 26.4933L24 24.98L21.18 26.5133L21.92 23.4733L19.3333 21.0933H22.6333L24 18.2467ZM13.3733 19.0933H15.48V24.3467C15.26 24.48 14.5733 23.68 14.0733 22.7667C13.7895 22.0674 13.6076 21.331 13.5333 20.58L13.3733 19.0933ZM32.52 19.0933H34.8133L34.62 20.56C34.5746 21.7809 34.0837 22.943 33.24 23.8267C33.0415 24.0971 32.7897 24.324 32.5 24.4933L32.52 19.0933Z"
      stroke="#F9A025"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="31"
    viewBox="0 0 25 31"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9198 8.83452C17.2198 8.51452 17.7398 8.51453 18.0598 8.81453C18.2198 8.97453 18.2998 9.17453 18.2998 9.39453C18.2998 9.59453 18.2198 9.79454 18.0798 9.95454L13.0798 15.1545C12.9198 15.3145 12.7198 15.3945 12.4998 15.3945C12.2798 15.3945 12.0798 15.3145 11.9198 15.1545L6.91983 9.95454C6.61983 9.63454 6.61983 9.13453 6.93983 8.81453C7.25983 8.51453 7.75983 8.51452 8.07983 8.83452L12.4998 13.4345L16.9198 8.83452Z"
      fill="#ED017F"
    />
    <path
      d="M16.9198 15.8345C17.2198 15.5145 17.7398 15.5145 18.0598 15.8145C18.2198 15.9745 18.2998 16.1745 18.2998 16.3945C18.2998 16.5945 18.2198 16.7945 18.0798 16.9545L13.0798 22.1545C12.9198 22.3145 12.7198 22.3945 12.4998 22.3945C12.2798 22.3945 12.0798 22.3145 11.9198 22.1545L6.91983 16.9545C6.61983 16.6345 6.61983 16.1345 6.93983 15.8145C7.25983 15.5145 7.75983 15.5145 8.07983 15.8345L12.4998 20.4345L16.9198 15.8345Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

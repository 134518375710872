/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_20257_73905)">
      <path
        d="M16.3462 2.48233C16.5896 1.75177 16.5441 1.18867 16.209 0.792783C15.8133 0.457873 15.25 0.412371 14.5193 0.655476C13.7887 0.899022 13.1341 1.30994 12.5557 1.88832L10.7292 3.71493L3.13789 1.89987C3.01609 1.86177 2.90194 1.8922 2.79546 1.99112L1.33415 3.45235C1.25054 3.53616 1.2162 3.63888 1.23147 3.76063C1.2543 3.88254 1.31904 3.97375 1.42556 4.03469L7.22481 7.21969L4.26805 10.1765L2.05339 9.57158C2.0382 9.5639 2.00785 9.56006 1.96215 9.56006C1.85555 9.56006 1.7681 9.59425 1.69957 9.66282L0.603716 10.7701C0.527626 10.8612 0.493439 10.9564 0.501036 11.0552C0.51615 11.177 0.565611 11.2646 0.649418 11.3179L3.52614 13.4754L5.68365 16.352C5.75219 16.4359 5.84355 16.4851 5.95771 16.5006H5.98054C6.08714 16.5006 6.17458 16.4664 6.24312 16.398L7.33893 15.3023C7.43785 15.1956 7.46832 15.0776 7.43033 14.9483L6.82525 12.7336L9.78198 9.77662L12.9671 15.5762C13.0127 15.6752 13.0926 15.736 13.2068 15.7589C13.2296 15.7665 13.2562 15.7703 13.2868 15.7703C13.3779 15.7703 13.4503 15.7474 13.5035 15.7018L14.965 14.6059C15.0942 14.4995 15.1399 14.374 15.102 14.2294L13.2755 6.28401L15.1135 4.44613C15.6918 3.86751 16.1027 3.21288 16.3462 2.48233Z"
        fill="#666565"
      />
    </g>
    <defs>
      <clipPath id="clip0_20257_73905">
        <rect
          fill="white"
          height="16"
          transform="translate(0.5 0.5)"
          width="16"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

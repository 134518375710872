/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.71768 6.67021C8.73592 6.4331 8.93364 6.25 9.17146 6.25C9.40527 6.25 9.60105 6.42718 9.62432 6.65983L10 10.4167L12.6945 11.9564C12.8319 12.0349 12.9167 12.181 12.9167 12.3393C12.9167 12.6301 12.6402 12.8413 12.3596 12.7647L8.73199 11.7754C8.50062 11.7123 8.34663 11.4938 8.36503 11.2547L8.71768 6.67021Z"
      fill="#505050"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.27398 1.30874C5.00988 0.993996 4.49994 1.11447 4.4046 1.51413L3.52858 5.18676C3.45091 5.51241 3.70898 5.82091 4.04323 5.80197L7.82131 5.58787C8.23221 5.56459 8.44059 5.08256 8.17604 4.76728L7.29061 3.71206C8.01864 3.46328 8.79064 3.33334 9.58334 3.33334C13.4954 3.33334 16.6667 6.50466 16.6667 10.4167C16.6667 14.3287 13.4954 17.5 9.58334 17.5C5.67132 17.5 2.5 14.3287 2.5 10.4167C2.5 9.75995 2.58894 9.11673 2.76234 8.49852L1.15761 8.04839C0.946331 8.80162 0.833336 9.59595 0.833336 10.4167C0.833336 15.2492 4.75084 19.1667 9.58334 19.1667C14.4158 19.1667 18.3333 15.2492 18.3333 10.4167C18.3333 5.58419 14.4158 1.66668 9.58334 1.66668C8.36779 1.66668 7.21014 1.91454 6.15817 2.36247L5.27398 1.30874Z"
      fill="#505050"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

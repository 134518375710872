/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="60"
    viewBox="0 0 60 60"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1290:7544)">
      <path
        d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129985 24.0013 -0.581101 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4235C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4457 51.6476 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.78679C45.5871 3.1607 37.9565 0 30 0V0ZM41.058 38.4C41.3335 38.6776 41.4882 39.0529 41.4882 39.444C41.4882 39.8351 41.3335 40.2104 41.058 40.488L40.5 41.046C40.2224 41.3215 39.8471 41.4761 39.456 41.4761C39.0649 41.4761 38.6896 41.3215 38.412 41.046L30.012 32.646L21.612 41.046C21.3344 41.3215 20.9591 41.4761 20.568 41.4761C20.1769 41.4761 19.8016 41.3215 19.524 41.046L18.966 40.488C18.6905 40.2104 18.5359 39.8351 18.5359 39.444C18.5359 39.0529 18.6905 38.6776 18.966 38.4L27.366 30L18.966 21.6C18.6905 21.3224 18.5359 20.9471 18.5359 20.556C18.5359 20.1649 18.6905 19.7896 18.966 19.512L19.524 18.954C19.8016 18.6785 20.1769 18.5238 20.568 18.5238C20.9591 18.5238 21.3344 18.6785 21.612 18.954L30.012 27.354L38.412 18.954C38.6896 18.6785 39.0649 18.5238 39.456 18.5238C39.8471 18.5238 40.2224 18.6785 40.5 18.954L41.058 19.512C41.3335 19.7896 41.4882 20.1649 41.4882 20.556C41.4882 20.9471 41.3335 21.3224 41.058 21.6L32.658 30L41.058 38.4Z"
        fill="#E11900"
      />
    </g>
    <defs>
      <clipPath id="clip0_1290:7544">
        <rect fill="white" height="60" width="60" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

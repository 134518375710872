class BuyAirtimeForm {
  productCode = null;
  operator = "";
  countryCode = "";
  phoneNumber = "";
  amount = 0;
  saveBeneficiary = false;
  policyAgree = false;
}

export default BuyAirtimeForm;

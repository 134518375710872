/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 52 36"
    width="52"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H47C49.7614 0 52 2.23858 52 5V31C52 33.7614 49.7614 36 47 36H5C2.23858 36 0 33.7614 0 31V5Z"
      fill="#F6F6F6"
    />
    <path
      d="M36.0001 18.2299C36.0001 17.5501 35.9438 16.8667 35.8239 16.198H26.2015V20.0487H31.7118C31.4831 21.2906 30.7484 22.3892 29.6726 23.0873V25.5859H32.96C34.8905 23.8443 36.0001 21.2722 36.0001 18.2299Z"
      fill="#4285F4"
    />
    <path
      d="M26.2014 28C28.9528 28 31.2731 27.1145 32.9636 25.586L29.6762 23.0874C28.7616 23.6974 27.5808 24.0428 26.2051 24.0428C23.5437 24.0428 21.2871 22.2828 20.4775 19.9165H17.0851V22.4922C18.8169 25.8689 22.3442 28 26.2014 28Z"
      fill="#34A853"
    />
    <path
      d="M20.4738 19.9164C20.0465 18.6745 20.0465 17.3297 20.4738 16.0878V13.5121H17.0852C15.6383 16.3376 15.6383 19.6666 17.0852 22.4921L20.4738 19.9164Z"
      fill="#FBBC04"
    />
    <path
      d="M26.2014 11.9579C27.6558 11.9359 29.0615 12.4723 30.1148 13.457L33.0274 10.6021C31.1831 8.90456 28.7353 7.97128 26.2014 8.00067C22.3442 8.00067 18.8169 10.1318 17.0851 13.5122L20.4737 16.0879C21.2796 13.7179 23.54 11.9579 26.2014 11.9579Z"
      fill="#EA4335"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="60"
    viewBox="0 0 60 60"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" fill="#ED017F" fillOpacity="0.1" r="30" />
    <path
      d="M20 22C20 21.2044 20.3161 20.4413 20.8787 19.8787C21.4413 19.3161 22.2044 19 23 19H39C39.7956 19 40.5587 19.3161 41.1213 19.8787C41.6839 20.4413 42 21.2044 42 22V32C42 32.7956 41.6839 33.5587 41.1213 34.1213C40.5587 34.6839 39.7956 35 39 35H31V33H39C39.2652 33 39.5196 32.8946 39.7071 32.7071C39.8946 32.5196 40 32.2652 40 32V22C40 21.7348 39.8946 21.4804 39.7071 21.2929C39.5196 21.1054 39.2652 21 39 21H23C22.7348 21 22.4804 21.1054 22.2929 21.2929C22.1054 21.4804 22 21.7348 22 22H21C20.654 22 20.32 22.044 20 22.126V22ZM43 39H31V37H43C43.2652 37 43.5196 37.1054 43.7071 37.2929C43.8946 37.4804 44 37.7348 44 38C44 38.2652 43.8946 38.5196 43.7071 38.7071C43.5196 38.8946 43.2652 39 43 39ZM23 39C23.2652 39 23.5196 38.8946 23.7071 38.7071C23.8946 38.5196 24 38.2652 24 38C24 37.7348 23.8946 37.4804 23.7071 37.2929C23.5196 37.1054 23.2652 37 23 37C22.7348 37 22.4804 37.1054 22.2929 37.2929C22.1054 37.4804 22 37.7348 22 38C22 38.2652 22.1054 38.5196 22.2929 38.7071C22.4804 38.8946 22.7348 39 23 39V39ZM17 27C17 26.2044 17.3161 25.4413 17.8787 24.8787C18.4413 24.3161 19.2044 24 20 24H26C26.7956 24 27.5587 24.3161 28.1213 24.8787C28.6839 25.4413 29 26.2044 29 27V39C29 39.7956 28.6839 40.5587 28.1213 41.1213C27.5587 41.6839 26.7956 42 26 42H20C19.2044 42 18.4413 41.6839 17.8787 41.1213C17.3161 40.5587 17 39.7956 17 39V27ZM20 26C19.7348 26 19.4804 26.1054 19.2929 26.2929C19.1054 26.4804 19 26.7348 19 27V39C19 39.2652 19.1054 39.5196 19.2929 39.7071C19.4804 39.8946 19.7348 40 20 40H26C26.2652 40 26.5196 39.8946 26.7071 39.7071C26.8946 39.5196 27 39.2652 27 39V27C27 26.7348 26.8946 26.4804 26.7071 26.2929C26.5196 26.1054 26.2652 26 26 26H20Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.6276 24 12C24 5.3724 18.627 0 12 0ZM15.8466 6.3408C16.6674 6.3408 17.3334 7.2018 17.3334 8.2644C17.3334 9.327 16.668 10.188 15.8466 10.188C15.0252 10.188 14.3598 9.327 14.3598 8.2644C14.3598 7.2018 15.0252 6.3408 15.8466 6.3408ZM8.0658 6.3408C8.8872 6.3408 9.5526 7.2018 9.5526 8.2644C9.5526 9.327 8.8872 10.188 8.0658 10.188C7.2444 10.188 6.579 9.327 6.579 8.2644C6.5796 7.2018 7.245 6.3408 8.0658 6.3408ZM19.5348 13.6992C18.9012 15.201 16.6452 19.674 11.6178 19.674C6.792 19.674 4.8234 15.4782 4.2414 13.8624C3.8418 14.1336 3.5088 14.2938 3.4818 14.307L3.099 13.5006C3.1104 13.4958 4.2336 12.9546 4.6806 12.228L5.4408 12.6948C5.2452 13.0122 4.968 13.29 4.6842 13.5258C5.2446 14.8398 7.2366 18.6246 11.6172 18.6246C16.0866 18.6246 18.5238 14.6034 19.1574 13.3902C18.9318 13.185 18.7182 12.9528 18.5592 12.6948L19.3194 12.228C19.7664 12.9546 20.8896 13.4958 20.901 13.5006L20.5182 14.307C20.4864 14.2908 20.0274 14.0664 19.5348 13.6992Z"
      fill="#DFDFDF"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";

/**
 * Whatsapp Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any) => (
  <svg
    fill="none"
    height="26"
    viewBox="0 0 24 26"
    width="35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7267_80660)">
      <path
        d="M20.52 3.73642C18.24 1.34875 15.24 0 12.045 0C2.875 0 -2.883 10.7629 1.696 19.3245L0 26L6.335 24.2103C9.095 25.8256 11.356 25.6826 12.051 25.7779C22.684 25.7779 27.977 11.8419 20.505 3.77867L20.52 3.73642Z"
        fill="#ECEFF1"
      />
      <path
        d="M12.0659 23.5631L12.0599 23.562H12.0439C8.86191 23.562 6.82891 21.9294 6.62891 21.8352L2.87891 22.8914L3.88391 18.9427L3.64491 18.5364C2.65491 16.8291 2.12891 14.8628 2.12891 12.8381C2.12891 3.31233 12.8739 -1.45109 19.0919 5.28183C25.2949 11.9443 20.9399 23.5631 12.0659 23.5631Z"
        fill="#4CAF50"
      />
      <path
        d="M17.5071 15.4984L17.4981 15.5796C17.1971 15.4171 15.7311 14.6404 15.4581 14.5331C14.8451 14.2872 15.0181 14.4941 13.8411 15.9545C13.6661 16.1657 13.4921 16.182 13.1951 16.0357C12.8951 15.8732 11.9321 15.532 10.7921 14.427C9.90414 13.5657 9.30814 12.5095 9.13214 12.1845C8.83914 11.6363 9.45214 11.5583 10.0101 10.4143C10.1101 10.1868 10.0591 10.008 9.98514 9.84663C9.91014 9.68413 9.31314 8.09163 9.06314 7.45679C8.82314 6.82413 8.57614 6.90429 8.39114 6.90429C7.81514 6.85013 7.39414 6.85879 7.02314 7.27696C5.40914 9.19879 5.81614 11.1813 7.19714 13.2895C9.91114 17.1375 11.3571 17.846 14.0011 18.8296C14.7151 19.0755 15.3661 19.0409 15.8811 18.9607C16.4551 18.8621 17.6481 18.1796 17.8971 17.4159C18.1521 16.6521 18.1521 16.0184 18.0771 15.8721C18.0031 15.7259 17.8071 15.6446 17.5071 15.4984Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_7267_80660">
        <rect fill="white" height="26" width="24" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
/* eslint-enable max-len */

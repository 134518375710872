/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 6.08269L10.3576 12.8535C9.88621 13.4192 9.04546 13.4957 8.47976 13.0243C7.91405 12.5528 7.83762 11.7121 8.30904 11.1464L14.9757 3.14638C15.5088 2.50671 16.4912 2.50671 17.0243 3.14638L23.691 11.1464C24.1624 11.7121 24.086 12.5528 23.5203 13.0243C22.9545 13.4957 22.1138 13.4192 21.6424 12.8535L16 6.08269Z"
      fill="black"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M4.04073 12C3.48844 12 3.04073 12.4477 3.04073 13V25C3.04073 26.6569 4.38387 28 6.04072 28H25.959C27.6158 28 28.959 26.6569 28.959 25V13C28.959 12.4477 28.5113 12 27.959 12H4.04073ZM15.6667 16C15.1144 16 14.6667 16.4477 14.6667 17V23C14.6667 23.5523 15.1144 24 15.6667 24H16.3333C16.8856 24 17.3333 23.5523 17.3333 23V17C17.3333 16.4477 16.8856 16 16.3333 16H15.6667ZM8.25722 17.4927C8.11428 16.9593 8.43086 16.4109 8.96433 16.268L9.60828 16.0955C10.1417 15.9525 10.6901 16.2691 10.833 16.8026L12.3859 22.5981C12.5289 23.1316 12.2123 23.6799 11.6788 23.8229L11.0349 23.9954C10.5014 24.1383 9.95308 23.8218 9.81014 23.2883L8.25722 17.4927ZM22.3917 16.0955C21.8583 15.9525 21.3099 16.2691 21.167 16.8026L19.6141 22.5981C19.4711 23.1316 19.7877 23.6799 20.3212 23.8229L20.9651 23.9954C21.4986 24.1383 22.0469 23.8218 22.1899 23.2883L23.7428 17.4927C23.8857 16.9593 23.5692 16.4109 23.0357 16.268L22.3917 16.0955Z"
      fill="#F36923"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

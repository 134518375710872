/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.16668 4.16667C6.86549 4.16667 5.00001 6.03215 5.00001 8.33333V9.16667V15H2.50001C1.57954 15 0.833344 14.2538 0.833344 13.3333V10C0.833344 9.07952 1.57954 8.33333 2.50001 8.33333H3.33334C3.33334 5.11167 5.94502 2.5 9.16668 2.5H10.8333C14.055 2.5 16.6667 5.11167 16.6667 8.33333L17.5 8.33333C18.4205 8.33333 19.1667 9.07952 19.1667 10V13.3333C19.1667 14.2538 18.4205 15 17.5 15H16.5617C16.1916 16.4377 14.8865 17.5 13.3333 17.5H12.277C11.9889 17.9982 11.4502 18.3333 10.8333 18.3333C9.91287 18.3333 9.16668 17.5871 9.16668 16.6667C9.16668 15.7462 9.91287 15 10.8333 15C11.4502 15 11.9889 15.3352 12.277 15.8333H13.3333C14.2536 15.8333 14.9996 15.0875 15 14.1673C15 14.1671 15 14.1669 15 14.1667L15 9.16667V8.33333C15 6.03215 13.1345 4.16667 10.8333 4.16667H9.16668ZM3.33334 10H2.50001L2.50001 13.3333H3.33334V10ZM9.16668 10.2083C9.16668 10.7836 8.70031 11.25 8.12501 11.25C7.54971 11.25 7.08334 10.7836 7.08334 10.2083C7.08334 9.63304 7.54971 9.16667 8.12501 9.16667C8.70031 9.16667 9.16668 9.63304 9.16668 10.2083ZM11.875 11.25C12.4503 11.25 12.9167 10.7836 12.9167 10.2083C12.9167 9.63304 12.4503 9.16667 11.875 9.16667C11.2997 9.16667 10.8333 9.63304 10.8333 10.2083C10.8333 10.7836 11.2997 11.25 11.875 11.25ZM16.6667 10H17.5V13.3333H16.6667V10Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

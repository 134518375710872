/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6674 5.83333C11.6674 6.29357 12.0403 6.66667 12.5004 6.66667C12.9604 6.66667 13.3333 6.29357 13.3333 5.83333V5C13.3333 3.15905 11.8416 1.66667 10.0014 1.66667H5.00433C3.16418 1.66667 1.67244 3.15905 1.67244 5V15C1.67244 16.841 3.16418 18.3333 5.00433 18.3333H10.0072C11.8474 18.3333 13.3391 16.841 13.3391 15V14.1667C13.3391 13.7064 12.9662 13.3333 12.5061 13.3333C12.0461 13.3333 11.6732 13.7064 11.6732 14.1667V15C11.6732 15.9205 10.9273 16.6667 10.0072 16.6667H5.00433C4.08426 16.6667 3.33839 15.9205 3.33839 15V5C3.33839 4.07953 4.08426 3.33333 5.00433 3.33333H10.0014C10.9215 3.33333 11.6674 4.07953 11.6674 5V5.83333Z"
      fill="#ED017F"
    />
    <rect
      fill="#ED017F"
      height="10"
      rx="0.833333"
      transform="rotate(90 16.6667 9.16666)"
      width="1.66667"
      x="16.6667"
      y="9.16666"
    />
    <path
      d="M14.4107 8.08926C14.0853 7.76382 14.0853 7.23618 14.4107 6.91075C14.7362 6.58531 15.2638 6.58531 15.5892 6.91075L18.0892 9.41075C18.4147 9.73618 18.4147 10.2638 18.0892 10.5893L15.5892 13.0893C15.2638 13.4147 14.7362 13.4147 14.4107 13.0893C14.0853 12.7638 14.0853 12.2362 14.4107 11.9107L16.3215 10L14.4107 8.08926Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

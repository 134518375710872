/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 17 12"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.4271 0C14.5908 0 15.7237 0.298452 16.3374 1.03011C16.9539 1.76502 17 2.89068 17 4.52402V7.47603C17 9.34771 16.7057 10.4288 16.0159 11.0909C15.3432 11.7366 14.3035 12 12.4271 12H4.57278C0.7072 12 0 10.4766 0 7.47603V4.52402C0 2.97522 0 1.85616 0.596042 1.09667C1.20738 0.317703 2.35955 0 4.57278 0H12.4271ZM7.34746 8.27722L10.9141 6.40758C11.0952 6.31264 11.2087 6.12447 11.2085 5.91931C11.2082 5.71431 11.0943 5.52642 10.9128 5.43192L7.34619 3.57432C7.17625 3.48577 6.97252 3.49259 6.80872 3.59237C6.64497 3.69214 6.54505 3.87036 6.54505 4.06243V7.78972C6.54505 7.98212 6.64525 8.1605 6.80932 8.26022C6.89646 8.31314 6.99484 8.33976 7.09344 8.33976C7.18053 8.33976 7.26772 8.31902 7.34746 8.27722Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10.6663V9.33301C8 7.21128 8.84285 5.17644 10.3431 3.67615C11.8434 2.17586 13.8783 1.33301 16 1.33301C18.1217 1.33301 20.1566 2.17586 21.6569 3.67615C23.1571 5.17644 24 7.21128 24 9.33301V10.6663H26.6667C27.0203 10.6663 27.3594 10.8068 27.6095 11.0569C27.8595 11.3069 28 11.6461 28 11.9997V27.9997C28 28.3533 27.8595 28.6924 27.6095 28.9425C27.3594 29.1925 27.0203 29.333 26.6667 29.333H5.33333C4.97971 29.333 4.64057 29.1925 4.39052 28.9425C4.14048 28.6924 4 28.3533 4 27.9997V11.9997C4 11.6461 4.14048 11.3069 4.39052 11.0569C4.64057 10.8068 4.97971 10.6663 5.33333 10.6663H8ZM25.3333 13.333H6.66667V26.6663H25.3333V13.333ZM14.6667 20.9757C14.1583 20.6822 13.761 20.2291 13.5363 19.6868C13.3117 19.1444 13.2723 18.5431 13.4242 17.9761C13.5761 17.4091 13.9109 16.908 14.3766 16.5507C14.8424 16.1933 15.413 15.9996 16 15.9996C16.587 15.9996 17.1576 16.1933 17.6234 16.5507C18.0891 16.908 18.4239 17.4091 18.5758 17.9761C18.7277 18.5431 18.6883 19.1444 18.4637 19.6868C18.239 20.2291 17.8417 20.6822 17.3333 20.9757V23.9997H14.6667V20.9757ZM10.6667 10.6663H21.3333V9.33301C21.3333 7.91852 20.7714 6.56197 19.7712 5.56177C18.771 4.56158 17.4145 3.99967 16 3.99967C14.5855 3.99967 13.229 4.56158 12.2288 5.56177C11.2286 6.56197 10.6667 7.91852 10.6667 9.33301V10.6663Z"
      fill="#ACACAC"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 16 16"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      fill="#ED017F"
    />
    <path
      d="M15.3394 7.45625C14.5125 6.1775 13.4397 5.09125 12.2372 4.31469C10.9069 3.45469 9.43812 3 7.99 3C6.66125 3 5.35469 3.37969 4.10656 4.12844C2.83375 4.89188 1.68062 6.00719 0.679061 7.44312C0.565994 7.60541 0.503723 7.79764 0.500162 7.9954C0.496601 8.19315 0.55191 8.3875 0.659061 8.55375C1.48437 9.84531 2.54656 10.9331 3.73031 11.6991C5.06312 12.5625 6.4975 13 7.99 13C9.44969 13 10.9216 12.5491 12.2462 11.6962C13.4481 10.9222 14.5187 9.83187 15.3425 8.5425C15.446 8.38012 15.5007 8.19146 15.5001 7.99892C15.4995 7.80638 15.4438 7.61804 15.3394 7.45625ZM8.00062 11C7.40728 11 6.82726 10.8241 6.33391 10.4944C5.84056 10.1648 5.45605 9.69623 5.22898 9.14805C5.00192 8.59987 4.94251 7.99667 5.05827 7.41473C5.17402 6.83279 5.45974 6.29824 5.8793 5.87868C6.29886 5.45912 6.83341 5.1734 7.41535 5.05764C7.99729 4.94189 8.6005 5.0013 9.14867 5.22836C9.69685 5.45542 10.1654 5.83994 10.495 6.33329C10.8247 6.82664 11.0006 7.40666 11.0006 8C10.9997 8.79537 10.6834 9.5579 10.1209 10.1203C9.55853 10.6827 8.79599 10.9991 8.00062 11Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 101 40"
    width="101"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        clipRule="evenodd"
        d="M67.1067 19.3355C67.1067 18.5535 66.9996 12.989 67.3374 12.5072C67.4979 12.2779 67.3429 12.4066 67.7242 12.3439L69.2122 12.1816C69.9459 12.1816 70.6818 12.1816 71.4158 12.185C72.4869 12.1887 72.5747 11.9628 73.3406 12.3685C73.3406 15.0647 73.4323 17.545 73.4323 20.161C73.0906 20.2405 72.6985 20.451 72.1356 20.5143C70.8609 20.6579 70.1344 20.6193 68.8485 20.6193C67.831 20.6189 67.1067 20.3595 67.1067 19.3355ZM73.3406 24.9275C73.5011 25.6113 73.5501 26.8349 73.0957 27.3412C72.4699 28.0387 70.771 27.6737 70.1349 27.6751L66.8368 27.4896C65.4121 27.3779 63.2961 27.2197 61.881 27.2197V31.9867L64.7224 32.1701C66.1899 32.2077 69.0273 32.5228 70.4034 32.5398C73.1972 32.5742 75.2016 33.0543 77.3743 31.6198C80.0521 29.8519 79.7577 26.9284 79.7572 23.9186C79.7572 22.9972 79.854 22.3758 79.8489 21.5356L79.8503 14.5648C79.8528 13.9569 79.7542 13.7744 79.7535 13.1949L79.7579 8.79018C79.7616 8.14812 79.6648 7.91882 79.6648 7.32446L67.3151 7.26025C65.4119 7.52097 63.2316 7.23549 61.713 9.5423C60.4231 11.5013 60.9333 14.4653 60.7026 16.5077C60.5192 18.1497 60.6941 21.8718 61.7496 23.4098L62.2569 24.0027C62.9906 24.7063 63.5433 24.975 64.6664 25.26C65.868 25.565 67.7606 25.6017 68.9801 25.4265L73.3406 24.9275Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M27.1361 19.9768V13.5597C27.1361 13.1383 27.2324 12.7342 27.39 12.4382C27.902 11.4751 29.678 11.9209 30.9816 11.8129C33.3065 11.6205 34.195 11.479 34.195 14.0181C34.195 15.0126 34.2934 20.4038 34.0836 20.9661C33.985 21.23 33.8369 21.2898 33.5193 21.4102C32.3358 21.859 28.3828 21.7932 27.7951 21.5187C27.4163 21.3414 27.1361 20.5928 27.1361 19.9773V19.9768ZM32.3615 26.6693C35.6809 26.6693 39.4377 26.4492 40.2831 22.4896C40.6394 20.82 40.5209 15.4059 40.5209 13.468C40.5209 11.4299 40.2457 9.75487 38.9398 8.44829C37.5147 7.02339 35.9607 6.9562 33.7367 6.95895C32.8965 6.9601 32.4193 6.86265 31.6289 6.86723C27.1366 6.89016 23.2604 6.20958 21.3897 9.92272C21.092 10.5121 20.9254 11.159 20.9013 11.8188V18.8775C20.9013 21.5159 20.4668 24.6077 23.4677 25.9372C24.5684 26.4244 25.3313 26.4834 26.4968 26.4836C27.0274 26.4836 27.121 26.5673 27.5908 26.5813C29.2471 26.6308 30.6793 26.67 32.3604 26.67L32.3615 26.6693Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 25.8443C0 26.0555 0.0639761 26.1194 0.275166 26.1194H6.32561V18.6934C7.07108 18.6934 8.66109 18.5489 9.02385 18.9291C9.11396 19.0236 9.11557 19.0566 9.16854 19.1509C9.8693 20.412 12.4724 25.1674 13.1098 26.1192H19.4354C19.3586 25.7902 19.0342 25.2802 18.8569 24.9557C18.6276 24.5375 18.4442 24.1403 18.2442 23.7349C18.0443 23.3295 17.8503 23.0447 17.6322 22.6051L14.8312 17.2469C14.7253 17.0557 14.5962 16.85 14.5049 16.6466C14.2614 16.1045 15.0305 15.23 15.5249 14.3244L18.8218 8.54596C19.0312 8.1715 19.3412 7.73032 19.4354 7.32582H13.0181C12.9197 7.74774 9.86012 12.5654 9.26989 13.57C9.14859 13.7764 9.16441 13.9264 8.89268 13.9264H6.32561V1.55031C6.32561 1.33912 6.26187 1.27515 6.05045 1.27515H0V25.8443Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M87.4588 20.1603C87.4588 19.0936 88.1846 18.6928 89.2015 18.6928H93.4189C94.0566 18.6928 93.8775 19.782 93.8775 20.5272C93.8775 20.7953 93.8106 20.7219 93.6239 20.8237C93.6161 20.828 93.2504 20.9571 93.2171 20.9668C92.8667 21.0695 92.6308 21.1165 92.2511 21.1924C90.859 21.471 90.5083 21.4447 89.1096 21.4447C88.1846 21.4447 87.4586 21.0918 87.4586 20.1605L87.4588 20.1603ZM82.8748 7.32587C82.8748 8.94775 83.0582 10.3999 83.0582 12.0014C85.3396 12.0014 87.6143 11.7327 89.9374 11.7294C90.642 11.7294 91.7775 11.5176 92.742 11.6689C93.4352 11.7776 93.3503 11.8021 93.7847 12.0931C93.7847 12.8794 93.8764 13.446 93.8764 14.111L91.4916 14.2027C87.731 14.2027 84.0562 13.4187 82.0646 16.4185C81.1426 17.8067 81.224 19.8439 81.224 21.5368C81.224 23.7898 82.678 25.5981 84.7072 26.2128C87.36 27.0168 90.1071 26.3401 92.4882 25.3752C92.9885 25.1725 93.4833 24.818 93.9669 24.5621C94.1593 24.8494 94.2511 24.95 94.3538 25.3671C94.4201 25.6364 94.4301 26.1206 94.792 26.1206H100.018V11.726C100.018 10.252 98.972 8.72028 97.7457 7.94729C94.1609 5.68817 86.7711 7.32564 82.8741 7.32564L82.8748 7.32587Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M55.2181 16.3701C55.3437 17.521 55.2385 22.2404 55.2385 24.0108C55.2385 24.6047 55.4634 26.1936 54.7446 25.8478C54.501 25.6756 54.5822 25.2096 54.584 24.7446C54.5937 22.6809 54.4832 17.8347 54.5999 16.4419L55.2181 16.3701ZM46.0186 7.14244C46.0186 6.89915 46.0596 6.76959 46.1397 6.60335L46.5487 6.62238C46.6278 6.76822 46.6778 6.90121 46.6778 7.14244L46.5847 25.8767L46.1032 25.8512C45.8895 25.2491 46.0186 9.19816 46.0186 7.14175V7.14244ZM53.468 7.93974C55.8904 7.44558 57.8477 9.12868 58.2355 11.0737C58.7175 13.4949 57.0007 15.4921 55.102 15.8652C52.6563 16.3468 50.7058 14.6994 50.3025 12.7556C49.7785 10.2298 51.4676 8.34859 53.4669 7.9402L53.468 7.93974ZM54.7292 6.59968L55.1071 6.60128C55.2759 6.93011 55.2378 6.92231 55.2378 7.40087C54.9257 7.42885 54.584 7.46072 54.584 7.14175C54.584 6.86659 54.6212 6.78633 54.7292 6.59968ZM43.4545 26.0608C43.6721 25.748 43.6081 25.421 44.1883 25.4217C44.7684 25.4224 44.6927 25.7446 44.922 26.0746L56.4008 26.1204C56.4772 25.6728 56.574 25.4525 57.0371 25.4272C57.5237 25.4011 57.6884 25.6935 57.7709 26.1204H59.3153V10.5343C59.3153 9.25801 59.3052 8.77143 58.742 7.9911C58.2999 7.37863 57.7466 6.87049 57.0131 6.60266C56.2724 6.33231 55.0044 6.34928 53.9984 6.42724L52.8977 6.42082H47.2139C45.2937 6.32405 43.6721 6.29699 42.5231 7.95212C42.0711 8.60335 41.8904 9.34767 41.8961 10.4421V26.1193C42.405 26.1193 42.9755 26.1794 43.4545 26.0608Z"
        fill="#F6A129"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.0844 13.6389C52.9913 13.7017 53.5822 14.4341 54.8947 14.1718C56.2153 13.9076 56.2946 12.951 56.8521 12.7194C56.7099 13.9276 55.2011 15.2417 53.2018 14.4456C52.8106 14.2894 52.2084 13.9952 52.0844 13.6389ZM54.1314 11.4951C54.2976 11.6497 54.2073 11.3426 54.3625 11.8311C54.4214 12.0166 54.4948 12.7198 54.3639 12.9583L54.1314 11.4951ZM51.5531 10.9638C51.5476 9.76089 54.0215 9.58478 53.8839 10.6907C53.337 10.6563 53.191 10.3342 52.5946 10.4993C52.1382 10.6256 51.9016 10.9547 51.5531 10.9638ZM54.1529 10.64C54.1694 10.015 54.6333 9.64967 55.2057 9.5458C55.6413 9.46669 56.4735 9.53089 56.548 10.183C55.9144 10.2603 55.7895 9.85674 55.1513 10.1266C54.6576 10.3355 54.7734 10.5774 54.1529 10.64ZM53.468 7.94043C51.4684 8.3486 49.7794 10.2298 50.3036 12.7558C50.7069 14.6997 52.6574 16.347 55.1032 15.8655C57.0009 15.4917 58.7177 13.4951 58.2357 11.0746C57.8484 9.12961 55.8906 7.44651 53.4684 7.94066L53.468 7.94043Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M47.2134 6.42077L48.4053 6.42055V2.19193H52.8974V6.42077L53.9976 6.42719V1H47.2134V6.42077Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M46.0186 7.14241C46.0186 9.19882 45.8895 25.2497 46.1032 25.8519L46.5848 25.8773L46.6779 7.1431C46.6779 6.90187 46.6281 6.76887 46.5488 6.62304L46.1399 6.604C46.0597 6.76956 46.0186 6.89912 46.0186 7.14241Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M94.1514 36.9368H93.3259V35.9279C94.1544 35.9279 94.7017 35.8114 94.7017 36.5699C94.7017 36.8517 94.433 36.9368 94.1514 36.9368ZM92.4087 38.863H93.3259V37.7623H93.968C94.0553 38.0895 94.4316 38.5261 94.61 38.863H95.7107L94.8852 37.6706C95.2979 37.1058 95.619 37.4486 95.619 36.203C95.619 34.8079 93.3204 35.1024 92.4103 35.1024V38.8611L92.4087 38.863Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M82.5085 37.0288C82.5085 35.5762 84.8004 35.5842 84.8004 37.0288C84.8004 38.1701 83.4507 38.5771 82.8564 37.8727C82.525 37.7729 82.5085 37.2091 82.5085 37.0288ZM81.5913 37.0288C81.5913 37.7167 82.0543 38.5496 82.6427 38.7977C83.2159 39.0396 83.947 39.0873 84.6126 38.8165C85.4819 38.4627 86.2354 36.8355 85.2391 35.7647C83.9568 34.3861 81.5913 35.2373 81.5913 37.0288Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M48.2228 36.8453C47.5833 36.9943 47.6136 36.937 46.847 36.937V35.928C47.4134 35.928 48.7073 35.759 48.2228 36.8453ZM46.0215 38.8631H46.847C46.847 37.7258 46.7523 37.7625 47.3056 37.7625C47.6021 37.7625 48.0584 38.6178 48.2228 38.8631H49.2318C48.9969 38.419 48.683 37.9918 48.4063 37.579C49.672 37.2841 49.4998 35.1025 47.7642 35.1025H46.0215V38.8613V38.8631Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M71.7824 37.9453C71.7824 36.9095 72.4451 37.7494 72.6996 38.1287C72.2362 38.3741 71.7824 38.3766 71.7824 37.9453ZM72.1493 36.1108C72.1493 35.8657 72.2816 35.8398 72.4244 35.7439C72.8956 35.8696 73.0234 36.2025 72.3327 36.5694C72.2715 36.4548 72.1493 36.2663 72.1493 36.1108ZM71.3238 36.0191C71.3238 36.7554 71.4613 36.3949 71.5072 36.9363C70.4613 37.2156 70.8438 38.8625 71.5072 38.8625H72.6996C73.1554 38.8625 72.8294 38.7655 73.1894 38.7334C73.7626 38.6825 72.969 38.8625 73.8913 38.8625C74.1894 38.8625 73.9601 38.7831 74.3499 38.679C74.1548 38.3103 74.0763 38.3289 73.7996 38.037C73.977 37.7721 74.177 37.5598 74.2582 37.2115C74.0181 37.051 73.8782 36.9822 73.6161 36.8446C73.5015 37.062 73.4036 37.2184 73.341 37.4867L72.8617 37.0491C74.3137 36.2466 73.3749 35.103 72.6994 35.103C72.105 35.103 71.3235 35.1863 71.3235 36.0202L71.3238 36.0191Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M60.5981 38.8622H63.5319V38.2201H61.514V37.4863C63.5089 37.4863 63.2567 37.6874 63.2567 36.6608H61.514V35.9271H63.5319V35.1016H60.5981V38.8622Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M87.2758 37.3037C87.2758 38.2643 87.625 38.6319 88.0985 38.8032C88.5915 38.9813 89.5477 39.0189 89.8825 38.8362C90.0864 38.725 90.3202 38.4998 90.4179 38.3375C90.8052 37.6931 90.6676 36.046 90.6676 35.1042H89.7504C89.7504 36.7752 90.0593 38.2212 89.0166 38.2212C87.8777 38.2212 88.1911 36.7536 88.1911 35.1042H87.2739V37.3056L87.2758 37.3037Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.6219 36.2955C52.7062 36.6573 52.9041 36.9419 52.9887 37.3044H52.1632C52.2071 37.1148 52.2666 36.9291 52.3412 36.7493C52.435 36.5138 52.426 36.4395 52.6216 36.2962L52.6219 36.2955ZM50.6037 38.863H51.521C51.7422 37.9137 51.7629 38.1292 53.172 38.1292C53.4393 38.1292 53.3671 38.1962 53.4682 38.3831C53.5008 38.4429 53.6019 38.7408 53.6303 38.8628H54.5476L52.9883 35.104H52.1628C52.0768 35.4725 50.7473 38.2487 50.6035 38.8628"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M97.1762 36.2953C97.1762 37.758 98.9522 37.0428 99.1941 37.9463C98.7598 38.5948 97.9416 38.0293 97.5431 37.7629C97.3422 38.0628 97.1799 38.0483 97.0845 38.4049C97.2817 38.6996 97.5678 38.8193 97.9205 38.8635H99.3764C99.5981 38.8635 100.018 38.4437 100.018 38.2215V37.5512C99.9879 37.1928 99.835 36.9844 99.1961 36.7507C98.9088 36.6459 98.0932 36.5634 98.0932 36.2036C98.0932 35.4636 99.2826 36.1389 99.5607 36.2036C99.7212 35.9649 99.8985 35.8163 99.9276 35.4698C99.7501 35.4226 98.3977 34.6338 97.5149 35.4407C97.2789 35.6565 97.1773 35.878 97.1773 36.2951L97.1762 36.2953Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.6003 16.4419C54.4836 17.8347 54.5941 22.682 54.5845 24.7446C54.5824 25.2096 54.5013 25.6756 54.745 25.8478C55.4641 26.1936 55.2392 24.6047 55.2392 24.0108C55.2392 22.2399 55.3442 17.5215 55.2185 16.3701L54.6003 16.4419Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.6557 38.8622H57.6646C57.8848 37.917 59.0253 35.7491 59.0404 35.1034C58.0879 35.1034 58.1601 34.8626 57.4472 36.994C57.3291 37.3471 57.3592 37.4916 57.1152 37.6705L56.198 35.1022H55.2808C55.339 35.8035 56.6048 38.2451 56.6566 38.861"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M77.1909 35.9283H78.3826V38.862H79.2994V35.9283H80.3996V35.1033H77.1909V35.9283Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M41.2539 35.9283H42.3541V38.862H43.2709V35.9283H44.4628V35.1033H41.2539V35.9283Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M65.182 38.862H67.9323V38.1283H66.0979V35.3782C66.0979 35.167 66.0341 35.103 65.8227 35.103H65.1807L65.182 38.862Z"
        fill="#1C1C1C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M43.4549 26.0605C43.4549 26.9004 43.3402 27.7731 44.1887 27.7731C45.0341 27.7731 44.9225 26.9107 44.9225 26.0742C44.6945 25.7442 44.7672 25.4221 44.1887 25.4214C43.6101 25.4207 43.673 25.7477 43.4549 26.0605Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.4002 26.1194C56.3332 26.9389 56.2873 27.7096 57.034 27.7704C57.43 27.8025 57.679 27.5667 57.7398 27.295C57.793 27.0563 57.7521 26.4207 57.77 26.1194C57.6877 25.6924 57.5231 25.4 57.0363 25.4262C56.5733 25.4514 56.4763 25.6718 56.3999 26.1194H56.4002Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.0845 13.6388C52.2085 13.9951 52.8107 14.2893 53.2019 14.4454C55.2003 15.2423 56.7103 13.9274 56.8522 12.7192C56.2947 12.9508 56.2154 13.9075 54.8948 14.1717C53.5823 14.434 52.9916 13.7016 52.0845 13.6388Z"
        fill="#FEFEFE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.1533 10.64C54.7738 10.5774 54.6578 10.3355 55.1517 10.1266C55.7899 9.85674 55.9148 10.2603 56.5484 10.183C56.4739 9.5309 55.6415 9.4667 55.2061 9.54581C54.6328 9.64968 54.1698 10.015 54.1533 10.64Z"
        fill="#FEFEFE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M51.5532 10.9637C51.9018 10.9545 52.1384 10.6255 52.5947 10.4991C53.1909 10.334 53.3372 10.6562 53.8841 10.6904C54.0217 9.58466 51.548 9.76077 51.5532 10.9637Z"
        fill="#FEFEFE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.585 7.14266C54.585 7.46163 54.9266 7.42952 55.2389 7.40178C55.2389 6.92276 55.2768 6.93101 55.108 6.60219L54.7301 6.60059C54.6221 6.78724 54.585 6.8675 54.585 7.14266Z"
        fill="#E7057F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.3625 11.831C54.2072 11.3426 54.2976 11.6497 54.1313 11.4951L54.3639 12.9583C54.4948 12.7198 54.4214 12.0166 54.3625 11.831Z"
        fill="#FEFEFE"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          fill="white"
          height="38"
          transform="translate(0 1)"
          width="100.018"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C5.38317 0 0 5.38317 0 12C0 18.6168 5.38317 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38317 18.6168 0 12 0ZM5.82593 7.13583C5.73866 7.13583 5.65501 7.10689 5.58592 7.04492C5.43319 6.91051 5.41866 6.68129 5.5532 6.53232C7.31683 4.53587 9.96411 5.73224 9.98957 5.74325C10.175 5.83043 10.2514 6.04492 10.1677 6.22674C10.0841 6.40856 9.86956 6.48864 9.6841 6.40501C9.63683 6.38317 8.98957 6.09233 8.18228 6.09233C7.51321 6.09233 6.73502 6.29226 6.09866 7.01225C6.02592 7.09233 5.92773 7.13583 5.82593 7.13583ZM7.92048 7.67401C8.90592 7.67401 9.70956 8.47408 9.70956 9.45952C9.70956 10.4487 8.90592 11.2486 7.92048 11.2486C6.93501 11.2486 6.13501 10.4487 6.13501 9.45952C6.13501 8.47408 6.93501 7.67401 7.92048 7.67401ZM11.6477 19.016C11.6041 19.0341 11.5605 19.0414 11.5168 19.0414C11.3714 19.0414 11.2332 18.9577 11.1786 18.8123C11.1205 18.6777 9.85139 15.6268 6.66593 16.7504C6.47683 16.8196 6.26955 16.7177 6.2041 16.5286C6.13501 16.3395 6.23684 16.1323 6.42591 16.0668C10.2768 14.7069 11.8368 18.5067 11.855 18.5431C11.9277 18.7322 11.8368 18.9432 11.6477 19.016ZM16.0768 11.2486C15.0914 11.2486 14.2914 10.4487 14.2914 9.45952C14.2914 8.47408 15.0914 7.67401 16.0768 7.67401C17.0659 7.67401 17.8659 8.47408 17.8659 9.45952C17.8659 10.4487 17.0659 11.2486 16.0768 11.2486ZM18.415 7.04492C18.3459 7.10689 18.2587 7.13583 18.175 7.13583C18.0732 7.13583 17.975 7.09233 17.9023 7.01225C16.4986 5.42685 14.4041 6.36506 14.3132 6.40501C14.1314 6.48864 13.9168 6.40856 13.8332 6.22674C13.7496 6.04492 13.8259 5.8267 14.0077 5.74325C14.0368 5.73224 16.6841 4.53587 18.4477 6.53232C18.5787 6.68129 18.5641 6.91051 18.415 7.04492Z"
      fill="#DFDFDF"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.07616 10.5823C4.95131 10.5823 3.86946 10.2106 2.98391 9.55832C2.7939 9.41836 2.92581 9.15131 3.15802 9.19337C3.38344 9.23419 3.53269 8.96638 3.37938 8.79615L1.87673 7.12767C1.27238 6.45663 0.158432 6.85835 0.12148 7.76066L0.0502779 9.49933C0.0339413 9.89825 0.532516 10.0911 0.788826 9.78493C0.957187 9.58385 1.26657 9.58628 1.44793 9.77572C2.65181 11.0332 4.32041 11.7669 6.07616 11.7669C7.27942 11.7669 8.43439 11.4369 9.44986 10.8102C9.72772 10.6387 9.77893 10.2651 9.58191 10.0047C9.38783 9.74826 9.0253 9.69815 8.74733 9.85998C7.94172 10.329 7.03143 10.5823 6.07616 10.5823Z"
      fill="#ED017F"
    />
    <path
      d="M10.7744 1.98194C10.6061 2.18302 10.2967 2.18059 10.1153 1.99115C8.91144 0.733627 7.24284 0 5.4871 0C4.28202 0 3.12537 0.331018 2.1088 0.95956C1.83361 1.12971 1.78286 1.49982 1.97809 1.75782C2.17293 2.01531 2.53839 2.06229 2.81563 1.89676C3.62132 1.41572 4.5317 1.17145 5.4871 1.17145C6.61161 1.17145 7.69316 1.54289 8.57856 2.19482C8.76883 2.33492 8.63677 2.60228 8.40427 2.56017C8.17876 2.51933 8.02928 2.78706 8.18237 2.9576L9.68469 4.63116C10.2888 5.30417 11.4051 4.90265 11.4421 3.99902L11.513 2.26754C11.5293 1.86863 11.0307 1.67582 10.7744 1.98194Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C11.6355 15 15 11.6355 15 7.5C15 3.3645 11.6355 0 7.5 0ZM7.5 13.4041C4.24449 13.4041 1.59572 10.7557 1.59572 7.5C1.59572 4.24434 4.24449 1.59587 7.5 1.59587C10.7555 1.59587 13.4043 4.24434 13.4043 7.5C13.4043 10.7557 10.7555 13.4041 7.5 13.4041Z"
      fill="#666565"
    />
    <path
      d="M11.4083 7.26779H8.03781V3.21519C8.03781 2.87416 7.76131 2.59766 7.42027 2.59766C7.07924 2.59766 6.80273 2.87416 6.80273 3.21519V7.88532C6.80273 8.22636 7.07924 8.50286 7.42027 8.50286H11.4083C11.7494 8.50286 12.0259 8.22636 12.0259 7.88532C12.0259 7.54429 11.7494 7.26779 11.4083 7.26779Z"
      fill="#666565"
    />
  </svg>
);

export default SVG;

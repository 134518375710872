/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.04762 0.833984H9.68879C10.0435 0.833984 10.3867 0.959669 10.6575 1.18872L14.4687 4.41237C14.8057 4.69738 15 5.11631 15 5.55763V15.9034C15 17.3955 14.983 17.5007 13.4524 17.5007H4.04762C2.51704 17.5007 2.5 17.3955 2.5 15.9034V2.43121C2.5 0.939088 2.51704 0.833984 4.04762 0.833984ZM5.83333 10.834C5.83333 10.3737 6.20643 10.0007 6.66667 10.0007H12.5C12.9602 10.0007 13.3333 10.3737 13.3333 10.834C13.3333 11.2942 12.9602 11.6673 12.5 11.6673H6.66667C6.20643 11.6673 5.83333 11.2942 5.83333 10.834ZM6.66667 13.334C6.20643 13.334 5.83333 13.7071 5.83333 14.1673C5.83333 14.6276 6.20643 15.0007 6.66667 15.0007H9.16667C9.6269 15.0007 10 14.6276 10 14.1673C10 13.7071 9.6269 13.334 9.16667 13.334H6.66667Z"
      fill="#ED017F"
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M5.71461 2.5H12.1891C12.5438 2.5 12.887 2.62568 13.1578 2.85474L16.969 6.07839C17.306 6.36339 17.5003 6.78232 17.5003 7.22365V17.5694C17.5003 19.0616 17.4833 19.1667 15.9527 19.1667H5.71461C4.18403 19.1667 4.16699 19.0616 4.16699 17.5694V4.09722C4.16699 2.6051 4.18403 2.5 5.71461 2.5ZM5.83366 10.8333C5.83366 10.3731 6.20675 10 6.66699 10H12.5003C12.9606 10 13.3337 10.3731 13.3337 10.8333C13.3337 11.2936 12.9606 11.6667 12.5003 11.6667H6.66699C6.20675 11.6667 5.83366 11.2936 5.83366 10.8333ZM6.66699 13.3333C6.20675 13.3333 5.83366 13.7064 5.83366 14.1667C5.83366 14.6269 6.20675 15 6.66699 15H9.16699C9.62723 15 10.0003 14.6269 10.0003 14.1667C10.0003 13.7064 9.62723 13.3333 9.16699 13.3333H6.66699Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

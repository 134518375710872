/**
 * Class to handle the signup form
 */
class SignupForm {
  firstname = "";
  lastname = "";
  email = "";
  password = "";
  phone_number = "";
  confirm_password = "";
}

export default SignupForm;

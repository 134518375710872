/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893L20.2992 8.885C20.858 9.44709 21.1716 10.2074 21.1716 11C21.1716 11.7926 20.858 12.5529 20.2992 13.115L19.59 12.41L20.2971 13.1171L13.1275 20.2867C13.1274 20.2868 13.1276 20.2866 13.1275 20.2867C12.849 20.5655 12.5178 20.787 12.1538 20.9379C11.7896 21.0889 11.3992 21.1666 11.005 21.1666C10.6108 21.1666 10.2204 21.0889 9.85618 20.9379C9.49208 20.787 9.16129 20.5658 8.88271 20.2869C8.88264 20.2869 8.88278 20.287 8.88271 20.2869L0.293305 11.7075C0.105517 11.5199 0 11.2654 0 11V1ZM18.8808 11.705C18.8811 11.7047 18.8814 11.7044 18.8817 11.7041C19.0674 11.5168 19.1716 11.2638 19.1716 11C19.1716 10.7362 19.0674 10.4832 18.8817 10.2959C18.8814 10.2956 18.8811 10.2953 18.8808 10.295L10.5858 2H2V10.5854L10.2967 18.8725L10.2975 18.8733C10.3904 18.9663 10.5007 19.04 10.6221 19.0903C10.7435 19.1407 10.8736 19.1666 11.005 19.1666C11.1364 19.1666 11.2665 19.1407 11.3879 19.0903C11.5093 19.04 11.6196 18.9663 11.7125 18.8733L18.8808 11.705Z"
      fill="#989898"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

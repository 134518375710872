/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M14.1717 16.6566C16.9746 15.7428 19 13.1079 19 10C19 6.13401 15.866 3 12 3C8.13401 3 5 6.13401 5 10C5 13.1079 7.02542 15.7428 9.82829 16.6566L12 21L14.1717 16.6566ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
      fill="#979797"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

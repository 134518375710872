/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-types */

/**
 * class that holds the Epin form for purchase
 */
class BuyEPinForm {
  provider = "";
  operator = "";
  productCode = "";
  serviceOption = "";
  amountTotal = 0.0;
  quantity = 1.0;
}

export default BuyEPinForm;

class BuyCableTvForm {
  amount = 0;
  cardNumber = "";
  userEmail = "";
  phoneNumber = "";
  operator = "";
  productCode = null;
  policyAgree = false;
}

export default BuyCableTvForm;

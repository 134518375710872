/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.55365 14.2686C4.22821 14.5941 4.22821 15.1217 4.55365 15.4472C4.87909 15.7726 5.40672 15.7726 5.73216 15.4472L10.0001 11.1792L15.1603 16.3393C15.4857 16.6648 16.0134 16.6648 16.3388 16.3393C16.6642 16.0139 16.6642 15.4862 16.3388 15.1608L11.1787 10.0007L16.3388 4.84056C16.6642 4.51512 16.6642 3.98748 16.3388 3.66205C16.0133 3.33661 15.4857 3.33661 15.1603 3.66205L10.0001 8.82215L5.7322 4.55421C5.40677 4.22877 4.87913 4.22877 4.55369 4.5542C4.22826 4.87964 4.22826 5.40728 4.55369 5.73272L8.82164 10.0007L4.55365 14.2686Z"
      fill="white"
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M14.9999 5.00065H12.4999C12.4999 5.00065 11.6666 4.85767 11.6666 4.16732C11.6666 3.47696 12.4999 3.33398 12.4999 3.33398H16.6666V7.50065C16.6666 7.50065 16.6666 8.33398 15.8332 8.33398C14.9999 8.33398 14.9999 7.50065 14.9999 7.50065V5.00065ZM7.49992 15.0007H4.99992V12.5007C4.99992 12.5007 4.99992 11.6674 4.16659 11.6674C3.33325 11.6674 3.33325 12.5007 3.33325 12.5007V16.6674H7.49992C7.49992 16.6674 8.33325 16.5244 8.33325 15.834C8.33325 15.1437 7.49992 15.0007 7.49992 15.0007ZM14.9999 12.5007V15.0007H12.4999C12.4999 15.0007 11.6666 15.0007 11.6666 15.834C11.6666 16.6674 12.4999 16.6674 12.4999 16.6674H16.6666V12.5007C16.6666 12.5007 16.5236 11.6674 15.8332 11.6674C15.1429 11.6674 14.9999 12.5007 14.9999 12.5007ZM4.99992 7.50065V5.00065H7.49992C7.49992 5.00065 8.33325 5.00065 8.33325 4.16732C8.33325 3.33398 7.49992 3.33398 7.49992 3.33398H3.33325V7.50065C3.33325 7.50065 3.47623 8.33398 4.16659 8.33398C4.85694 8.33398 4.99992 7.50065 4.99992 7.50065Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_16974_65083)">
      <path
        clipRule="evenodd"
        d="M13.4154 1.75C13.7375 1.75 13.9987 2.01117 13.9987 2.33333V5.83333C13.9987 6.1555 13.7375 6.41667 13.4154 6.41667H9.91536C9.5932 6.41667 9.33203 6.1555 9.33203 5.83333C9.33203 5.51117 9.5932 5.25 9.91536 5.25H12.832V2.33333C12.832 2.01117 13.0932 1.75 13.4154 1.75Z"
        fill="#ED017F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.65668 2.38089C6.65488 2.23879 5.63404 2.42614 4.74799 2.9147C3.86194 3.40326 3.15868 4.16657 2.74418 5.08959C2.32968 6.01262 2.22641 7.04535 2.44992 8.03218C2.67343 9.019 3.21162 9.90645 3.98338 10.5608C4.75515 11.2151 5.71867 11.6009 6.72877 11.66C7.73886 11.7191 8.7408 11.4483 9.58359 10.8884C10.4264 10.3285 11.0644 9.50989 11.4014 8.55585C11.5088 8.25209 11.842 8.09284 12.1458 8.20016C12.4495 8.30747 12.6088 8.64072 12.5015 8.94449C12.0802 10.137 11.2827 11.1603 10.2292 11.8602C9.17567 12.56 7.92325 12.8986 6.66063 12.8247C5.39802 12.7508 4.19361 12.2686 3.2289 11.4507C2.2642 10.6327 1.59147 9.52343 1.31208 8.2899C1.03269 7.05637 1.16178 5.76544 1.6799 4.61166C2.19802 3.45788 3.0771 2.50375 4.18466 1.89305C5.29223 1.28235 6.56828 1.04817 7.82052 1.22579C9.06977 1.40298 10.2278 1.98044 11.121 2.87142L13.8155 5.40883C14.0501 5.62969 14.0612 5.99887 13.8403 6.23342C13.6194 6.46796 13.2503 6.47905 13.0157 6.25818L10.3149 3.71485C10.3106 3.7108 10.3064 3.70669 10.3022 3.70252C9.58694 2.98683 8.65847 2.52299 7.65668 2.38089Z"
        fill="#ED017F"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_16974_65083">
        <rect fill="white" height="14" width="14" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="60"
    viewBox="0 0 60 60"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.25 50.9627L36.3937 46.1064L33.75 48.7502L41.25 56.2502L56.25 41.2502L53.6063 38.6064L41.25 50.9627Z"
      fill="#ED017F"
    />
    <path
      d="M28.125 52.5006H15V7.50064H30V18.7506C30.003 19.7443 30.399 20.6964 31.1016 21.399C31.8042 22.1016 32.7564 22.4977 33.75 22.5006H45V33.7506H48.75V18.7506C48.7566 18.5042 48.7098 18.2593 48.6127 18.0327C48.5156 17.8061 48.3705 17.6033 48.1875 17.4381L35.0625 4.31314C34.8974 4.13003 34.6946 3.98492 34.468 3.88781C34.2414 3.79069 33.9964 3.7439 33.75 3.75064H15C14.0064 3.75361 13.0542 4.14965 12.3516 4.85227C11.649 5.55488 11.253 6.50699 11.25 7.50064V52.5006C11.253 53.4943 11.649 54.4464 12.3516 55.149C13.0542 55.8516 14.0064 56.2477 15 56.2506H28.125V52.5006ZM33.75 8.25064L44.25 18.7506H33.75V8.25064Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

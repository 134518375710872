/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 100 40"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.5185 38.7429H39.2052C40.1997 38.7429 40.7407 38.1462 40.7407 37.2631V34.6216C40.7407 33.7385 40.1997 33.1418 39.2052 33.1418H37.5185C36.5239 33.1418 35.9829 33.7385 35.9829 34.6216V37.2631C35.9829 38.1462 36.5239 38.7429 37.5185 38.7429ZM37.7969 34.0567H38.9267C39.4757 34.0567 39.7064 34.2159 39.7064 34.741V37.0403C39.7064 37.5654 39.4757 37.8041 38.9267 37.8041H37.7969C37.2479 37.8041 37.0172 37.5654 37.0172 37.0403V34.741C37.0172 34.2159 37.2479 34.0567 37.7969 34.0567Z"
      fill="black"
    />
    <path
      d="M41.7096 38.727H42.6961V35.29C43.0542 35.1865 43.4599 35.0115 43.802 35.0115H44.0248C44.4942 35.0115 44.6852 35.1308 44.6852 35.6162V38.727H45.6717V35.457C45.6717 34.375 45.1785 34.1443 44.2078 34.1443H43.9612C43.5395 34.1443 43.0462 34.367 42.6961 34.5421V34.192H41.7096V38.727Z"
      fill="black"
    />
    <path d="M47.599 32.8792H46.6124V38.727H47.599V32.8792Z" fill="black" />
    <path
      d="M49.588 34.192H48.6015V38.727H49.588V34.192ZM48.5617 32.927C48.5617 33.2213 48.8004 33.4441 49.0948 33.4441C49.3971 33.4441 49.6358 33.2213 49.6358 32.927C49.6358 32.6246 49.3971 32.4019 49.0948 32.4019C48.8004 32.4019 48.5617 32.6246 48.5617 32.927Z"
      fill="black"
    />
    <path
      d="M50.5826 38.727H51.5691V35.29C51.9272 35.1865 52.3329 35.0115 52.675 35.0115H52.8978C53.3672 35.0115 53.5582 35.1308 53.5582 35.6162V38.727H54.5448V35.457C54.5448 34.375 54.0515 34.1443 53.0808 34.1443H52.8342C52.4125 34.1443 51.9192 34.367 51.5691 34.5421V34.192H50.5826V38.727Z"
      fill="black"
    />
    <path
      d="M59.1214 37.9394H57.0528C56.4879 37.9394 56.3925 37.9234 56.3925 37.287V37.0005H59.3681C59.4397 37.0005 59.4476 36.9767 59.4476 36.9051V35.6162C59.4476 34.733 58.9464 34.1761 57.9439 34.1761H56.9812C55.9867 34.1761 55.4059 34.733 55.4059 35.6162V37.3983C55.4059 38.4963 55.9071 38.7668 56.9016 38.7588C58.1269 38.7588 59.1055 38.6554 59.1055 38.6554L59.1214 37.9394ZM58.4611 36.2049L56.3925 36.2208V35.8071C56.3925 35.0115 56.6789 34.9797 57.196 34.9797H57.737C58.2462 34.9797 58.4611 35.0354 58.4611 35.8071V36.2049Z"
      fill="black"
    />
    <path
      d="M66.0127 33.2054C66.0127 33.2054 64.8988 33.1179 63.6178 33.1179C62.3289 33.11 61.8834 33.4839 61.8834 34.4784V34.9478C61.8834 35.7991 62.3051 35.8787 63.1564 36.0299C63.4189 36.0776 64.6124 36.2924 64.9943 36.372C65.2727 36.4197 65.3284 36.5709 65.3284 36.7778V37.1915C65.3284 37.7007 65.1454 37.8041 64.5089 37.8041H61.8914L61.8834 38.6713C61.8834 38.6713 63.3473 38.7588 64.6283 38.7588C65.9251 38.7668 66.3468 38.3531 66.3468 37.3586V36.6345C66.3468 35.9264 66.1161 35.6241 65.3284 35.4729C64.9545 35.4013 63.3235 35.099 63.3235 35.099C63.0132 35.0354 62.9257 34.9797 62.9257 34.741V34.5739C62.9257 34.1045 63.045 34.0727 63.586 34.0727H66.0047L66.0127 33.2054Z"
      fill="black"
    />
    <path
      d="M67.1321 38.727H68.1186V35.2979C68.4766 35.1945 68.8824 35.0194 69.2245 35.0194H69.4473C69.9167 35.0194 70.1077 35.1388 70.1077 35.6321V38.727H71.0942V35.4729C71.0942 34.3829 70.6009 34.1602 69.6303 34.1522H69.3837C68.962 34.1522 68.4687 34.375 68.1186 34.55V32.8792H67.1321V38.727Z"
      fill="black"
    />
    <path
      d="M74.5332 34.1363H73.4511C72.4566 34.1363 71.9554 34.558 71.9554 35.6559V37.2472C71.9554 38.3451 72.4566 38.7668 73.4511 38.7668H74.5332C75.5356 38.7668 76.0369 38.3451 76.0369 37.2472V35.6559C76.0369 34.558 75.5356 34.1363 74.5332 34.1363ZM74.382 37.9075H73.6023C73.1329 37.9075 72.9419 37.7484 72.9419 37.2551V35.6082C72.9419 35.1229 73.1329 34.9637 73.6023 34.9637H74.382C74.8594 34.9637 75.0503 35.1229 75.0503 35.6082V37.2551C75.0503 37.7484 74.8594 37.9075 74.382 37.9075Z"
      fill="black"
    />
    <path
      d="M77.9318 38.361C78.2818 38.5361 78.7751 38.7668 79.1968 38.7668H79.4594C80.438 38.7588 80.9312 38.5281 80.9312 37.4461V35.4729C80.9312 34.3829 80.438 34.1602 79.4594 34.1522H79.1968C78.7751 34.1522 78.2818 34.375 77.9318 34.55V34.192H76.9452V40H77.9318V38.361ZM77.9318 35.2979C78.2898 35.1945 78.6956 35.0194 79.0377 35.0194H79.2764C79.7537 35.0194 79.9447 35.1388 79.9447 35.6321V37.2949C79.9447 37.7802 79.7537 37.8996 79.2764 37.8996H79.0377C78.6956 37.8996 78.2898 37.7245 77.9318 37.6211V35.2979Z"
      fill="black"
    />
    <path
      d="M82.8267 38.361C83.1768 38.5361 83.67 38.7668 84.0917 38.7668H84.3543C85.3329 38.7588 85.8262 38.5281 85.8262 37.4461V35.4729C85.8262 34.3829 85.3329 34.1602 84.3543 34.1522H84.0917C83.67 34.1522 83.1768 34.375 82.8267 34.55V34.192H81.8401V40H82.8267V38.361ZM82.8267 35.2979C83.1847 35.1945 83.5905 35.0194 83.9326 35.0194H84.1713C84.6486 35.0194 84.8396 35.1388 84.8396 35.6321V37.2949C84.8396 37.7802 84.6486 37.8996 84.1713 37.8996H83.9326C83.5905 37.8996 83.1847 37.7245 82.8267 37.6211V35.2979Z"
      fill="black"
    />
    <path
      d="M87.7296 34.192H86.743V38.727H87.7296V34.192ZM86.7032 32.927C86.7032 33.2213 86.9419 33.4441 87.2363 33.4441C87.5386 33.4441 87.7773 33.2213 87.7773 32.927C87.7773 32.6246 87.5386 32.4019 87.2363 32.4019C86.9419 32.4019 86.7032 32.6246 86.7032 32.927Z"
      fill="black"
    />
    <path
      d="M88.7241 38.727H89.7107V35.29C90.0687 35.1865 90.4744 35.0115 90.8166 35.0115H91.0393C91.5088 35.0115 91.6997 35.1308 91.6997 35.6162V38.727H92.6863V35.457C92.6863 34.375 92.193 34.1443 91.2223 34.1443H90.9757C90.554 34.1443 90.0607 34.367 89.7107 34.5421V34.192H88.7241V38.727Z"
      fill="black"
    />
    <path
      d="M97.5255 34.192H96.5389V34.55C96.1888 34.375 95.6956 34.1522 95.2739 34.1522H95.0113C94.0407 34.1602 93.5474 34.3829 93.5474 35.4729V37.1835C93.5474 38.2735 94.0407 38.4963 95.0113 38.5042H95.3137C95.7353 38.5042 96.1888 38.2815 96.5389 38.1064V38.4883C96.5389 38.9816 96.348 39.1407 95.8786 39.1407H93.9691L93.9611 39.8488C93.9611 39.8488 94.8045 39.9682 96.0297 39.9682C97.0242 39.9761 97.5334 39.7136 97.5255 38.6077V34.192ZM94.534 35.6321C94.534 35.1388 94.7249 35.0194 95.1943 35.0194H95.433C95.7831 35.0194 96.1809 35.1945 96.5389 35.2979V37.3983C96.1809 37.5018 95.7831 37.6768 95.433 37.6768H95.1943C94.7249 37.6768 94.534 37.5575 94.534 37.0642V35.6321Z"
      fill="black"
    />
    <path
      d="M6.10576 16.8893V24.1281H0V0H6.10576V12.235H8.89653L12.7341 5.8594H19.1893L13.9555 14.6485L19.1893 24.1281H12.7341L8.7218 16.8893H6.10576Z"
      fill="#E5087F"
    />
    <path
      d="M50.0643 10.5122C48.8313 10.5122 47.4362 10.7424 45.879 11.2027V24.1282H39.7798V5.85953H45.1866L45.5361 7.5825C46.5728 6.8971 47.7074 6.36851 48.9022 6.01424C50.055 5.68202 51.0264 5.51537 51.8166 5.51428H53.3859C54.9662 5.51428 56.2338 5.98547 57.1888 6.92784C58.1438 7.8702 58.6207 9.12253 58.6196 10.6848V24.1282H52.5205V11.7189C52.5243 11.5594 52.4953 11.4008 52.4353 11.2527C52.3753 11.1046 52.2854 10.97 52.1712 10.8572C52.057 10.7444 51.9209 10.6556 51.7709 10.5963C51.621 10.537 51.4604 10.5084 51.299 10.5122H50.0643Z"
      fill="#E5087F"
    />
    <path
      d="M72.3934 22.7487C70.1373 23.208 68.1592 23.4376 66.4591 23.4376H65.2376C63.6552 23.4376 62.3875 22.9669 61.4347 22.0257C60.4819 21.0844 60.005 19.832 60.0039 18.2687V11.3752C60.0039 9.6511 60.4984 8.30106 61.4875 7.32504C62.4765 6.34902 63.8431 5.86046 65.5871 5.85938H78.4992V25.1622C78.4992 26.7234 78.0223 27.9757 77.0684 28.9192C76.1145 29.8626 74.8469 30.3333 73.2655 30.3311C69.1857 30.3076 65.1099 30.0777 61.054 29.6422V25.1622C65.497 25.5053 68.8697 25.6773 71.172 25.6784C71.3335 25.6824 71.4942 25.654 71.6442 25.5948C71.7942 25.5355 71.9305 25.4468 72.0448 25.3339C72.159 25.2211 72.2488 25.0864 72.3088 24.9382C72.3687 24.79 72.3975 24.6313 72.3934 24.4717V22.7487ZM68.2065 18.7849C69.6096 18.7906 71.0106 18.6751 72.3934 18.4397V10.512H67.5092C66.5784 10.512 66.113 10.9718 66.113 11.8914V17.5782C66.1091 17.7377 66.1381 17.8963 66.1981 18.0444C66.2581 18.1925 66.348 18.3271 66.4622 18.4399C66.5764 18.5527 66.7126 18.6415 66.8625 18.7008C67.0124 18.7601 67.173 18.7887 67.3344 18.7849H68.2065Z"
      fill="#E5087F"
    />
    <path
      d="M81.4653 6.20477C85.2859 5.77055 89.1273 5.54005 92.973 5.51428C94.5533 5.51428 95.8209 5.98547 96.7759 6.92784C97.7309 7.8702 98.2078 9.12253 98.2067 10.6848V24.1282H92.8081L92.4603 22.4036C91.4238 23.0904 90.2885 23.6191 89.0926 23.9719C87.942 24.3052 86.9711 24.4718 86.1798 24.4718H84.9584C83.3759 24.4718 82.1082 24.0012 81.1554 23.0599C80.2027 22.1186 79.7257 20.8663 79.7246 19.3029V17.751C79.7246 16.1887 80.2016 14.9363 81.1554 13.994C82.1093 13.0516 83.377 12.5804 84.9584 12.5804H92.1109V11.2027C92.1147 11.0432 92.0857 10.8846 92.0257 10.7364C91.9657 10.5883 91.8758 10.4538 91.7616 10.341C91.6474 10.2281 91.5113 10.1394 91.3613 10.0801C91.2114 10.0208 91.0508 9.99217 90.8894 9.99596C89.3772 9.99596 87.7085 10.0649 85.8831 10.2028C84.0578 10.3407 82.5868 10.4438 81.4703 10.5122L81.4653 6.20477ZM87.9189 19.6465C89.1519 19.6465 90.5476 19.4169 92.1059 18.9577V16.8895H87.0469C86.8854 16.8857 86.7249 16.9143 86.575 16.9736C86.425 17.0329 86.2888 17.1217 86.1746 17.2345C86.0604 17.3473 85.9706 17.4818 85.9106 17.63C85.8506 17.7781 85.8216 17.9367 85.8254 18.0962V18.4398C85.8214 18.5994 85.8502 18.7581 85.9101 18.9063C85.97 19.0546 86.0599 19.1892 86.1741 19.3021C86.2884 19.4149 86.4247 19.5037 86.5747 19.5629C86.7247 19.6221 86.8854 19.6506 87.0469 19.6465H87.9189Z"
      fill="#E5087F"
    />
    <path
      d="M20.9874 7.22095H35.1831L36.9917 24.1655H19.1787L20.9874 7.22095Z"
      fill="#ED3618"
    />
    <path
      d="M22.408 7.22095H36.6037L38.4124 24.1655H20.5996L22.408 7.22095Z"
      fill="#FBA100"
    />
    <path
      d="M29.5063 17.3993C27.4121 17.3993 25.7085 15.6957 25.7085 13.6014V10.3108C25.7085 10.0306 25.9355 9.80334 26.2157 9.80334C26.4961 9.80334 26.7231 10.0306 26.7231 10.3108V13.6014C26.7231 15.1358 27.9717 16.3844 29.5063 16.3844C31.041 16.3844 32.2893 15.1358 32.2893 13.6014V10.3108C32.2893 10.0306 32.5165 9.80334 32.7967 9.80334C33.0771 9.80334 33.3042 10.0306 33.3042 10.3108V13.6014C33.3042 15.6957 31.6005 17.3993 29.5063 17.3993Z"
      fill="white"
    />
    <path
      d="M38.806 6.752C38.806 9.49156 36.5852 11.7126 33.8456 11.7126C31.1061 11.7126 28.8853 9.49156 28.8853 6.752C28.8853 4.01244 31.1061 1.79163 33.8456 1.79163C36.5852 1.79163 38.806 4.01244 38.806 6.752Z"
      fill="#ED017F"
    />
    <path
      d="M33.4509 9.80123C33.267 9.80123 33.097 9.70159 33.0074 9.54032L31.6543 7.10456C31.5182 6.85957 31.6063 6.55064 31.8513 6.41453C32.0965 6.27843 32.4055 6.36677 32.5416 6.61175L33.4211 8.19493L35.3625 4.20065C35.485 3.94872 35.7886 3.84344 36.0407 3.96619C36.2929 4.08868 36.398 4.39222 36.2752 4.6444L33.9072 9.51566C33.8246 9.68592 33.654 9.79583 33.4648 9.80097C33.4602 9.80123 33.4555 9.80123 33.4509 9.80123Z"
      fill="#E5E5E5"
    />
  </svg>
);

export default SVG;

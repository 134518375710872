/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.42125 2.00198C1.88778 1.85904 1.33945 2.17562 1.1965 2.70909L0.161228 6.57279C0.0182858 7.10626 0.334868 7.6546 0.868334 7.79754L9.0787 9.9975C9.61217 10.1404 10.1605 9.82386 10.3034 9.29039L11.3387 5.42669C11.4817 4.89322 11.1651 4.34489 10.6316 4.20194L2.42125 2.00198ZM7.6817 6.51738C7.39582 7.58431 6.29915 8.21748 5.23221 7.93159C4.16528 7.64571 3.53212 6.54903 3.818 5.4821C4.10388 4.41517 5.20056 3.78201 6.26749 4.06789C7.33442 4.35377 7.96759 5.45045 7.6817 6.51738Z"
      fill="#555555"
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M1.5 3C0.947715 3 0.5 3.44772 0.5 4V8C0.5 8.55228 0.947715 9 1.5 9H10C10.5523 9 11 8.55228 11 8V4C11 3.44772 10.5523 3 10 3H1.5ZM7.75 6C7.75 7.10457 6.85457 8 5.75 8C4.64543 8 3.75 7.10457 3.75 6C3.75 4.89543 4.64543 4 5.75 4C6.85457 4 7.75 4.89543 7.75 6ZM5.75 7C6.30228 7 6.75 6.55228 6.75 6C6.75 5.44772 6.30228 5 5.75 5C5.19772 5 4.75 5.44772 4.75 6C4.75 6.55228 5.19772 7 5.75 7Z"
      fill="#555555"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";

/**
 * Package Icon for Yakata
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg fill="none" height="16" viewBox="0 0 14 16" width="14" {...props}>
    <path
      clipRule="evenodd"
      d="M2.17773 7.8125V14.6451C2.17773 15.3938 2.81288 16 3.59716 16H6.90831L7.85373 10.9731L6.90831 7.8125H2.17773Z"
      fill="#FF7816"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.78241 7.8125L7.81641 10.9731L8.78241 16H12.1625C12.9623 16 13.6107 15.3938 13.6107 14.6451V7.8125H8.78241Z"
      fill="#FF4B00"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6 15H9V7H6V15Z"
      fill="#76E2F8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.08057 1.83516C9.02619 2.16813 8.79878 2.43991 8.47168 2.55434L6.99847 3.07167L7.22588 1.65636C7.28026 1.34088 7.48954 1.08658 7.78945 0.964201C8.45355 0.692423 9.18109 1.13347 9.08057 1.83516ZM4.1707 4.07137C3.83371 4.1858 3.47941 4.11587 3.22481 3.8878C2.66948 3.41338 2.87876 2.56308 3.57004 2.36123C3.86996 2.26508 4.21602 2.32627 4.46979 2.5456L5.64308 3.5453L4.1707 4.07137ZM7.44422 0.148072C6.84357 0.393626 6.42501 0.910958 6.32531 1.52524L6.11603 2.78241L5.07044 1.88761C4.57937 1.46643 3.91528 1.33531 3.29732 1.51968C1.92381 1.93211 1.55139 3.62397 2.6151 4.54499C3.12429 4.98365 3.83371 5.11477 4.47968 4.89624L5.87049 4.40434L6.61616 4.1413L8.78066 3.37841C9.4258 3.15988 9.88062 2.60679 9.98114 1.95754C10.1805 0.598651 8.76253 -0.386742 7.44422 0.148072Z"
      fill="#25D9F8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7 3L5 3.953L7 3Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8 15H9V7H8V15Z"
      fill="#25D9F8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.35064 4.95765C3.98568 5.08526 3.60197 5.00673 3.32624 4.75062C2.72569 4.21875 2.95235 3.2639 3.70013 3.03723C4.02583 2.92836 4.39972 2.99707 4.67545 3.24427L5.94615 4.366L4.35064 4.95765ZM6.45835 3.5093L5.32508 2.50448C4.79414 2.0324 4.07491 1.88427 3.40476 2.0913C1.91812 2.55445 1.51389 4.45524 2.6659 5.48951C3.21737 5.98122 3.98568 6.12935 4.68527 5.88305L6.19154 5.33156L7 5.03618L6.45835 3.5093Z"
      fill="#76E2F8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14 5.09569L13.1887 2.91561C13.0581 2.56669 12.7977 2.28756 12.453 2.13034C12.1101 1.97396 11.7242 1.95799 11.365 2.0841L7.35494 3.51254L7 5.10998L8.65181 7L14 5.09569Z"
      fill="#FF7816"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.51544 4L0.855419 5.32387C0.529177 5.44238 0.268811 5.6794 0.122159 5.99127C-0.0244928 6.30391 -0.0393933 6.65476 0.0790262 6.97833L0.819344 9L5.69885 7.23406L6 5.69032L4.51544 4Z"
      fill="#FF9F00"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.979 7L8 7.356L8.979 7Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9 6.14616L7.65077 6.57308L6.30154 7L5 3.85308L5.42142 3.71962L6.37785 3.41782L7.69846 3L9 6.14616Z"
      fill="#76E2F8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8 7.52329L6.47301 8L5 4.48603L5.47694 4.337L6.55937 4L8 7.52329Z"
      fill="#A8EBFA"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;
/* eslint-enable max-len */

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2103 0H3.78967C1.70001 0 0 1.70001 0 3.78967V10.2104C0 12.3 1.70001 14 3.78967 14H10.2104C12.3 14 14 12.3 14 10.2104V3.78967C14 1.70001 12.3 0 10.2103 0V0ZM13.1792 10.2104C13.1792 11.8474 11.8474 13.1792 10.2103 13.1792H3.78967C2.15257 13.1792 0.820738 11.8474 0.820738 10.2104V3.78967C0.820738 2.15257 2.15257 0.820738 3.78967 0.820738H10.2104C11.8474 0.820738 13.1792 2.15257 13.1792 3.78967V10.2104Z"
      fill="white"
    />
    <path
      d="M6.99989 3.17188C4.88908 3.17188 3.17188 4.88908 3.17188 6.99989C3.17188 9.11069 4.88908 10.8279 6.99989 10.8279C9.11069 10.8279 10.8279 9.11069 10.8279 6.99989C10.8279 4.88908 9.11069 3.17188 6.99989 3.17188ZM6.99989 10.0072C5.34175 10.0072 3.99261 8.65813 3.99261 6.99989C3.99261 5.34175 5.34175 3.99261 6.99989 3.99261C8.65813 3.99261 10.0072 5.34175 10.0072 6.99989C10.0072 8.65813 8.65813 10.0072 6.99989 10.0072Z"
      fill="white"
    />
    <path
      d="M10.9196 1.8125C10.2958 1.8125 9.78842 2.31996 9.78842 2.94363C9.78842 3.56741 10.2958 4.07487 10.9196 4.07487C11.5433 4.07487 12.0508 3.56741 12.0508 2.94363C12.0508 2.31985 11.5433 1.8125 10.9196 1.8125ZM10.9196 3.25403C10.7484 3.25403 10.6092 3.11474 10.6092 2.94363C10.6092 2.77241 10.7484 2.63324 10.9196 2.63324C11.0908 2.63324 11.2301 2.77241 11.2301 2.94363C11.2301 3.11474 11.0908 3.25403 10.9196 3.25403Z"
      fill="white"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 23.3346C18.2592 23.3346 23.3334 18.2605 23.3334 12.0013C23.3334 5.74207 18.2592 0.667969 12 0.667969C5.74079 0.667969 0.666687 5.74207 0.666687 12.0013C0.666687 18.2605 5.74079 23.3346 12 23.3346Z"
      fill="#FFEFED"
    />
    <path
      d="M12 21.7886C17.4057 21.7886 21.7879 17.4065 21.7879 12.0008C21.7879 6.59507 17.4057 2.21289 12 2.21289C6.59428 2.21289 2.2121 6.59507 2.2121 12.0008C2.2121 17.4065 6.59428 21.7886 12 21.7886Z"
      fill="#E11900"
    />
    <path
      d="M12.8191 15.3022C12.8191 15.7607 12.4637 16.1162 12.0052 16.1162C11.5467 16.1162 11.181 15.7607 11.181 15.3022C11.181 14.8438 11.5519 14.4883 12.0052 14.4883C12.4585 14.4883 12.8191 14.8438 12.8191 15.3022ZM11.3664 13.7722L11.2531 7.87891H12.7521L12.6388 13.7722H11.3664Z"
      fill="white"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    className="icon"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.13638 11.0852L2.60054 14.621C2.21002 15.0115 2.21001 15.6447 2.60054 16.0352L4.05041 17.4851C5.02107 16.8422 6.34159 16.9483 7.19673 17.8035C8.05188 18.6586 8.15801 19.9792 7.51512 20.9498L8.9645 22.3992C9.35502 22.7897 9.98819 22.7897 10.3787 22.3992L13.9142 18.8637L12.5 17.4496C12.1095 17.059 12.1095 16.4259 12.5 16.0354C12.8906 15.6448 13.5237 15.6448 13.9142 16.0354L15.3284 17.4495L22.3995 10.3784C22.7901 9.98785 22.7901 9.35469 22.3995 8.96416L20.9505 7.5151C19.9797 8.15858 18.6587 8.05263 17.8033 7.19725C16.948 6.34187 16.842 5.02085 17.4855 4.05012L16.0356 2.6002C15.645 2.20968 15.0119 2.20968 14.6214 2.6002L7.55059 9.67097L8.9645 11.0849C9.35503 11.4754 9.35503 12.1086 8.9645 12.4991C8.57398 12.8896 7.94081 12.8896 7.55029 12.4991L6.13638 11.0852ZM9.67161 14.6209C9.28108 14.2304 9.28108 13.5972 9.67161 13.2067C10.0621 12.8162 10.6953 12.8162 11.0858 13.2067L11.7929 13.9138C12.1835 14.3043 12.1835 14.9375 11.7929 15.328C11.4024 15.7185 10.7692 15.7185 10.3787 15.328L9.67161 14.6209Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

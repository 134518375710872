/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="44"
    viewBox="0 0 26 44"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.7019 41.0084H24.0198V33.2324H24.0136C24.0136 32.8004 23.8154 32.2057 23.4165 31.8063L14.6661 22.1122C14.6195 22.0652 14.5667 22.0354 14.5163 21.9946C14.5663 21.9533 14.6186 21.9217 14.6661 21.8751L23.4165 12.193C23.8154 11.7927 24.0146 11.1994 24.0146 10.7674H24.0198V2.99239H24.7019C25.3739 2.99239 25.9202 2.36935 25.9202 1.69639C25.9202 1.02343 25.3749 0.400391 24.7019 0.400391H1.73633C1.06337 0.400391 0.519531 1.02391 0.519531 1.69639C0.519531 2.36887 1.06385 2.99239 1.73633 2.99239H2.41985V10.7261C2.41985 11.2503 2.62001 11.774 3.01985 12.1743L11.7712 21.8785C11.8182 21.925 11.8711 21.9596 11.9219 22.0009C11.872 22.0417 11.8192 22.0767 11.7722 22.1237L3.01985 31.8284C2.62001 32.2277 2.41985 32.7524 2.41985 33.2756V41.0084H1.73633C1.06337 41.0084 0.519531 41.6329 0.519531 42.3034C0.519531 42.9759 1.06385 43.6004 1.73633 43.6004H24.7019C25.3739 43.6004 25.9202 42.9769 25.9202 42.3034C25.9202 41.6324 25.3749 41.0084 24.7019 41.0084ZM5.87825 11.6674C5.59073 11.3794 5.44385 11.0031 5.44385 10.6258V5.15239H20.9958V10.7684H20.9862C20.9862 11.2004 20.8437 11.4509 20.5567 11.7385L13.2175 18.7724L5.87825 11.6674ZM20.9958 39.2804H5.44385V33.6644H5.45297C5.45297 33.2329 5.59601 32.8628 5.88305 32.5748L13.2199 25.4809L20.5619 32.5388C20.8494 32.8268 20.9958 33.1906 20.9958 33.5684V39.2804Z"
      fill="#5A90DC"
    />
  </svg>
);

export default SVG;

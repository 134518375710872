/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 26 30"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25.2777 21.5862V21.7056C25.249 21.7695 25.2164 21.832 25.1926 21.8974C25.0533 22.278 24.9028 22.6547 24.7385 23.0264C24.0417 24.604 23.0717 26.0229 21.9418 27.3482C21.4081 27.974 20.8213 28.548 20.0732 28.9491C19.3955 29.3125 18.6702 29.4581 17.8929 29.3649C17.1837 29.2798 16.5173 29.0602 15.8612 28.8037C15.1104 28.5101 14.3386 28.2939 13.5224 28.2539C12.5507 28.2064 11.6256 28.4094 10.7306 28.7479C10.0042 29.0228 9.27586 29.2873 8.49935 29.4086H7.61803C7.31777 29.3382 7.01998 29.2637 6.73943 29.1344C5.94459 28.7681 5.31234 28.2092 4.74796 27.5833C3.29978 25.9772 2.12012 24.2135 1.31734 22.2326C0.772395 20.8876 0.354174 19.5115 0.140684 18.0822C0.0804692 17.6788 0.0468035 17.2733 0 16.8688C0 16.2619 0 15.6547 0 15.0478C0.0169697 14.9047 0.0355816 14.762 0.0506354 14.6187C0.193236 13.254 0.596676 11.9687 1.33431 10.7824C2.34976 9.14874 3.79519 8.02028 5.70319 7.44424C6.57028 7.18257 7.46174 7.06912 8.37181 7.1888C9.12422 7.2877 9.84051 7.51355 10.5472 7.77029C11.1398 7.98575 11.7345 8.19369 12.346 8.35905C12.6868 8.45121 13.0108 8.44186 13.3486 8.34218C14.1656 8.10127 14.958 7.79573 15.7679 7.53639C16.5701 7.2794 17.3863 7.08418 18.2402 7.06419C18.9007 7.04888 19.5477 7.13895 20.1876 7.27888C21.9095 7.65503 23.3248 8.4821 24.369 9.85068C24.501 10.0236 24.5001 10.0251 24.3135 10.1412C23.5074 10.6424 22.7947 11.2382 22.2454 11.9957C21.2491 13.3703 20.9871 14.9019 21.205 16.5241C21.433 18.2208 22.2963 19.5837 23.6867 20.6584C24.1742 21.0353 24.7139 21.3287 25.2777 21.5862Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.8245 0C18.9101 0.501537 18.8852 1.00489 18.7933 1.49942C18.4279 3.46507 17.3432 4.99486 15.6032 6.10048C14.7594 6.6368 13.808 6.84993 12.7931 6.81592C12.6448 6.81099 12.5791 6.76608 12.5679 6.62019C12.5024 5.77209 12.6223 4.94632 12.9743 4.16728C13.99 1.91996 15.7727 0.545668 18.2932 0.0324494C18.3545 0.0199888 18.4166 0.0106434 18.4782 0H18.8245Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

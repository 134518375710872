/* eslint-disable max-len */
import React from "react";

/**
 * Feedback Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any) => (
  <svg height="26" viewBox="0 0 27 26" width="27" {...props}>
    <path
      d="M2.46 25h22.08c.2 0 .35-.15.35-.34V10.19A2.32 2.32 0 0 0 26 8.22V4.85a.34.34 0 0 0-.34-.34h-.77A3.85 3.85 0 0 0 21.02 1H5.98a3.85 3.85 0 0 0-3.87 3.51h-.76a.34.34 0 0 0-.35.34v3.37c0 .8.42 1.54 1.11 1.97v14.47c0 .19.16.34.35.34zm18-.68h-4.7v-6.45a2.34 2.34 0 0 1 2.35-2.23c1.26 0 2.3.99 2.35 2.23v6.45zm3.74 0h-3.05v-6.45a3.02 3.02 0 0 0-3.04-2.9 3.02 3.02 0 0 0-3.05 2.9v6.45H2.8V10.48c.9.22 1.85-.1 2.42-.82a3.03 3.03 0 0 0 2.65 1.99 3.05 3.05 0 0 0 2.91-1.61 3.05 3.05 0 0 0 2.7 1.62 3.05 3.05 0 0 0 2.7-1.62 3.05 3.05 0 0 0 2.93 1.65 3.03 3.03 0 0 0 2.67-2.03 2.37 2.37 0 0 0 2.42.82v13.84zM5.74 5.2h4.71v3.47a2.31 2.31 0 0 1-1.15 2.08 2.39 2.39 0 0 1-2.4 0 2.31 2.31 0 0 1-1.16-2.08V5.2zm5.4 0h4.72v3.47a2.31 2.31 0 0 1-1.15 2.08 2.39 2.39 0 0 1-2.41 0 2.31 2.31 0 0 1-1.15-2.08V5.2zm5.4 0h4.72v3.47a2.31 2.31 0 0 1-1.15 2.08 2.39 2.39 0 0 1-2.41 0 2.31 2.31 0 0 1-1.15-2.08V5.2zm8.77 3.03c0 .91-.76 1.65-1.68 1.65-.93 0-1.68-.74-1.68-1.65V5.19h3.36v3.03zM5.98 1.68h15.04A3.17 3.17 0 0 1 24.2 4.5H2.8a3.17 3.17 0 0 1 3.18-2.83zM1.69 8.22V5.19h3.36v3.03c0 .91-.75 1.65-1.68 1.65S1.7 9.13 1.7 8.22zm8.6 6.67c1.14 0 2.07.91 2.07 2.04v1.6c0 1.13-.93 2.04-2.07 2.04H7.57a2.05 2.05 0 0 1-2.07-2.04v-1.6c0-1.13.93-2.04 2.07-2.04h2.72zm1.38 3.65v-1.61a1.37 1.37 0 0 0-1.38-1.36H7.57a1.37 1.37 0 0 0-1.38 1.36v1.6c0 .76.62 1.36 1.38 1.36h2.72a1.37 1.37 0 0 0 1.38-1.35z"
      fill="#ed017f"
      stroke="#ed017f"
      strokeWidth=".25"
    />
  </svg>
);

export default SVG;
/* eslint-enable max-len */

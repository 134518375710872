/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="254"
    viewBox="0 0 303 254"
    width="303"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M291.952 131.629C291.952 165.582 278.084 196.188 255.846 218.187C234.087 239.946 203.959 253.097 170.723 253.097C137.726 253.097 107.598 239.707 85.5994 218.187C63.3621 196.188 49.4937 165.582 49.4937 131.629C49.4937 64.4383 103.772 10.1602 170.723 10.1602C237.674 10.1602 291.952 64.6775 291.952 131.629Z"
      fill="#EAEEF9"
    />
    <path
      d="M281.433 51.9938C286.847 51.9938 291.237 47.6046 291.237 42.1903C291.237 36.7759 286.847 32.3867 281.433 32.3867C276.019 32.3867 271.629 36.7759 271.629 42.1903C271.629 47.6046 276.019 51.9938 281.433 51.9938Z"
      fill="#EAEEF9"
    />
    <path
      d="M295.782 13.7418C299.48 13.7418 302.478 10.7443 302.478 7.04667C302.478 3.34906 299.48 0.351562 295.782 0.351562C292.085 0.351562 289.087 3.34906 289.087 7.04667C289.087 10.7443 292.085 13.7418 295.782 13.7418Z"
      fill="#EAEEF9"
    />
    <path
      d="M53.3221 51.7574C57.0197 51.7574 60.0172 48.7599 60.0172 45.0623C60.0172 41.3647 57.0197 38.3672 53.3221 38.3672C49.6245 38.3672 46.627 41.3647 46.627 45.0623C46.627 48.7599 49.6245 51.7574 53.3221 51.7574Z"
      fill="#EAEEF9"
    />
    <path
      d="M12.4343 180.883C19.3012 180.883 24.868 175.316 24.868 168.449C24.868 161.582 19.3012 156.016 12.4343 156.016C5.56728 156.016 0.000488281 161.582 0.000488281 168.449C0.000488281 175.316 5.56728 180.883 12.4343 180.883Z"
      fill="#EAEEF9"
    />
    <g filter="url(#filter0_d_15314:63756)">
      <path
        d="M279.085 61.9994V194.478C279.085 199.992 274.671 204.36 269.26 204.36H69.7101C64.2995 204.36 59.8145 199.921 59.8145 194.478V61.9994C59.8145 56.557 64.2283 52.1172 69.7101 52.1172H269.26C274.671 52.1172 279.085 56.557 279.085 61.9994Z"
        fill="url(#paint0_linear_15314:63756)"
      />
    </g>
    <path
      d="M279.085 61.9994V76.8227H59.8145V61.9994C59.8145 56.557 64.2283 52.1172 69.7101 52.1172H269.26C274.671 52.1172 279.085 56.557 279.085 61.9994Z"
      fill="#D5DDEA"
    />
    <path
      d="M73.6287 68.1545C75.6733 68.1545 77.3307 66.4873 77.3307 64.4308C77.3307 62.3742 75.6733 60.707 73.6287 60.707C71.5842 60.707 69.9268 62.3742 69.9268 64.4308C69.9268 66.4873 71.5842 68.1545 73.6287 68.1545Z"
      fill="#989FB0"
    />
    <path
      d="M85.7278 68.1545C87.7724 68.1545 89.4298 66.4873 89.4298 64.4308C89.4298 62.3742 87.7724 60.707 85.7278 60.707C83.6833 60.707 82.0259 62.3742 82.0259 64.4308C82.0259 66.4873 83.6833 68.1545 85.7278 68.1545Z"
      fill="#989FB0"
    />
    <path
      d="M97.764 68.1545C99.8085 68.1545 101.466 66.4873 101.466 64.4308C101.466 62.3742 99.8085 60.707 97.764 60.707C95.7194 60.707 94.062 62.3742 94.062 64.4308C94.062 66.4873 95.7194 68.1545 97.764 68.1545Z"
      fill="#989FB0"
    />
    <path
      d="M105.592 167.055H233.31"
      stroke="#D5DDEA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M125.739 180.73H213.162"
      stroke="#D5DDEA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M148.092 128.526V135.902H143.607V142.991H135.207V135.902H116.697V127.452L133.854 103.462H143.678V128.597H148.092V128.526ZM135.207 113.273L124.528 128.526H135.207V113.273Z"
      fill="url(#paint1_linear_15314:63756)"
    />
    <path
      d="M169.165 143.779C160.195 143.779 152.293 135.902 152.293 123.155C152.293 110.408 160.124 102.531 169.165 102.531C178.206 102.531 186.037 110.408 186.037 123.155C186.037 135.902 178.206 143.779 169.165 143.779ZM169.165 136.188C173.365 136.188 177.566 131.963 177.566 123.227C177.566 114.49 173.365 110.265 169.165 110.265C164.965 110.265 160.764 114.49 160.764 123.227C160.764 131.963 164.965 136.188 169.165 136.188Z"
      fill="url(#paint2_linear_15314:63756)"
    />
    <path
      d="M222.274 128.526V135.902H217.789V142.991H209.388V135.902H190.878V127.452L208.036 103.462H217.86V128.597H222.274V128.526ZM209.317 113.273L198.638 128.526H209.317V113.273Z"
      fill="url(#paint3_linear_15314:63756)"
    />
    <path
      d="M269.394 233.615L221.699 185.957L232.633 175.023L280.29 222.718L269.394 233.615Z"
      fill="#858B9B"
    />
    <path
      d="M285.971 250.377L249.398 213.805C246.344 210.751 246.344 205.774 249.398 202.72C252.452 199.666 257.429 199.666 260.483 202.72L297.056 239.293C300.11 242.347 300.11 247.323 297.056 250.377C294.002 253.431 289.025 253.431 285.971 250.377Z"
      fill="#858B9B"
    />
    <path
      d="M283.371 252.714L236.317 205.66C235.224 204.567 235.224 202.795 236.317 201.664L248.345 189.636C249.438 188.543 251.21 188.543 252.341 189.636L299.395 236.69C300.488 237.784 300.488 239.556 299.395 240.687L287.368 252.714C286.237 253.808 284.464 253.808 283.371 252.714Z"
      fill="url(#paint4_linear_15314:63756)"
    />
    <path
      d="M167.705 32.3867C117.786 32.3867 77.3301 72.8426 77.3301 122.762C77.3301 172.644 117.786 213.137 167.668 213.137C217.587 213.137 258.043 172.681 258.043 122.762C258.043 72.8426 217.587 32.3867 167.705 32.3867ZM167.705 196.397C127.627 196.397 95.1638 163.406 95.1638 122.724C95.1638 82.0422 127.627 49.0894 167.705 49.0894C207.784 49.0894 240.247 82.0799 240.247 122.762C240.247 163.444 207.746 196.397 167.705 196.397Z"
      fill="url(#paint5_linear_15314:63756)"
    />
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="196.242"
        id="filter0_d_15314:63756"
        width="263.27"
        x="37.8145"
        y="41.1172"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_15314:63756"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15314:63756"
          mode="normal"
          result="shape"
        />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_15314:63756"
        x1="169.378"
        x2="169.378"
        y1="48.5957"
        y2="206.002"
      >
        <stop stopColor="#FDFEFF" />
        <stop offset="0.9964" stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_15314:63756"
        x1="186.42"
        x2="194.309"
        y1="102.531"
        y2="143.779"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_15314:63756"
        x1="186.42"
        x2="194.309"
        y1="102.531"
        y2="143.779"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_15314:63756"
        x1="186.42"
        x2="194.309"
        y1="102.531"
        y2="143.779"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_15314:63756"
        x1="235.467"
        x2="300.227"
        y1="221.182"
        y2="221.182"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_15314:63756"
        x1="77.2473"
        x2="258.076"
        y1="122.782"
        y2="122.782"
      >
        <stop stopColor="#B0BACC" />
        <stop offset="1" stopColor="#969EAE" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;

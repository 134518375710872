/* eslint-disable max-len */
import React from "react";

/**
 * Twitter Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any) => (
  <svg
    fill="#03A9F4"
    height="18px"
    version="1.1"
    viewBox="0 0 22 18"
    width="22px"
    {...props}
  >
    <path d="M19 2.60922C18.3007 2.91908 17.5503 3.12923 16.7619 3.22305C17.5669 2.74101 18.1831 1.97641 18.4752 1.06814C17.7201 1.51455 16.8866 1.83869 15.9985 2.01441C15.2873 1.25575 14.2758 0.783203 13.1538 0.783203C11.0012 0.783203 9.25595 2.5285 9.25595 4.67984C9.25595 4.98496 9.29038 5.28296 9.35688 5.56793C6.118 5.40525 3.24599 3.8535 1.32379 1.49555C0.987783 2.0702 0.796634 2.7398 0.796634 3.45456C0.796634 4.80688 1.48526 6.00009 2.53007 6.69818C1.89131 6.67682 1.29054 6.50111 0.764595 6.20903V6.25771C0.764595 8.14547 2.1086 9.72098 3.89068 10.0796C3.56418 10.1674 3.21988 10.2161 2.86369 10.2161C2.612 10.2161 2.3686 10.1912 2.12996 10.1437C2.62622 11.6931 4.06522 12.8198 5.77016 12.8507C4.43684 13.8955 2.75565 14.5164 0.929634 14.5164C0.615016 14.5164 0.305118 14.4974 0 14.463C1.72512 15.5707 3.77318 16.2166 5.97438 16.2166C13.1443 16.2166 17.0636 10.2778 17.0636 5.12746L17.0505 4.62287C17.8163 4.07669 18.4787 3.39045 19 2.60922Z" />
  </svg>
);

export default SVG;
/* eslint-enable max-len */

class ElectricityForm {
  countryCode = "+234";
  operator = "";
  purchaseType = "prepaid";
  meterNumber = "";
  amount = 100;
  phoneNumber = "";
  email = "";
  policyAgree = false;
}

export default ElectricityForm;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.00018 10.0003L3.27726 11.4318C2.95137 11.6031 2.54828 11.4779 2.37695 11.152C2.30872 11.0222 2.28518 10.8735 2.30996 10.729L2.83 7.69701L0.627107 5.54973C0.363451 5.29273 0.358057 4.87065 0.615057 4.607C0.717396 4.50201 0.851491 4.43368 0.996583 4.4126L4.0409 3.97023L5.40236 1.21162C5.56531 0.881446 5.96506 0.745887 6.29523 0.908835C6.4267 0.973722 6.53312 1.08014 6.59801 1.21162L7.95947 3.97023L11.0038 4.4126C11.3681 4.46554 11.6206 4.80384 11.5677 5.1682C11.5466 5.31329 11.4783 5.44739 11.3733 5.54973L9.17037 7.69701L9.6904 10.729C9.75264 11.0919 9.50892 11.4366 9.14603 11.4988C9.00152 11.5236 8.85288 11.5 8.7231 11.4318L6.00018 10.0003Z"
      fill="#E09B3D"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

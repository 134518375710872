/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6875 12.6949C20.287 12.0642 20.7 11.2888 20.8236 10.8306C20.9047 10.5358 20.9621 10.2253 20.9991 9.99168C21.2538 9.86781 21.4507 9.65769 21.5572 9.39349C21.7074 9.01168 21.7889 8.19201 21.79 8.15633C21.8559 7.5064 21.6278 7.13047 21.6067 7.1093C21.8715 4.4731 21.0334 3.9537 20.475 3.8608C20.2761 3.8263 19.9754 3.75261 19.8424 3.60208C19.5284 3.25477 19.3896 2.86904 18.8487 2.9004C18.6501 2.91098 18.3795 2.84865 18.2266 2.71969C18.0187 2.54446 17.7333 2.3418 17.5238 2.3418C17.3152 2.3418 17.0527 2.53741 16.8632 2.70871C16.7122 2.84434 16.4486 2.91137 16.2497 2.9004C15.7084 2.86904 15.5699 3.25477 15.2568 3.60208C15.1226 3.753 14.8223 3.8263 14.6238 3.8608C14.0637 3.9537 13.226 4.47349 13.4916 7.11087C13.4686 7.13517 13.2436 7.5111 13.3083 8.15594C13.3107 8.19201 13.3918 9.01207 13.5419 9.39349C13.65 9.65809 13.8442 9.86781 14.1 9.99168C14.1375 10.2253 14.1948 10.5389 14.2743 10.8306C14.4027 11.2998 14.8211 12.0908 15.4284 12.7247H15.4151C15.3309 16.3915 7.67555 14.3625 9.30848 20.9485C9.82835 22.8128 16.5211 23.1535 17.5515 23.0806C18.5784 23.1535 25.2696 22.8124 25.7907 20.9485C27.422 14.3688 19.7878 16.3503 19.6875 12.6949Z"
      fill="#ED017F"
    />
    <path
      d="M28.9908 9.99012C29.5692 9.38174 29.9681 8.6338 30.0875 8.19123C30.1647 7.90703 30.2205 7.60754 30.256 7.38254C30.502 7.26259 30.6927 7.06031 30.7949 6.80473C30.9396 6.43625 31.0188 5.6448 31.02 5.6107C31.0835 4.98271 30.8632 4.6209 30.8425 4.60051C31.098 2.05682 30.2899 1.55506 29.7501 1.4653C29.5586 1.43198 29.2681 1.36063 29.1398 1.21559C28.8375 0.880824 28.703 0.508033 28.1812 0.537825C27.9904 0.548801 27.728 0.488432 27.5817 0.364168C27.3797 0.194824 27.1051 0 26.9031 0C26.7011 0 26.448 0.188552 26.2647 0.353192C26.1196 0.48412 25.8657 0.548801 25.6727 0.537825C25.1512 0.508033 25.0167 0.880432 24.7144 1.21559C24.5857 1.36102 24.2968 1.43198 24.1041 1.4653C23.5647 1.55506 22.7559 2.05682 23.0117 4.60208C22.9895 4.6256 22.7722 4.98781 22.8346 5.6107C22.837 5.6448 22.9158 6.43586 23.0597 6.80473C23.1646 7.05992 23.3518 7.26259 23.599 7.38254C23.6349 7.60754 23.6899 7.91056 23.7671 8.19123C23.89 8.64399 24.2944 9.408 24.8802 10.0191H24.8677C24.8244 11.9219 22.6681 12.2359 20.9287 13.1069C21.1506 13.5071 21.729 13.8031 22.9193 14.2931C24.7909 15.0622 27.5306 16.1904 27.1032 20.0194C28.506 20.0492 34.4005 19.671 34.879 17.9552C36.4542 11.6059 29.0875 13.5177 28.9908 9.99012Z"
      fill="#ED017F"
    />
    <path
      d="M12.1873 14.3158C13.4029 13.8231 13.9785 13.5271 14.1887 13.1159C12.4466 12.2382 10.2763 11.929 10.2318 10.0191H10.2193C10.8055 9.408 11.2095 8.64399 11.3328 8.19123C11.4092 7.91017 11.4654 7.60754 11.5009 7.38254C11.7477 7.26259 11.9349 7.06031 12.0406 6.80473C12.1845 6.43625 12.2633 5.6448 12.2649 5.6107C12.3273 4.98781 12.11 4.62521 12.0882 4.60208C12.3448 2.05682 11.5356 1.55546 10.9958 1.4653C10.8039 1.43198 10.5138 1.36102 10.3855 1.21559C10.0828 0.880824 9.94827 0.508033 9.42723 0.537825C9.23417 0.548801 8.98029 0.48412 8.83482 0.353192C8.65152 0.188552 8.39841 0 8.19717 0C7.99398 0 7.71942 0.194824 7.51857 0.364168C7.37154 0.488432 7.10946 0.549193 6.91914 0.537825C6.39692 0.508033 6.26199 0.880432 5.95974 1.21559C5.83143 1.36063 5.54088 1.43198 5.34939 1.4653C4.80963 1.55506 4.00155 2.05682 4.257 4.60051C4.2375 4.6209 4.01676 4.9831 4.08033 5.6107C4.08189 5.6448 4.15988 6.43586 4.30457 6.80473C4.40793 7.05992 4.59747 7.26259 4.84355 7.38254C4.87905 7.60754 4.93481 7.90742 5.01281 8.19123C5.13177 8.6338 5.52995 9.38174 6.10989 9.99012C6.01199 13.5177 -1.35471 11.6059 0.220885 17.9556C0.699805 19.671 6.59231 20.0492 7.99592 20.0198C7.56809 16.1892 10.3133 15.0755 12.1873 14.3158Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

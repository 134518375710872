/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.12109 5C4.12109 4.44772 4.56881 4 5.12109 4H18.8789C19.4312 4 19.8789 4.44772 19.8789 5V19C19.8789 20.1046 18.9835 21 17.8789 21H6.1211C5.01653 21 4.12109 20.1046 4.12109 19V5Z"
      fill="#ED017F"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M7 7H9C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7H17C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z"
      fill="#ED017F"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

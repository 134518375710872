/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="33"
    viewBox="0 0 28 33"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5095 32.37H0.841041C0.570189 32.37 0.350586 32.1507 0.350586 31.8796V0.490455C0.350586 0.219364 0.570189 0 0.841041 0H21.1743C21.3044 0 21.4291 0.0517277 21.5211 0.143688L27.8563 6.47889C27.9482 6.57085 28 6.69538 28 6.82566V31.8796C28 32.1507 27.7804 32.37 27.5095 32.37ZM1.33149 31.3891H27.0191V7.02874L20.9712 0.980909H1.33149V31.3891Z"
      fill="#ED017F"
    />
    <path
      d="M27.5095 7.31643H21.1743C20.9034 7.31643 20.6838 7.09707 20.6838 6.82598V0.490776C20.6838 0.292486 20.8033 0.113355 20.9865 0.0376797C21.1697 -0.0389539 21.3805 0.00367356 21.5211 0.144009L27.8563 6.47921C27.9966 6.61955 28.0385 6.83029 27.9626 7.01373C27.8867 7.19717 27.7078 7.31643 27.5095 7.31643ZM21.6647 6.33552H26.3255L21.6647 1.67476V6.33552Z"
      fill="#ED017F"
    />
    <path
      d="M7.62554 11.8688H4.02903C3.75818 11.8688 3.53857 11.6495 3.53857 11.3784V8.05295C3.53857 7.78186 3.75818 7.5625 4.02903 7.5625H7.62554C7.89639 7.5625 8.11599 7.78186 8.11599 8.05295V11.3784C8.11599 11.6495 7.89639 11.8688 7.62554 11.8688ZM4.51948 10.8879H7.13508V8.54341H4.51948V10.8879Z"
      fill="#ED017F"
    />
    <path
      d="M7.62554 20.5563H4.02903C3.75818 20.5563 3.53857 20.337 3.53857 20.0659V16.7405C3.53857 16.4694 3.75818 16.25 4.02903 16.25H7.62554C7.89639 16.25 8.11599 16.4694 8.11599 16.7405V20.0659C8.11599 20.337 7.89639 20.5563 7.62554 20.5563ZM4.51948 19.5754H7.13508V17.2309H4.51948V19.5754Z"
      fill="#ED017F"
    />
    <path
      d="M7.6256 29.2512H4.02909C3.75824 29.2512 3.53864 29.0318 3.53864 28.7607V25.4358C3.53864 25.1647 3.75824 24.9453 4.02909 24.9453H7.6256C7.89645 24.9453 8.11605 25.1647 8.11605 25.4358V28.7607C8.11605 29.0318 7.89645 29.2512 7.6256 29.2512ZM4.51955 28.2703H7.13514V25.9262H4.51955V28.2703Z"
      fill="#ED017F"
    />
    <path
      d="M23.5859 10.2036H10.221C9.95019 10.2036 9.73059 9.9842 9.73059 9.71311C9.73059 9.44202 9.95019 9.22266 10.221 9.22266H23.5859C23.8568 9.22266 24.0764 9.44202 24.0764 9.71311C24.0764 9.9842 23.8568 10.2036 23.5859 10.2036Z"
      fill="#ED017F"
    />
    <path
      d="M23.5859 18.895H10.221C9.95019 18.895 9.73059 18.6756 9.73059 18.4045C9.73059 18.1334 9.95019 17.9141 10.221 17.9141H23.5859C23.8568 17.9141 24.0764 18.1334 24.0764 18.4045C24.0764 18.6756 23.8568 18.895 23.5859 18.895Z"
      fill="#ED017F"
    />
    <path
      d="M23.5859 27.5825H10.221C9.95019 27.5825 9.73059 27.3631 9.73059 27.092C9.73059 26.8209 9.95019 26.6016 10.221 26.6016H23.5859C23.8568 26.6016 24.0764 26.8209 24.0764 27.092C24.0764 27.3631 23.8568 27.5825 23.5859 27.5825Z"
      fill="#ED017F"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 29C1.99877 26.7909 2.56084 24.618 3.6331 22.6866C4.70537 20.7552 6.2524 19.1291 8.128 17.962C8.62303 14.1022 10.5076 10.555 13.429 7.98433C16.3505 5.41364 20.1086 3.99561 24 3.99561C27.8914 3.99561 31.6495 5.41364 34.571 7.98433C37.4924 10.555 39.377 14.1022 39.872 17.962C42.1985 19.4094 44.0081 21.5548 45.0426 24.092C46.0771 26.6291 46.2835 29.4283 45.6323 32.0897C44.9811 34.7511 43.5057 37.1388 41.4167 38.9117C39.3277 40.6847 36.7319 41.7523 34 41.962L14 42C7.288 41.452 2 35.844 2 29ZM33.696 37.974C35.5877 37.8286 37.385 37.0891 38.8312 35.8611C40.2775 34.6331 41.2987 32.9795 41.7489 31.1365C42.1992 29.2935 42.0555 27.3553 41.3384 25.5988C40.6213 23.8423 39.3675 22.3573 37.756 21.356L36.142 20.35L35.902 18.466C35.5282 15.5734 34.1138 12.9159 31.9232 10.9902C29.7326 9.06449 26.9157 8.00239 23.999 8.00239C21.0823 8.00239 18.2654 9.06449 16.0749 10.9902C13.8843 12.9159 12.4699 15.5734 12.096 18.466L11.856 20.35L10.246 21.356C8.63459 22.3572 7.38077 23.842 6.66359 25.5983C5.94642 27.3546 5.80258 29.2927 6.25262 31.1356C6.70265 32.9786 7.72355 34.6322 9.1695 35.8603C10.6155 37.0884 12.4125 37.8282 14.304 37.974L14.65 38H33.35L33.696 37.974ZM26 26V34H22V26H16L24 16L32 26H26Z"
      fill="#878787"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM12.7264 17.5581C12.9539 17.725 13.6398 18.2207 14.784 19.0449C15.9283 19.8691 16.8049 20.5037 17.4138 20.9488C17.4807 20.9976 17.6228 21.1037 17.8403 21.2671C18.0578 21.4307 18.2385 21.5629 18.3822 21.6637C18.5261 21.7645 18.7001 21.8775 18.9043 22.0027C19.1084 22.1278 19.3008 22.2218 19.4815 22.2841C19.6622 22.3469 19.8295 22.378 19.9833 22.378H19.9934H20.0036C20.1574 22.378 20.3248 22.3468 20.5055 22.2841C20.6861 22.2218 20.8787 22.1277 21.0826 22.0027C21.2866 21.8774 21.4606 21.7644 21.6045 21.6637C21.7484 21.5629 21.929 21.4307 22.1465 21.2671C22.3639 21.1035 22.5062 20.9976 22.5731 20.9488C23.1886 20.5037 24.7546 19.3734 27.2705 17.5579C27.759 17.2033 28.1671 16.7754 28.4949 16.2746C28.823 15.774 28.9869 15.2488 28.9869 14.6994C28.9869 14.2402 28.8278 13.8472 28.51 13.5203C28.1921 13.1934 27.8157 13.03 27.3808 13.03H12.6059C12.0907 13.03 11.6942 13.2108 11.4165 13.5724C11.1388 13.9341 11 14.3863 11 14.9288C11 15.367 11.1841 15.8418 11.5521 16.353C11.92 16.8643 12.3116 17.266 12.7264 17.5581ZM27.983 18.6744C25.7884 20.2184 24.122 21.4184 22.9846 22.274C22.6032 22.566 22.2938 22.794 22.0561 22.9574C21.8185 23.1208 21.5025 23.2877 21.1076 23.4581C20.7129 23.6287 20.345 23.7137 20.0037 23.7137H19.9935H19.9834C19.6421 23.7137 19.274 23.6287 18.8793 23.4581C18.4846 23.2877 18.1683 23.1208 17.9308 22.9574C17.6933 22.794 17.3838 22.566 17.0024 22.274C16.099 21.5855 14.4362 20.3854 12.0139 18.6744C11.6324 18.4102 11.2944 18.1075 11 17.7667V26.0507C11 26.5101 11.1572 26.9029 11.4717 27.2298C11.7862 27.5568 12.1643 27.7203 12.606 27.7203H27.381C27.8225 27.7203 28.2007 27.5568 28.5152 27.2298C28.8298 26.9028 28.9869 26.5101 28.9869 26.0507V17.7667C28.6991 18.1004 28.3646 18.4032 27.983 18.6744Z"
      fill="#B6B6B6"
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;

/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props: any): unknown => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 14L8 15.3333L6.66667 14H3.33C2.97726 14 2.63897 13.8599 2.38955 13.6105C2.14012 13.361 2 13.0227 2 12.67V3.33C2 2.59533 2.59533 2 3.33 2H12.67C13.4047 2 14 2.59533 14 3.33V12.67C14 13.0227 13.8599 13.361 13.6105 13.6105C13.361 13.8599 13.0227 14 12.67 14H9.33333ZM12.6667 12.6667V3.33333H3.33333V12.6667H7.21867L8 13.448L8.78133 12.6667H12.6667ZM5.31467 12.12C4.90466 11.9424 4.51357 11.724 4.14733 11.468C4.57018 10.8127 5.15064 10.2739 5.8356 9.90096C6.52055 9.52803 7.2881 9.33286 8.068 9.33333C9.668 9.33333 11.0793 10.138 11.92 11.3647C11.5608 11.6304 11.1757 11.8593 10.7707 12.048C10.4619 11.62 10.0559 11.2715 9.58594 11.0314C9.11602 10.7912 8.59574 10.6662 8.068 10.6667C6.92467 10.6667 5.91467 11.2427 5.31467 12.12ZM8 8.66667C7.69358 8.66667 7.39017 8.60631 7.10707 8.48905C6.82398 8.37179 6.56675 8.19992 6.35008 7.98325C6.13341 7.76658 5.96154 7.50935 5.84428 7.22626C5.72702 6.94317 5.66667 6.63975 5.66667 6.33333C5.66667 6.02692 5.72702 5.7235 5.84428 5.44041C5.96154 5.15731 6.13341 4.90009 6.35008 4.68342C6.56675 4.46675 6.82398 4.29488 7.10707 4.17761C7.39017 4.06035 7.69358 4 8 4C8.61884 4 9.21233 4.24583 9.64992 4.68342C10.0875 5.121 10.3333 5.71449 10.3333 6.33333C10.3333 6.95217 10.0875 7.54566 9.64992 7.98325C9.21233 8.42083 8.61884 8.66667 8 8.66667ZM8 7.33333C8.26522 7.33333 8.51957 7.22798 8.70711 7.04044C8.89464 6.8529 9 6.59855 9 6.33333C9 6.06812 8.89464 5.81376 8.70711 5.62623C8.51957 5.43869 8.26522 5.33333 8 5.33333C7.73478 5.33333 7.48043 5.43869 7.29289 5.62623C7.10536 5.81376 7 6.06812 7 6.33333C7 6.59855 7.10536 6.8529 7.29289 7.04044C7.48043 7.22798 7.73478 7.33333 8 7.33333Z"
      fill="#505050"
    />
  </svg>
);

export default SVG;
